import { TabPanel, TabView } from "primereact/tabview";
import React from "react";
import TopNavbar from "../components/common/top-navbar/NavbarTop";
import { CCO2Transactions } from "../components/transactions/CCO2Transactions";
import { NFTreeTransactions } from "../components/transactions/NFTreeTransactions";
import { PoccTransactions } from "../components/transactions/PoccTransactions";
import { useAppSelector } from "../store/hooks";
import { RootState } from "../store/store";
import classes from "../styles/Transactions.module.scss";
import RightSidebar from "./RightSidebar";

export interface TransactionsProps {
  onThemeChange: (newTheme: string) => void;
}

export const Transactions = (props:TransactionsProps) => {
  const currentUser = useAppSelector(
    (state: RootState) => state.users.currentUser
  );
  return (
    <React.Fragment>
      <div
        className={`${classes.dashboard} flex w-full justify-content-between`}
      >
        <div className={classes.main_part}>
          <TopNavbar currentAccount={currentUser} />
          {/* Main Section */} 
          <section className= {`${classes.datatable_section} flex w-full justify-content-between`}>
            <div className={`${classes.base_card} flex-column w-full`}>
              <TabView>
                <TabPanel header="NFTrees">
                  <NFTreeTransactions currentUser={currentUser} />
                </TabPanel>
                <TabPanel header="CCO2">
                  <CCO2Transactions currentUser={currentUser} />
                </TabPanel>
                <TabPanel header="POCC">
                  <PoccTransactions currentUser={currentUser} />
                </TabPanel>
              </TabView>
            </div>
          </section>
        </div>

        {/* Right sidebar */}
        <RightSidebar 
        onThemeChange={(theme) => props.onThemeChange(theme)}/>
      </div>
    </React.Fragment>
  );
};
