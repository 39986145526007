import React, { useEffect, useState } from "react";
import classes from "../../styles/Dashboard.module.scss";
import Icon from "@mdi/react";
import { mdiCertificateOutline, mdiLoading } from "@mdi/js";
import { User } from "../../store/custom-hooks/useUser/usersTypes";
import { Link } from "react-router-dom";
import { polygonConfig } from "../../dappConfig";
import { useMoralis } from "react-moralis";
import { useNavigate } from "react-router-dom";
import UserLogin from "../users/UserLogin";
import { FormattedMessage } from "react-intl";
import { useFetchPocc } from "../../store/custom-hooks/usePocc/useFetchPocc";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";



const Pocc = () => {
  const {user} = useMoralis();
  const navigate = useNavigate();
  const currentUser = useSelector(
    (state: RootState) => state.users.currentUser
  );
  const currentMoralisUser = user;
  const [displayLoginDialog, setDisplayLoginDialog] = useState(false);
  const { loadingPocc : loading} = useFetchPocc();
  const closeLoginDialog = () => {
    setDisplayLoginDialog(false);
  };
  const goToCertificates = () => {
    currentMoralisUser
      ? navigate("/certificates")
      : setDisplayLoginDialog(true);
  };
useEffect(()=>{
  currentUser.pocc?.length > 0 ??  console.log("currentUser in Pocc",currentUser.pocc);
  
},[currentUser]);
  return (
    <React.Fragment>
      <div className={`${classes.base_card} ${classes.card} flex-column justify-content-between`}>
        {/* title */}
        <div className="flex align-items-center w-full">
          <Icon
            path={mdiCertificateOutline}
            size={1.5}
            color="#0a5566"
            className="mr-2"
          ></Icon>
          <strong><FormattedMessage id="pocc_certificates"></FormattedMessage></strong>
        </div>

        {/* Body */}
        <div className={`${classes.card_body} flex w-full flex-column min-h-0 justify-content-center`}>
          <div
            className={`${classes.bordered_div} flex`}
          >
            <span><FormattedMessage id="certificates_created"></FormattedMessage> : </span>

            <span>
              {loading ? (
                <Icon
                  className={classes.spinner}
                  path={mdiLoading}
                  style={{ color: "#444" }}
                />
              ) : (
                <Link to="/certificates">{currentUser.pocc?.length}</Link> //PoCC number
              )}
            </span>
          </div>
          <div className="flex justify-content-between">
            <span><FormattedMessage id="cco2_burned"></FormattedMessage></span>
            <span>
              {(+currentUser.totalCompensation).toLocaleString(undefined, {
                maximumFractionDigits: 2,
              })}
            </span>
          </div>
        </div>
        <div className="flex justify-content-between">
          <Link to="/transactions">
            <button
              className={`${classes.dapp_btn} mt-4 xl:mt-2 flex justify-content-center `}
            >
              <FormattedMessage id="transactions"></FormattedMessage>
            </button>
          </Link>
          <button
            onClick={goToCertificates}
            className={`${classes.secondary_btn} mt-4 xl:mt-2 flex justify-content-center `}
          >
            <FormattedMessage id="certificates"></FormattedMessage>
          </button>
        </div>
      </div>

      {/* User Login Dialog */}
      {displayLoginDialog && (
        <UserLogin
          displayLoginDialog={displayLoginDialog}
          onHide={closeLoginDialog}
        />
      )}
    </React.Fragment>
  );
};

export default Pocc;
