import React, { useState } from "react";
import classes from "./../styles/Dashboard.module.scss";
import TopNftreeHolders from "../components/right-side-bar/TopNftreeHolders";
import TopCO2Compensators from "../components/right-side-bar/TopCO2Compensators";
import LiveStatistics from "../components/right-side-bar/LiveStatistics";
import ProfileBar from "../components/right-side-bar/ProfileBar";

interface RightSidebarProps {
  onThemeChange?: (newTheme: string) => void;
}
const RightSidebar = ({ onThemeChange }: RightSidebarProps) => {

  const [totalCO2Compensated, setTotalCO2Compensated] = useState<string>("");

  return (
    <React.Fragment>
      <div className={`${classes.right_sidebar}`}>
        {/* Profile Bar */}
        <ProfileBar onThemeChange={onThemeChange} />
        {/* Statistics */}
        <LiveStatistics totalCO2Compensated={totalCO2Compensated} setTotalCO2Compensated={setTotalCO2Compensated} />
        {/* NFT holders */}
        <TopNftreeHolders />
        {/* CO2 Compensator */}
        <TopCO2Compensators setTotalCO2Compensated={setTotalCO2Compensated} />
      </div>
    </React.Fragment>
  );
};

export default RightSidebar;
