import { useEffect, useState } from "react";
import { User } from "../useUser/usersTypes";
import stakeABI from "../../../services/abi-dev/stakeAbi";
import { polygonConfig } from "../../../dappConfig";
import { useMoralis } from "react-moralis";
import { differenceInSeconds } from "date-fns";

interface StakedProps {
  currentUser: User;
}

export const useStakingContract = ({ currentUser }: StakedProps) => {
  const { isAuthenticated } = useMoralis();
  const Moralis = require("moralis-v1");

  const [balanceOf, setBalanceOf] = useState<number | undefined>(0);
  const [earned, setEarned] = useState<number | undefined>(0);
  const [rewardRate, setRewardRate] = useState<number | undefined>(0);
  const [totalSupply, setTotalSupply] = useState<number | undefined>(0);

  const getBalanceOf = async () => {
    try {
        ;
        const options = {
          chain: "polygon",
          address: polygonConfig.STAKED_TOKEN_ADDRESS,
          function_name: "balanceOf",
          abi: stakeABI,
          params: {
          "":currentUser.ethAddress,
          },
        };
        const count = await Moralis.Web3API.native.runContractFunction(options);
        const balanceOf = parseFloat(count) / Math.pow(10, 18);
        return balanceOf;
      } catch (error) {
        console.error(error);
      
      }
  };

  const getEarned = async () => {
    try {

        const options = {
          chain: "polygon",
          address: polygonConfig.STAKED_TOKEN_ADDRESS,
          function_name: "earned",
          abi: stakeABI,
          params: {
          _account: currentUser.ethAddress,
          },
          
        }; 
        const count = await Moralis.Web3API.native.runContractFunction(options);
        const earned = parseFloat(count) / Math.pow(10, 18);
        return earned;
      } catch (error) {
        console.error(error);
       
      }
};

  const getRewardRate = async () => {
    try {

        const options = {
          chain: "polygon",
          address: polygonConfig.STAKED_TOKEN_ADDRESS,
          function_name: "rewardRate",
          abi: stakeABI,
          params: {},
        };
        const count = await Moralis.Web3API.native.runContractFunction(options);
        const rewardRate = parseFloat(count) / Math.pow(10, 18);
        return rewardRate;
      } catch (error) {
        console.error(error);
      }
};

  const getTotalSupply = async () => {
    try {

        const options = {
          chain: "polygon",
          address: polygonConfig.STAKED_TOKEN_ADDRESS,
          function_name: "totalSupply",
          abi: stakeABI,
          params: {},
        };
        const count = await Moralis.Web3API.native.runContractFunction(options);
        const totalSupply = parseFloat(count) / Math.pow(10, 18);
        return totalSupply;
      } catch (error) {
        console.error(error);
      }
  };




  useEffect(() => {
    const fetchData = async () => {
        try {
          const balance = isAuthenticated? await getBalanceOf() : 0;
          setBalanceOf(balance);
          const earnedAmount = isAuthenticated? await getEarned() : 0;     
          setEarned(earnedAmount);
        } catch (error) {
          console.error(error);
        }
    };
    fetchData();
  }, [currentUser]);



useEffect(() => {
  const fetchData = async () => {
  const rate = await getRewardRate();
  setRewardRate(rate);
  const totalStaked = await getTotalSupply();
  setTotalSupply(totalStaked);
}
fetchData();
}, [])


useEffect(() => {
  let crstData: {
    rewardRate: number;
    totalSupply: number;
    balanceOf?: number;
    earned?: number;
    date: number;
  } = {
    rewardRate: rewardRate || 0,
    totalSupply: totalSupply || 0,
    date: Date.now(),
  };

  if (isAuthenticated) {
    crstData.balanceOf = balanceOf || 0;
    crstData.earned = earned || 0;
  }

  localStorage.setItem("crstData", JSON.stringify(crstData));
}, [balanceOf, earned, rewardRate, totalSupply, isAuthenticated]);



useEffect(() => {
  const storedcrstData = localStorage.getItem("crstData");
  if (storedcrstData) {
    const parsedcrstData = JSON.parse(storedcrstData);
    const storedDate = parsedcrstData.date;

    const secondsElapsed = differenceInSeconds(Date.now(), storedDate);    
    if (secondsElapsed <= 300) {
      if (isAuthenticated) {
        setBalanceOf(parsedcrstData.balanceOf);
        setEarned(parsedcrstData.earned);
      }
      setRewardRate(parsedcrstData.rewardRate);
      setTotalSupply(parsedcrstData.totalSupply);
      return;
    }
  }
}, [isAuthenticated]);


  return {
    balanceOf,
    earned,
    rewardRate,
    totalSupply,
  };
};
