import React, { useState } from "react";
import { useMoralis } from "react-moralis";
import Co2Tokens from "../components/dashboard/Co2Tokens";
import NFTrees from "../components/dashboard/NFTrees";
import Pocc from "../components/dashboard/Pocc";
import Staked from "../components/dashboard/Staked";
import TokenBalance from "../components/dashboard/TokenBalance";
import TopNavbar from "../components/common/top-navbar/NavbarTop";
import UserLogin from "../components/users/UserLogin";
import { useAppSelector } from "../store/hooks";
import { RootState } from "../store/store";
import classes from "./../styles/Dashboard.module.scss";
import RightSidebar from "./RightSidebar";

export interface DashboardProps {
  onThemeChange: (newTheme: string) => void;
}

const Dashboard = (props: DashboardProps) => {
  const currentUser = useAppSelector(
    (state: RootState) => state.users.currentUser
  );

  const Moralis = require("moralis-v1");
  const {user} = useMoralis();
  const currentMoralisUser = user;

  const [displayLoginDialog, setDisplayLoginDialog] = useState(false);

  const closeLoginDialog = () => {
    setDisplayLoginDialog(false);
  };

  return (
    <React.Fragment>
      <div className={classes.dashboard}>
        <div className={`${classes.main_part} flex-column justify-content-between`}>
          <div className="flex-column">
{/*           <HamburgerMenu onAppLocaleChange={function (newAppLocale: string): void {
              throw new Error("Function not implemented.");
            } } /> */}
          <TopNavbar currentAccount={currentUser}/>
         
          {/* Login button */}
          {/* {!currentMoralisUser && (
            <button
              onClick={() => setDisplayLoginDialog(true)}
              className={`${classes.dapp_btn} ${classes.login_btn} `}
            >
              <Icon
                className="text-white mr-2"
                path={mdiWallet}
                style={{ width: "19px", top: "2px", position: "relative" }}
              ></Icon>
              <span style={{ bottom: "2px", position: "relative" }}>
                Connect wallet
              </span>
            </button>
          )}</div>
          )} */}
        </div>
          <Staked currentUser={currentUser} />
          
          {/* Main Section */}
          <section className={`${classes.main_section}`}>
          
            <NFTrees currentUser={currentUser}/>
            <TokenBalance currentUser={currentUser}/>
            <Co2Tokens
              currentUser={currentUser}
            />
            <Pocc
              
            />
          </section>
        </div>

        {/* Right sidebar */}
        <RightSidebar
          
          onThemeChange={(theme) => props.onThemeChange(theme)}
        />
      </div>
      {/* User Login Dialog */}
      {displayLoginDialog && (
        <UserLogin
          displayLoginDialog={displayLoginDialog}
          onHide={closeLoginDialog}
        />
      )}
    </React.Fragment>
  );
};

export default Dashboard;
