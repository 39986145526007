import React from "react";
import classes from "../../styles/Dashboard.module.scss";
import coorestIcon from "../../assets/images/tokens/token.png";
import { FormattedMessage } from "react-intl";
import { useStakingContract } from "../../store/custom-hooks/useStakingContract/useStakingContract";
import { User } from "../../store/custom-hooks/useUser/usersTypes";

interface StakedProps {
  currentUser: User;
}

const Staked = ({ currentUser }: StakedProps) => {
  const { balanceOf, earned, rewardRate, totalSupply } = useStakingContract({
    currentUser,
  });

  const stakingLink = () => {
    window.open('https://app.unvest.io/projects/137/0x91f0484f9b65dc5187e414dae5ed37ea7a4b8af4/staking-pools/0x870b1c854ccfeb2c540836efed7969018a2d1406', '_blank');
  };

  const defaultValue: number = 0;

  return (
    <React.Fragment>
      <div
        className={`${classes.base_card} ${classes.top_base_card} flex-column`}
      >
        {/* Total balance */}
        <div
          className={classes.total_balance}
          style={{ marginTop: "8px", marginBottom: "8px" }}
        >
          <span style={{ marginLeft: "15px" }}>
            <FormattedMessage id="your_stake" />:
          </span>
          <strong
            className={classes.current_balance}
            style={{ fontFamily: "sans-serif" }}
          >
            {balanceOf !== undefined ? Math.ceil(balanceOf) : defaultValue} CRST
          </strong>
        </div>

        {/* Staked tokens */}
        {/*         <div
          className={`${classes.stake_title} flex justify-content-start`}
          style={{ marginLeft: "15px" }}
        >
          <FormattedMessage id="staked_crst" />
        </div> */}

        <div className={`${classes.base_card} ${classes.staked_tokens}`}>
         
          <button
            className={`
${classes.secondary_btn} 
${classes.button_with_icon} 
flex justify-content-between align-items-center
`}
onClick={stakingLink}
          >
                <span className={` ${classes.menu_title}`}>
                 <FormattedMessage id="stakeCRST" />
                  </span>
            <img
              src={coorestIcon}
              alt="CRST"
              className={`${classes.coorest_token} mt-1 p-1`}
            />
          </button>

<div className={`${classes.staked_data}`}>

          <div>
            <span className={classes.strong_blue}>
              <FormattedMessage id="earned" />
            </span>
            <span>{earned !== undefined ? Math.ceil(earned) : defaultValue}</span>
          </div>

          <div>
            <span className={classes.strong_blue}>
              <FormattedMessage id="reward_rate" />
            </span>
            <span>
              {/* reward rate  */}
              {rewardRate !== undefined
                ? `${(rewardRate * 100).toFixed(1)}%`
                : defaultValue}
            </span>
          </div>

          <div>
            <span className={classes.strong_blue}>
              <FormattedMessage id="total_staked" />
            </span>
            <span>
              {totalSupply !== undefined ? Math.ceil(totalSupply) : defaultValue}
            </span>
          </div>
          
          </div>



        </div>
      </div>
    </React.Fragment>
  );
};

export default Staked;
