import { mdiMenu } from "@mdi/js";
import Icon from "@mdi/react";
import { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/images/logo/Logo-sidebar.png";
import Sidebar from "../pages/Sidebar";
import classes from "./Dashboard.module.scss";

import { useEffect } from 'react';

export interface HamburgerMenuProps {
  onAppLocaleChange: (newAppLocale: string) => void;  
}

const HamburgerMenu = (props: HamburgerMenuProps) => {
  const [showSidebar, setShowSidebar] = useState(false);

  useEffect(() => {
    function handleClickOutsideSidebar(event: MouseEvent) {
      if (showSidebar && !(event.target as Element).closest(".sidebar")) {
        setShowSidebar(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutsideSidebar);

    return () => {
      document.removeEventListener("mousedown", handleClickOutsideSidebar);
    };
  }, [showSidebar]);

  return (
    <>
      {/* Hamburger Menu */}
      <div className={classes.hamburger_menu} >
        <span onClick={() => setShowSidebar(true)}>
          <Icon path={mdiMenu} size={1.5}></Icon>
        </span>
        <Link to="/">
          <img width={"100px"} height={"auto"} src={logo} alt="Coorest Dapp" />
        </Link>
          {/* Navigation sidebar */}
          <Sidebar
          closeSidebar={() => setShowSidebar(false)}
          showSidebar={showSidebar}
          onAppLocaleChange={props.onAppLocaleChange}
        />
      </div>

    </>
  );
};

export default HamburgerMenu;
