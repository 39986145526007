import { mdiPound } from "@mdi/js";
import Icon from "@mdi/react";
import { format } from "date-fns";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Message } from "primereact/message";
import React from "react";
import { useIntl } from "react-intl";
import { polygonConfig } from "../../dappConfig";
import { User } from "../../store/custom-hooks/useUser/usersTypes";
import classes from "../../styles/Transactions.module.scss";
import { useMoralisWeb3Api } from "react-moralis";
import { usePoccTransfer } from "../../store/custom-hooks/usePocc/usePoccTransfer";
import DappLoading from "../../ui/DappLoading";

interface PoccTransactionsProps {
  currentUser: User;
}

export const PoccTransactions = ({ currentUser }: PoccTransactionsProps) => {
  const { data: poccTransfers, meta, loading, error } = usePoccTransfer();
  const intl = useIntl();
  const sentMessage = intl.formatMessage({id: 'sent'});
  const generatedMessage = intl.formatMessage({id: 'generated'});
  const statusMessage = intl.formatMessage({id: 'status'});
  const tokenIdMessage = intl.formatMessage({id: 'token_id'});
  const dateMessage = intl.formatMessage({id: 'date'});
  const certificateIdMessage = intl.formatMessage({id: 'certificate_id'});
  const compare = (a: any, b: any) => {
    if (a.date < b.date) {
      return 1;
    }
    if (a.date > b.date) {
      return -1;
    }
    return 0;
  };

  const transferStatus = (rowData: any) => {
    return rowData.from === currentUser.ethAddress ? sentMessage : generatedMessage;
  };

  const convertDate = (rowData: any) => {
    return format(new Date(rowData.date), "dd MMMM Y");
  };

  const generateTxLink = (rowData: any) => {
    return (
      <a
        href={polygonConfig.Txhash + rowData.transactionHash}
        target="_blank"
        rel="noreferrer"
      >
        <Button
          icon={<Icon path={mdiPound} style={{ width: "20px" }} />}
          className="p-button-rounded p-button-text"
        />
      </a>
    );
  };

  return (
    <React.Fragment>
      {loading && <DappLoading />}
      {error ? (
        <Message
          severity="error"
          text="Something Went wrong. Please try again later"
        ></Message>
      ) : (    
        <DataTable
        style={{ width: "100%", zIndex: "0"}}
        scrollHeight="flex"
          value={poccTransfers.sort(compare)}
          stripedRows
          responsiveLayout="scroll"
          scrollable
      
          className={classes.datatable}
          size="small"
          paginator
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={10} rowsPerPageOptions={[10,20,50]} pageLinkSize={3}
        >
          <Column header={statusMessage} body={transferStatus}></Column>
          <Column field="tokenId" header={certificateIdMessage}></Column>
          <Column header={dateMessage} body={convertDate}></Column>
          <Column header="Tx" body={generateTxLink}></Column>
        </DataTable>     
      )}
    </React.Fragment>
  );
};
