export function chunk(array : any[], size :number) {
    const chunkedArray = [];
    let index = 0;
    while (index < array.length) {
      chunkedArray.push(array.slice(index, size + index));
      index += size;
    }
    return chunkedArray;
  }

  export const convertToBase64 = (doc: any): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result as string;
        const dataUrlParts = base64String.split(",");
        if (dataUrlParts.length === 2) {
          resolve(dataUrlParts[1]);
        } else {
          reject(new Error("Failed to convert document to base64."));
        }
      };
      reader.onerror = reject;
      reader.readAsDataURL(doc.output("blob"));
    });
  };
  
