import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Message } from "primereact/message";
import React from "react";
import { useIntl } from "react-intl";
import { polygonConfig } from "../../dappConfig";
import { User } from "../../store/custom-hooks/useUser/usersTypes";
import { format } from "date-fns";
import classes from "../../styles/Transactions.module.scss";
import { Button } from "primereact/button";
import { mdiPound } from "@mdi/js";
import Icon from "@mdi/react";
import { useTransfer } from "../../store/custom-hooks/useNFTree/useTransfer";
import DappLoading from "../../ui/DappLoading";

interface NFTreeTransactionsProps {
  currentUser: User;
}

export const NFTreeTransactions = ({
  currentUser,
}: NFTreeTransactionsProps) => {
  const { data: nftreeTransfers, meta, error, loading } = useTransfer();
  const intl = useIntl();
  const receivedMessage = intl.formatMessage({id: 'received'});
  const sentMessage = intl.formatMessage({id: 'sent'});
  const compare = (a: any, b: any) => {
    if (a.date < b.date) {
      return 1;
    }
    if (a.date > b.date) {
      return -1;
    }
    return 0;
  };

  const transferStatus = (rowData: any) => {
    return rowData.from === currentUser.ethAddress ? sentMessage : receivedMessage;
  };

  const convertDate = (rowData: any) => {
    return format(new Date(rowData.date), "dd/MM/yyyy");
  };

  const generateTxLink = (rowData: any) => {
    return (
      <a
        href={polygonConfig.Txhash + rowData.transactionHash}
        target="_blank"
        rel="noreferrer"
      >
        <Button
          icon={<Icon path={mdiPound} style={{ width: "20px" }} />}
          className="p-button-rounded p-button-text"
        />
      </a>
    );
  };

  return (
    <React.Fragment>
      {loading && <DappLoading />}
      {error ? (
        <Message
          severity="error"
          text="Something Went wrong. Please try again later"
        ></Message>
      ) : (
        <DataTable
        style={{ width: "100%", zIndex: "0"}}
        scrollHeight="flex"
          value={nftreeTransfers.sort(compare)}
          stripedRows
          responsiveLayout="scroll"
          scrollable
          className={classes.datatable}
          size="small"
          paginator
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={10} rowsPerPageOptions={[10,20,50]} pageLinkSize={3}
          >
          <Column header="Status" body={transferStatus}></Column>
          <Column field="tokenId" header="NFTree ID"></Column>
          <Column header="Date" body={convertDate}></Column>
          <Column header="Tx" body={generateTxLink}></Column>
        </DataTable>
      )}
    </React.Fragment>
  );
};
