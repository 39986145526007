import { useEffect, useState } from "react";
import { useMoralis, useMoralisWeb3Api } from "react-moralis";
import { polygonConfig } from "../../../dappConfig";
import { differenceInSeconds } from "date-fns";
import { ethers } from "ethers";

export function useTokensBalance() {
  const co2Contract = polygonConfig.CO2_TOKEN_ADDRESS;
  const crstContract = polygonConfig.CRST_TOKEN_ADDRESS;
  const usdcContract = polygonConfig.USDC_TOKEN_ADDRESS;

  const Web3Api = useMoralisWeb3Api();
  const { user,isAuthenticated } = useMoralis();
  const [tokens, setTokens] = useState({
    co2: 0,
    crst: 0,
    usdc: 0,
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  // To keep track on the tokens object if it stills empty
  const [isFetched, setIsFetched] = useState(false);
  const fetch = async (): Promise<void> => {
    console.log("!--Started Fetching token balance from the server--!");
    setLoading(true);
    try {
      const options: { address: any; chain: any } = {
        address: user?.attributes.ethAddress,
        chain: polygonConfig.chainName,
      };
      const result = await Web3Api?.account?.getTokenBalances(options as any);

      if (result) {
        const co2 = result.find((token) => token.token_address === co2Contract);
        const crst = result.find((token) => token.token_address === crstContract);
        const usdc = result.find((token) => token.token_address === usdcContract);
      
        const newTokens = {
          co2:
            co2 !== undefined // No Error in fetchin data
              ? (ethers as any).BigNumber.from("" + co2.balance) / Math.pow(10, 18)
              : 0,
          crst: crst !== undefined ? (ethers as any).BigNumber.from("" + crst.balance) / Math.pow(10, 18) : 0,
          usdc: usdc !== undefined ? (ethers as any).BigNumber.from("" + usdc.balance) / Math.pow(10, 18) : 0,
        };
        
        localStorage.setItem("tokensBalance", JSON.stringify({ data: newTokens, date: Date.now() }));
        setTokens(newTokens);
      }
    } catch (err) {
      console.log(err);
      setError(true);
    } finally {
      setLoading(false);
      setIsFetched(true);
    }
  };

  useEffect(() => {
    if (!user || !isAuthenticated) return;
    const cachedData = localStorage.getItem("tokensBalance");
    if (cachedData) {
      const { data, date } = JSON.parse(cachedData);
      const secondsElapsed = differenceInSeconds(Date.now(), date);
      if (secondsElapsed <= 240) {
        setTokens(data);
        setIsFetched(true);
        return;
      }
    }
    fetch();
  }, [user,isAuthenticated]);

  return { fetch, tokens, loading, error, isFetched };
}
