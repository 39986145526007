// Description: This file should contain functions or methods for making API calls or handling data retrieval from a server.
import axios from "axios";
import { polygonConfig } from "../dappConfig";


const Moralis = require("moralis-v1");

export const postTryCarbonApi = async (selectedOption: string, data: any) => {
    try {
        const response = await axios({
            method: "post",
            url: polygonConfig.TRY_CARBON_API + selectedOption,
            headers: {
              Authorization: polygonConfig.TRY_CARBON_TOKEN ,
            },
            data,
          });
      return response.data;
    } catch (error) {
      // Handle error
      console.error(error);
      throw error;
    }
  };

export const getMerkleTreeApi  = async (allNFTrees: number[]) => {
    try {
        let response = await axios.get(polygonConfig.MERKLE_TREE_API, {
            params: {
              token_id_in: allNFTrees.join(","),
            },
          });
      return response.data;
    } catch (error) {
      // Handle error
      console.error(error);
      throw error;
    }
  };

  export const getTotalCO2Absorbed = async () => {
    try {
      const response = await axios.get(
        polygonConfig.TOTAL_CO2_API
      );
      return response.data["total_co2_absorbed"];
      
    } catch (error) {
      // Handle error
      console.error(error);
      throw error;
    }
  };
  
  export async function uploadToIPFS(tokenId:any, base64File:any) {
    const fileUrl = await Moralis.Cloud.run("uploadToIPFS", {
      path: tokenId,
      base64File: base64File,
    });
    return fileUrl;
  }
