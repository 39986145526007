import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { mdiLoading } from "@mdi/js";
import Icon from "@mdi/react";
import { Dropdown } from "primereact/dropdown";
import classes from "../../styles/CO2Calculator.module.scss";
import { InputText } from "primereact/inputtext";
import { useTranslations  } from "../../i18n/useTranslations";
import { postTryCarbonApi } from "../../utils/api";
interface EmissionCalculatorProps {
  setTotalFootprint: React.Dispatch<React.SetStateAction<number>>;
}

const EmissionCalculator: React.FC<EmissionCalculatorProps> = ({
  setTotalFootprint,
}) => {
  const TRANSLATIONS = useTranslations ();
  const intl = useIntl();
  const [selectedOption, setSelectedOption] = useState("flight");
  const [distance, setDistance] = useState(0);
  const [type, setType] = useState("LongFirstClassFlight");
  const [litres, setLitres] = useState(0);
  const [co2CalculationLoading, setCo2CalculationLoading] = useState(false);
  const options = [
    { label: TRANSLATIONS.flight, value: "flight" },
    { label: TRANSLATIONS.carTravel, value: "carTravel" },
    { label: TRANSLATIONS.fuelToCo2, value: "fuelToCO2e" },
  ];

  const flightTypes = [
    {
      label: TRANSLATIONS.longFirstClassFlight,
      value: "LongFirstClassFlight",
    },
    {
      label: TRANSLATIONS.longBusinessClassFlight,
      value: "LongBusinessClassFlight",
    },
    {
      label: TRANSLATIONS.longEconomyClassFlight,
      value: "LongEconomyClassFlight",
    },
    {
      label: TRANSLATIONS.shortBusinessClassFlight,
      value: "ShortBusinessClassFlight",
    },
    {
      label: TRANSLATIONS.shortEconomyClassFlight,
      value: "ShortEconomyClassFlight",
    },
  ];

  const carTypes = [
    {
      label: TRANSLATIONS.smallDieselCar,
      value: "SmallDieselCar",
    },
    {
      label: TRANSLATIONS.mediumDieselCar,
      value: "MediumDieselCar",
    },
    {
      label: TRANSLATIONS.largeDieselCar,
      value: "LargeDieselCar",
    },
    {
      label: TRANSLATIONS.mediumHybridCar,
      value: "MediumHybridCar",
    },
    {
      label: TRANSLATIONS.largeHybridCar,
      value: "LargeHybridCar",
    },
    {
      label: TRANSLATIONS.smallPetrolVan,
      value: "SmallPetrolVan",
    },
    {
      label: TRANSLATIONS.largePetrolVan,
      value: "LargePetrolVan",
    },
  ];

  const fuelTypes = [
    { label: TRANSLATIONS.petrol, value: "Petrol" },
    { label: TRANSLATIONS.diesel, value: "Diesel" },
    { label: TRANSLATIONS.lpg, value: "LPG" },
  ];

  const calculateFootprint = async () => {
    try {
      setCo2CalculationLoading(true);
      let data;
      if (selectedOption == "flight") {
        data = {
          distance: distance,
          type: type,
        };
      }
      if (selectedOption == "carTravel") {
        data = {
          distance: distance,
          vehicle: type,
        };
      }
      if (selectedOption == "fuelToCO2e") {
        data = {
          type: type,
          litres: litres,
        };
      }
      console.log("data", data);

      const response = await postTryCarbonApi(selectedOption, data);
      console.log(
        "response",
        parseFloat(response.carbon.match(/[\d\.]+/))
      );
      setTotalFootprint(parseFloat(response.carbon.match(/[\d\.]+/)));
    } catch (error) {
      console.error(error);
    } finally {
      setCo2CalculationLoading(false);
    }
  };
  return (
    <div className={`${classes.base_card}  flex-column w-full m-auto p-3`}>
      <div className={`flex flex-column w-full g-0`}>
        <div className={`${classes.title}`}>
          <FormattedMessage id="chooseCategory" />
        </div>
        <Dropdown
          className={`w-full`}
          value={selectedOption}
          options={options}
          onChange={(e) => setSelectedOption(e.value)}
          placeholder={TRANSLATIONS.selectOption}
        />
      </div>

      {selectedOption === "flight" && (
        <>
          <div className={`flex flex-column w-full g-0`}>
            <div className={`${classes.title}`}>
              <FormattedMessage id="distance" />
            </div>
            <InputText
              type="number"
              value={distance}
              onChange={(e) => setDistance(parseInt(e.target.value))}
              placeholder={TRANSLATIONS.theDistanceInKM}
            />
          </div>
          <div className={`flex flex-column w-full g-0`}>
            <div className={`${classes.title}`}>
              <FormattedMessage id="type" />
            </div>
            <Dropdown
              value={type}
              options={flightTypes}
              onChange={(e) => setType(e.value)}
              placeholder={TRANSLATIONS.selectFlightClass}
            />
          </div>
        </>
      )}

      {selectedOption === "carTravel" && (
        <>
          <div className={`${classes.title}`}>
            <FormattedMessage id="distance" />
          </div>
          <InputText
            type="number"
            value={distance}
            onChange={(e) => setDistance(parseInt(e.target.value))}
            placeholder={TRANSLATIONS.theDistanceInKM}
          />
          <div className={`${classes.title}`}>
            <FormattedMessage id="type" />
          </div>
          <Dropdown
            value={type}
            options={carTypes}
            onChange={(e) => setType(e.value)}
            placeholder={TRANSLATIONS.selectCarType}
          />
        </>
      )}

      {selectedOption === "fuelToCO2e" && (
        <>
          <div className={`${classes.title}`}>
            <FormattedMessage id="type" />
          </div>
          <Dropdown
            value={type}
            options={fuelTypes}
            onChange={(e) => setType(e.value)}
            placeholder={TRANSLATIONS.selectFuelType}
          />
          <div className={`${classes.title}`}>
            <FormattedMessage id="liters" />
          </div>
          <InputText
            type="number"
            value={litres}
            onChange={(e) => setLitres(parseInt(e.target.value))}
            placeholder={TRANSLATIONS.numberOfLiters}
          />
        </>
      )}

      <div className={`mt-1`}>
        <button
          className={`${classes.dapp_btn} xl:mt-2 mr-1 claimIcon`}
          onClick={calculateFootprint}
        >
          {co2CalculationLoading ? (
            <span className="text-0">
              <Icon
                className={classes.spinner}
                path={mdiLoading}
                color="#232323"
              />{" "}
            </span>
          ) : (
            <FormattedMessage id="calculateYourEmission" />
          )}
        </button>
      </div>
    </div>
  );
};

export default EmissionCalculator;
