import { mdiAccountCircle } from "@mdi/js";
import Icon from "@mdi/react";
import { FileUpload } from "primereact/fileupload";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import React, { useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useMoralis, useMoralisFile } from "react-moralis";
import TopNavbar from "../components/common/top-navbar/NavbarTop";
import UserLogin from "../components/users/UserLogin";
import { updateAvatar, updateUserProfile } from "../store/custom-hooks/useUser/usersSlice";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { RootState } from "../store/store";
import DappBoxLoading from "../ui/DappBoxLoading";
import classes from "./../styles/Profile.module.scss";
import RightSidebar from "./RightSidebar";
import { useTranslations } from "../i18n/useTranslations"

export interface ProfileProps {
  onThemeChange: (newTheme: string) => void;
}

const Profile = (props: ProfileProps) => {
  const currentUser = useAppSelector((state: RootState) => state.users.currentUser);
  const toast = useRef(null);
  const { user, setUserData, userError, isUserUpdating } = useMoralis();
  const currentMoralisUser = user;
  const dispatch = useAppDispatch();

  const [displayLoginDialog, setDisplayLoginDialog] = useState(false);

  const [fullName, setfullName] = useState(user?.attributes.fullName);
  const [email, setEmail] = useState(user?.attributes.email);
  const [company, setCompany] = useState(user?.attributes.company);
  const [invalidfullName, setInvalidfullName] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const TRANSLATIONS = useTranslations();

  const cancelUpdate = () => {
    setfullName(user?.attributes.fullName);
    setEmail(user?.attributes.email);
    setCompany(user?.attributes.company);
  };

  const handleUpdate = () => {
    if (!invalidEmail && !invalidfullName) {
      setUserData({
        fullName,
        email,
        company,
      });
      dispatch(
        updateUserProfile({
          fullName,
          email,
          company,
        })
      );
      toast.current &&
        (toast.current as any).show({
          severity: "success",
          summary: TRANSLATIONS.success,
          detail: TRANSLATIONS.profile_updated,
          life: 3000,
        });
    }
  };

  const uploadPicture = (event: any) => {
    currentMoralisUser ? handleFileUpload(event) : setDisplayLoginDialog(true);
  };

  const closeLoginDialog = () => {
    setDisplayLoginDialog(false);
  };

  const [profilePicture, setProfilePicture] = useState("");
  const [pictureFile, setPictureFile] = useState<any>(null);

  const { error, isUploading, saveFile } = useMoralisFile();

  React.useEffect(() => {
    if (user?.attributes.profilePicture) {
      setProfilePicture(user.attributes.profilePicture._url);
    }
  }, [user]);

  const handleFileUpload = async (event: any) => {
    const file = event.files[0];
    if (file) {
      const name = file.name;
      let fileIpfs = await saveFile(name, file, { saveIPFS: true });
      user?.set("profilePicture", fileIpfs);
      fileIpfs && user?.set("profilePictureAddress", fileIpfs._url);
      try {
        await user?.save();
        setProfilePicture(user?.attributes.profilePicture._url);
        dispatch(
          updateAvatar({
            profilePicture: user?.attributes.profilePicture._url,
          })
        );
      } catch (err) {
        toast.current &&
          (toast.current as any).show({
            severity: "error",
            summary: TRANSLATIONS.error,
            detail: TRANSLATIONS.check_your_file,
            life: 3000,
          });

        console.log(err);
      } finally {
        fileUploader.current.clear();
      }
    }
  };

  const emailChange = (email: string) => {
    const pattern = /\S+@\S+\.\S+/;
    !email || !pattern.test(email) ? setInvalidEmail(true) : setInvalidEmail(false);
    setEmail(email);
  };

  const fullNameChange = (name: string) => {
    name ? setInvalidfullName(false) : setInvalidfullName(true);
    setfullName(name);
  };

  /** File uploader icon */
  const chooseOptions = { label: ".", icon: "pi pi-camera" };
  const fileUploader = useRef<any>(null);
  return (
    <React.Fragment>
      <div className={`${classes.dashboard}`}>
        <div className={classes.main_part}>
          <TopNavbar currentAccount={currentUser} />
          {/* Main Section */}
          <section className="flex w-full flex-wrap justify-content-between mt-6">
            <div className={`${classes.base_card_profile} flex-column w-full justify-content-center relative`}>
              {/* Profile Picture */}

              <div className={`${classes.profile_header} flex justify-content-center align-items-center`}>
                <div className={`${classes.avatar}`}>
                  {isUploading && (
                    <div className={`${classes.loading_overlay}`}>
                      <DappBoxLoading />
                    </div>
                  )}
                  <div>
                    {currentUser.profilePicture ? (
                      <img src={profilePicture} alt={currentUser.fullName} />
                    ) : (
                      <Icon path={mdiAccountCircle} className={classes.no_avatar} />
                      )}
                      <FileUpload
                      ref={fileUploader}
                      mode="basic"
                      name="demo[]"
                      maxFileSize={3000000}
                      auto
                      customUpload
                      chooseOptions={chooseOptions}
                      className={`${classes.camera} cursor-pointer`}
                      onSelect={(event) => {
                        const files = event.files;
                        const allowedTypes = ["image/jpeg", "image/png"];
                        for (let file of files) {
                          if (!allowedTypes.includes(file.type)) {
                            //event.preventDefault();
                            alert("Image type is not supported, please choose another image");
                            fileUploader.current.clear();
                            return;
                          }
                        }
                        handleFileUpload(event); // call uploadHandler only if all files are valid
                      }}
                     // uploadHandler={handleFileUpload}
                      accept=".jpeg,.png"
                    />
                  </div>
                </div>
                <div className="fullName">
                  <h4 className="mx-3 mb-5" style={{fontSize:"28px"}}>{currentUser.fullName}</h4>
                </div>
                <div className="actions flex flex-grow-1 justify-content-end">
                  {isUserUpdating && (
                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                  )}
                </div>
              </div>

              <strong className="my-4">
                <FormattedMessage id="update_your_details"></FormattedMessage>
              </strong>
              <div className={`${classes.form_group} flex flex-column`}>
                {/* Profile Form */}
                <div className="flex flex-row gap-3">
                  <div className="field w-full xl:w-6">
                    <label htmlFor="fullName" className="block">
                      <FormattedMessage id="fullname"></FormattedMessage> *
                    </label>
                    <InputText
                      id="fullName"
                      aria-describedby="fullName-help"
                      className={`${invalidfullName && "p-invalid"} w-full block`}
                      placeholder={currentUser.fullName}
                      value={fullName}
                      onChange={(event) => fullNameChange(event.currentTarget.value)}
                      style={{ borderRadius: "30px" }}
                    />
                    {invalidfullName && (
                      <small id="fullName-help" className="p-error block">
                        Can't be empty
                      </small>
                    )}
                  </div>
                  <div className="field w-full xl:w-6">
                    <label htmlFor="company" className="block">
                      <FormattedMessage id="business_name"></FormattedMessage>
                    </label>
                    <InputText
                      id="company"
                      aria-describedby="company-help"
                      className="w-full block"
                      placeholder={currentUser.company}
                      value={company}
                      onChange={(event) => setCompany(event.currentTarget.value)}
                      style={{ borderRadius: "30px" }}
                    />
                  </div>
                </div>
                <div className="field w-full">
                  <label htmlFor="email" className="block">
                    <FormattedMessage id="email"></FormattedMessage> *
                  </label>
                  <InputText
                    id="email"
                    aria-describedby="email-help"
                    className={`${invalidEmail && "p-invalid"} w-full block`}
                    onChange={(event) => emailChange(event.currentTarget.value)}
                    placeholder={currentUser.email}
                    value={email}
                    style={{ borderRadius: "30px" }}
                  />
                  {invalidEmail && (
                    <small id="email-help" className="p-error block">
                      Email is not valid
                    </small>
                  )}
                </div>
                <button onClick={handleUpdate} className={`${classes.dapp_btn} mb-3`}>
                  <FormattedMessage id="save"></FormattedMessage>
                </button>

                <button onClick={cancelUpdate} className={`${classes.secondary_btn}`}>
                  <FormattedMessage id="cancel"></FormattedMessage>
                </button>
              </div>
            </div>
          </section>
        </div>

        {/* Right sidebar */}
        <RightSidebar onThemeChange={(theme) => props.onThemeChange(theme)} />
      </div>

      {/* User Login */}
      {displayLoginDialog && <UserLogin displayLoginDialog={displayLoginDialog} onHide={closeLoginDialog} />}

      {/* Toast */}
      <Toast ref={toast} />
    </React.Fragment>
  );
};

export default Profile;
