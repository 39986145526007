import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface LocaleState {
  value: string;
}

const initialState: LocaleState = {
  value: localStorage.getItem('locale') || 'en',
};

export const localeReducer = createSlice({
  name: 'locale',
  initialState,
  reducers: {
    setLocale: (state, action: PayloadAction<string>) => {
      state.value = action.payload;
      localStorage.setItem('locale', action.payload);
    },
  },
});

export const { setLocale } = localeReducer.actions;

export default localeReducer.reducer;