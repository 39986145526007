import React from "react"; 
import { useAppSelector } from "../../store/hooks";
import { RootState } from "../../store/store";
import { FormattedMessage } from "react-intl";
import co2Icon from "../../assets/images/tokens/co2.png";
import classes from "../../styles/POCC.module.scss";
import "../../styles/POCC.module.scss";

const CCO2Available = () => {
    const currentUser = useAppSelector(
        (state: RootState) => state.users.currentUser
      );

    return (
        <React.Fragment>
              <span className={`${classes.amount_title} w-full mb-2`}>
                <FormattedMessage id="cco2_available" />
              </span>
              <div
                className={`${classes.amount} px-2 py-1 mr-4 opacity-90 flex align-items-center mb-2`}
                style={{ borderRadius: "30px" }}
              >
                <img
                  src={co2Icon}
                  alt="CCo2"
                  style={{ width: "40px" }}
                  className="mr-2"
                />
                <strong className="mr-6">CCO2</strong>
                <strong>{currentUser.co2Balance.toFixed(2)}</strong>
              </div>
        </React.Fragment>
    )

}

export default CCO2Available;