import { useMoralis } from "react-moralis";
import { userLogout } from "./usersSlice";
import { useAppDispatch } from "../../../store/hooks";
import { useNavigate } from "react-router-dom";

export function useUser() {
  const { logout : moralisLogout } = useMoralis();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const logout = async (): Promise<void> => {
    moralisLogout();
    localStorage.setItem("walletType", "");
    localStorage.removeItem("poccData");
    localStorage.removeItem("nftreesData");
    localStorage.removeItem("claimable");
    localStorage.removeItem("totalCompensation");
    localStorage.removeItem("tokensBalance");
    localStorage.removeItem("crstData");
    dispatch(userLogout());
    navigate("/");
  };
  return { logout };
}
