import { mdiContentCopy } from "@mdi/js";
import Icon from "@mdi/react";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useMoralis } from "react-moralis";
import { Link } from "react-router-dom";
import { User } from "../../../store/custom-hooks/useUser/usersTypes";
import UserLogin from "../../users/UserLogin";
import classes from "./NavbarTop.module.scss";

interface NavbarTopProps {
  currentAccount: User;
}

const NavbarTop = ({ currentAccount }: NavbarTopProps) => {
  const Moralis = require("moralis-v1");
  const {user} = useMoralis();
  const currentUser = user;

  const [value, setValue] = useState("");
  const [copyToClipboard, setCopyToClipboard] = useState(false);
  const [displayLoginDialog, setDisplayLoginDialog] = useState(false);

  const closeLoginDialog = () => {
    setDisplayLoginDialog(false);
  };

  const copyEth = () => {
    if (currentUser) {
      const el = document.createElement("textarea");
      el.value = currentAccount.ethAddress;
      el.setAttribute("readonly", "");
      el.style.position = "absolute";
      el.style.left = "-9999px";
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      setCopyToClipboard(true);
      setTimeout(() => {
        setCopyToClipboard(false);
      }, 1000);
    } else {
      setDisplayLoginDialog(true);
    }
  };

  return (
    <React.Fragment>
      <div className={classes.navbar}>
        <div className="welcome">
          <h1>
          <FormattedMessage id="hi"></FormattedMessage>&nbsp;
            <Link to="/profile">
            {!currentAccount.fullName || currentAccount.username == "Guest" 
  ? <FormattedMessage id="guest"></FormattedMessage> 
  : (!currentAccount.fullName 
      ? currentAccount.username.substring(0, 15) + "..."
      : currentAccount.fullName)
}
            </Link>
          </h1>
          <span className="flex align-items-center">
           <FormattedMessage id="address"></FormattedMessage>:{" "}
            {currentAccount.ethAddress &&
              `${currentAccount.ethAddress.slice(
                0,
                8
              )} ... ${currentAccount.ethAddress.slice(34)}`}
            <span onClick={copyEth}>
              <Icon
                path={mdiContentCopy}
                style={{ width: "15px" }}
                className="cursor-pointer ml-2"
              ></Icon>
            </span>{" "}
            <span className={`${!copyToClipboard && "hidden"} text-white`}>
              copied!
            </span>
          </span>
        </div>
      </div>
      {displayLoginDialog && (
        <UserLogin
          displayLoginDialog={displayLoginDialog}
          onHide={closeLoginDialog}
        />
      )}
    </React.Fragment>
  );
};

export default NavbarTop;
