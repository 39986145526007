import { useEffect, useState } from "react";
import { useMoralis } from "react-moralis";
import { polygonConfig } from "../../../dappConfig";
import { differenceInSeconds } from "date-fns";
import BigNumber from "bignumber.js";

export function useTotalCompensation() {
 const Moralis = require("moralis-v1");
  const { user,isAuthenticated } = useMoralis();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] =  useState('');
  // To keep track on the tokens object if it stills empty
  const [fetched, setFetched] = useState(false);
  const fetch = async (): Promise<void> => {
    console.log("!--Started Fetching total compensation from the server--!");
    setLoading(true);
    try {
      let sum = new BigNumber(0);
      const cco2 = Moralis.Object.extend("PolygonTokenTransfers");
      const burnedQuery = new Moralis.Query(cco2);
      burnedQuery.equalTo("from_address", user?.attributes.ethAddress);
      burnedQuery.equalTo("to_address", "0x0000000000000000000000000000000000000000");
      burnedQuery.equalTo("token_address", polygonConfig.CO2_TOKEN_ADDRESS);
      const transfers = await burnedQuery.find();
      if (transfers.length) {
        transfers.forEach((transfer:any) => {
          sum = sum.plus(transfer.get("value"));
        });
      }
      setData(sum.div(new BigNumber(10).exponentiatedBy(18)).toFixed(3));
      localStorage.setItem("totalCompensation", JSON.stringify({ data: sum.div(new BigNumber(10).exponentiatedBy(18)).toFixed(3), date: Date.now() }));
    } catch (err) {
      console.log(err);
      setError(true);
    } finally {
      setFetched(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!user || !isAuthenticated) return;
    const cachedData = localStorage.getItem("totalCompensation");
    if (cachedData) {
      const { data, date } = JSON.parse(cachedData);
      const secondsElapsed = differenceInSeconds(Date.now(), date);
      if (secondsElapsed <= 240) {
        setData(data);
        setFetched(true);
        return;
      }
    }
    fetch();
  }, [user,isAuthenticated]);

  return { fetch, data, loading, error, fetched };
}
