import { Routes, Route } from "react-router-dom";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faHome,
  faArrowLeft,
  faUpload,
  faUser,
  faLock,
  faInfoCircle,
  faHistory,
  faTree,
  faCertificate,
  faHandHoldingUsd,
  faChartLine,
  faShoppingBasket,
  faMoneyBillWave,
  faSyncAlt,
} from "@fortawesome/free-solid-svg-icons";
import DashboardLayout from "./layouts/DashboardLayout";
import Dashboard from "./pages/Dashboard";
import Profile from "./pages/Profile";
import Batches from "./pages/batches";
import BatchDetails from "./components/nftrees/BatchDetails";
import { Transactions } from "./pages/Transactions";
import POCC from "./pages/POCC";
// import ViewPOCC from "./features/pocc/views/ViewPOCC";
import Dao from "./pages/DaoPage";
import useLocalStorage from "use-local-storage";
import Insurances from "./pages/Insurances";
import Co2MarketPlace from "./pages/Co2MarketPlace";
import CO2Calculator from "./pages/CO2Calculator";
import { useEffect, useState } from "react";
import { I18nProvider } from "./i18n";
import Exchange from "./pages/Exchange";
import BridgePage from "./pages/Bridge";

library.add(
  faHome,
  faArrowLeft,
  faUpload,
  faUser,
  faLock,
  faInfoCircle,
  faHistory,
  faTree,
  faCertificate,
  faHandHoldingUsd,
  faChartLine,
  faShoppingBasket,
  faMoneyBillWave,
  faSyncAlt
);

function App() {
  const [locale, setLocale] = useState<string>(
    localStorage.getItem('locale') || 'en'
  );
  const onAppLocaleChange = (newAppLocale:string) => {
    setLocale(newAppLocale);
    localStorage.setItem('locale', newAppLocale);
  }
  const [theme, setTheme] = useLocalStorage("theme", "light");
  useEffect(()=>{
    
  },[])
  return (
    <I18nProvider locale={locale}>
    <div className="App flex flex-column" data-theme={theme}>
      <Routes>
        <Route path="/" element={<DashboardLayout onAppLocaleChange={onAppLocaleChange}/>}>
          <Route
            index
            element={
              <Dashboard onThemeChange={(newTheme) => setTheme(newTheme)} />
            }
          />
          {/* <Route path="exchange" element={<Exchange onThemeChange={(newTheme) => setTheme(newTheme)} />} /> */}
          <Route path="profile" element={<Profile onThemeChange={(newTheme) => setTheme(newTheme)} />} />
          <Route path="certificates" element={<POCC />} />
          {/* <Route path="insurances" element={<Insurances onThemeChange={(newTheme) => setTheme(newTheme)}/>} /> */}
          <Route path="Dao" element={<Dao onThemeChange={(newTheme) => setTheme(newTheme)} />} />
          <Route path="Bridge" element={<BridgePage onThemeChange={(newTheme) => setTheme(newTheme)} />} />
          {/* <Route path="Charts" element={<Co2MarketPlace onThemeChange={(newTheme) => setTheme(newTheme)} />} /> */}
          <Route path="CO2Calculator" element={<CO2Calculator  onThemeChange={(newTheme) => setTheme(newTheme)} />} />
          
          <Route path="batches" element={<Batches />} />
          <Route path="batches/:batchId" element={<BatchDetails />} />
          <Route path="transactions" element={<Transactions onThemeChange={(newTheme) => setTheme(newTheme)} />} />
        </Route>
        {/* <Route path="view-certificate" element={<ViewPOCC />}></Route> */}

        {/* <Route path="/projects" element={<ProjectsLayout />}>
          <Route index element={<Rooted />} />
          <Route path="coorest" element={<Coorest />} />
        </Route> */}
      </Routes>
    </div>
    </I18nProvider>
  );
}

export default App;


