import { useEffect, useState } from "react";
import { useMoralis, useMoralisWeb3Api } from "react-moralis";
import { useAppSelector } from "../../../store/hooks";
import { RootState } from "../../../store/store";
import { polygonConfig } from "../../../dappConfig";
import {
  CachedData,
  TransactionsResponse,
  TransferMeta,
  useTransferResponse,
} from "./types";
import useLocalStorage from "use-local-storage";
import differenceInSeconds from "date-fns/differenceInSeconds";

export function useCo2Transfer(): useTransferResponse {
  const Web3Api = useMoralisWeb3Api();

  const currentUser = useAppSelector(
    (state: RootState) => state.users.currentUser
  );

  const { user,isAuthenticated } = useMoralis();

  const [data, setData] = useState<TransactionsResponse[]>([]);
  const [meta, setMeta] = useState<TransferMeta>({} as TransferMeta);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [co2Transfers, setCo2Transfers] = useLocalStorage(
    "cco2Transfers",
    {} as CachedData
  );

  /** Retrieving the CCO2 transfers */
  const fetch = async (): Promise<void> => {
    console.log("!--Started Fetching co2 transfers from the server--!");
    if (
      //If transfer data exists in locale storage and it's been saved not later than 2 minutes ago
      co2Transfers &&
      differenceInSeconds(Date.now(), co2Transfers.date) < 120
    ) {
      setData(co2Transfers.data);
      setMeta(co2Transfers.meta);
    } else {
      const options = {
        chain: polygonConfig.chainName,
        address: currentUser.ethAddress,
      };
      try {
        setLoading(true);
        let Co2Transfers: TransactionsResponse[] = [];

        let transactionResult = await Web3Api.account.getTokenTransfers(
          options as any
        );

        transactionResult &&
          transactionResult.result !== undefined &&
          (Co2Transfers = transactionResult.result
            .filter((tx) => tx.address === polygonConfig.CO2_TOKEN_ADDRESS)
            .map((transaction: any) => {
              return {
                from: transaction.from_address,
                to: transaction.to_address,
                transactionHash: transaction.transaction_hash,
                date: transaction.block_timestamp,
                tokenAddress: transaction.address,
                value: transaction.value,
              };
            }));

        const pageDetails: TransferMeta = {
          page: transactionResult.page ?? 0,
          pageSize: transactionResult.page_size,
          total:
            transactionResult.total ?? transactionResult.result?.length ?? 0,
        };
        console.log("Transaction result co2 : ",transactionResult.result);
        /** This part should be used for pagination */
        // while ((transactionResult as any).next) {
        //   transactionResult = await (transactionResult as any).next();
        //   transactionResult.result &&
        //     (Co2Transfers = [
        //       ...Co2Transfers,
        //       ...transactionResult.result
        //         .filter((tx) => tx.address === polygonConfig.CO2_TOKEN_ADDRESS)
        //         .map((transaction: any) => {
        //           return {
        //             from: transaction.from_address,
        //             to: transaction.to_address,
        //             transactionHash: transaction.transaction_hash,
        //             date: transaction.block_timestamp,
        //             tokenAddress: transaction.address,
        //             value: transaction.value,
        //           };
        //         }),
        //     ]);
        // }

        setData(Co2Transfers);
        setCo2Transfers({
          data: Co2Transfers,
          meta: pageDetails,
          date: Date.now(),
        });
      } catch (err) {
        console.log(err);
        setError(true);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (!user || !isAuthenticated) return;
    console.log("fetch co2 transactions");
    fetch();
  }, [user,isAuthenticated]);

  return { fetch, data, meta, error, loading };
}
