import { useEffect, useState } from "react";
import { useMoralis, useMoralisWeb3Api } from "react-moralis";
import { useAppSelector } from "../../../store/hooks";
import { RootState } from "../../../store/store";
import { polygonConfig } from "../../../dappConfig";
import {
  CachedData,
  TransactionsResponse,
  TransferMeta,
  useTransferResponse,
} from "./types";
import useLocalStorage from "use-local-storage";
import differenceInSeconds from "date-fns/differenceInSeconds";

export function useTransfer(): useTransferResponse {
  const { user,isAuthenticated } = useMoralis();
  const Web3Api = useMoralisWeb3Api();

  const currentUser = useAppSelector(
    (state: RootState) => state.users.currentUser
  );

  const [data, setData] = useState<TransactionsResponse[]>([]);
  const [meta, setMeta] = useState<TransferMeta>({} as TransferMeta);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [nftreeTransfers, setNftreeTransfers] = useLocalStorage(
    "nftreeTransfers",
    {} as CachedData
  );

  /** Retrieving the NFTree transfers */
  const fetch = async (): Promise<void> => {
    console.log("!--Started Fetching nftrees_transfers from the server--!");
    if (
      //If transfer data exists in locale storage and it's been saved not later than 2 minutes ago
      nftreeTransfers &&
      differenceInSeconds(Date.now(), nftreeTransfers.date) < 120
    ) {
      setData(nftreeTransfers.data);
      setMeta(nftreeTransfers.meta);
    } else {
      const options = {
        // tokenAddresses: [polygonConfig.TREE_TOKEN_ADDRESS],
        chain: polygonConfig.chainName,
        address: currentUser.ethAddress,
      };
      try {
        setLoading(true);
        const transferResults = await Web3Api.account.getNFTTransfers(
          options as any
        );
        const pageDetails = {
          total: transferResults.total,
          pageSize: transferResults.page_size,
          page: transferResults.page,
        };
        setMeta(pageDetails);
        const transfers = transferResults.result
          .filter(
            (transfer) =>
              transfer.token_address === polygonConfig.TREE_TOKEN_ADDRESS
          )
          .map((transaction) => {
            return {
              from: transaction.from_address,
              to: transaction.to_address,
              tokenId: transaction.token_id,
              transactionHash: transaction.transaction_hash,
              date: transaction.block_timestamp,
              tokenAddress: transaction.token_address,
            };
          });
        setData(transfers);
        setNftreeTransfers({
          data: transfers,
          meta: pageDetails,
          date: Date.now(),
        });
      } catch (err) {
        console.log(err);
        setError(true);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (!user || !isAuthenticated) return;
    fetch();
  }, []);

  return { fetch, data, meta, error, loading };
}
