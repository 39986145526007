import React from "react";
import { Spinner } from "react-bootstrap";
import classes from "./DappLoading.module.scss";

const DappLoading = () => {
  return (
    <React.Fragment>
      <div className={classes.overlay}>
        <Spinner animation="border" className={classes.spinner} />
        <h2>Please wait ...</h2>
      </div>
    </React.Fragment>
  );
};

export default DappLoading;
