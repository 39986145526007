import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Message } from "primereact/message";
import React from "react";
import { useIntl } from "react-intl";
import { polygonConfig } from "../../dappConfig";
import { User } from "../../store/custom-hooks/useUser/usersTypes";
import { format } from "date-fns";
import classes from "../../styles/Transactions.module.scss";
import BigNumber from "bignumber.js";
import { Button } from "primereact/button";
import { mdiPound } from "@mdi/js";
import Icon from "@mdi/react";
import { useCo2Transfer } from "../../store/custom-hooks/useCco2/useCo2Transfer";
import DappLoading from "../../ui/DappLoading";
interface CCO2TransactionsProps {
  currentUser: User;
}

export const CCO2Transactions = ({ currentUser }: CCO2TransactionsProps) => {
  const intl = useIntl();
  const receivedMessage = intl.formatMessage({id: 'received'});
  const coorestFeeMessage = intl.formatMessage({id: 'coorest_fee'});
  const burntMessage = intl.formatMessage({id: 'burnt'});
  const claimedMessage = intl.formatMessage({id: 'claimed'});
  const { data: cco2Transfers, meta, error, loading } = useCo2Transfer();

  const compare = (a: any, b: any) => {
    if (a.date < b.date) {
      return 1;
    }
    if (a.date > b.date) {
      return -1;
    }
    return 0;
  };

  const transferStatus = (rowData: any) => {
    if (rowData.from === "0x0000000000000000000000000000000000000000")
      return claimedMessage;
    else if (rowData.to === "0x0000000000000000000000000000000000000000")
      return burntMessage;
    else if (rowData.to === "0xe655afc0004191a72457f791aacab416a4249eac")
      return coorestFeeMessage;
    else if (rowData.from === currentUser.ethAddress) return "Sent";
    else return receivedMessage;
  };

  const convertDate = (rowData: any) => {
    return format(new Date(rowData.date), "dd MMMM Y");
  };

  const convertValue = (rowData: any) => {
    const value = new BigNumber(rowData.value);
    const convertedValue = value.div(new BigNumber(10).exponentiatedBy(18));
    return convertedValue.toFixed(3);
  };

  const generateTxLink = (rowData: any) => {
    return (
      <a
        href={polygonConfig.Txhash + rowData.transactionHash}
        target="_blank"
        rel="noreferrer"
      >
        <Button
          icon={<Icon path={mdiPound} style={{ width: "20px" }} />}
          className="p-button-rounded p-button-text"
        />
      </a>
    );
  };

  return (
    <React.Fragment>
      {loading && <DappLoading />}
      {error ? (
        <Message
          severity="error"
          text="Something Went wrong. Please try again later"
        ></Message>
      ) : (
        <DataTable
        style={{ width: "100%", zIndex: "0"}}
        scrollHeight="flex"
          value={cco2Transfers.sort(compare)}
          responsiveLayout="scroll"
          scrollable
          className={classes.datatable}
          size="small"
          loading={loading}
          paginator
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={10} rowsPerPageOptions={[10,20,50] } pageLinkSize={3}
        >
          <Column header="Status" body={transferStatus}></Column>
          <Column header="Date" body={convertDate}></Column>
          <Column header="Value" body={convertValue}></Column>
          <Column header="Tx" body={generateTxLink}></Column>
        </DataTable>
      )}
    </React.Fragment>
  );
};
