import React, {  useRef, useState } from "react";
import classes from "../../styles/Dashboard.module.scss";
import { useAppSelector } from "../../store/hooks";
import { 
    mdiAppleIcloud,
    mdiChevronDown, 
    mdiAccountCircleOutline,
    mdiCogOutline, 
    mdiBullhornVariantOutline, 
    mdiHelpCircleOutline, 
    mdiTreeOutline, 
    mdiChartArc,
    mdiChartLine
} from "@mdi/js";
import Icon from "@mdi/react";
import UserLogin from "../users/UserLogin";
import { FormattedMessage } from "react-intl";
import { RootState } from "../../store/store";
import useLocalStorage from "use-local-storage";
import bellPrimary from "../../assets/images/icon/bell-primary.png";
import bellSecondary from "../../assets/images/icon/bell-secondary.png";
import { Link, useNavigate } from "react-router-dom";
import coorestIcon from "../../assets/images/tokens/token.png";
import walletPrimary from "../../assets/images/icon/wallet-primary.png";
import walletSecondary from "../../assets/images/icon/wallet-secondary.png";
import { TieredMenu } from "primereact/tieredmenu";
import { useTranslations } from "../../i18n/useTranslations"


const ProfileBar = ({ onThemeChange }: any) => {

    const navigate = useNavigate();

    const TRANSLATIONS = useTranslations();

    const currentUser = useAppSelector(
        (state: RootState) => state.users.currentUser
    );
    const [displayLoginDialog, setDisplayLoginDialog] = useState(false);
    const [theme, setTheme] = useLocalStorage("theme", "light");

    const menu: any = useRef(null);
    const items = [
        {
            label: TRANSLATIONS.my_profile,
            icon: <Icon path={mdiAccountCircleOutline} style={{ width: "20px", marginRight: "10px" }} />,
            command: (event: any) => {
                navigate("/profile");
                menu.current.toggle(event);
            },
        },
        {
            label: TRANSLATIONS.settings,
            icon: <Icon path={mdiCogOutline} style={{ width: "20px", marginRight: "10px" }} />,

            disabled: true,
        },
        {
            label: TRANSLATIONS.reports,
            icon: <Icon path={mdiBullhornVariantOutline} style={{ width: "20px", marginRight: "10px" }} />,
            command: (event: any) => {
                window.open("https://discord.gg/GeDbpHGAau");
            },
        },

        {
            label: TRANSLATIONS.help,
            icon: <Icon path={mdiHelpCircleOutline} style={{ width: "20px", marginRight: "10px" }} />,

            command: (event: any) => {
                window.open("https://coorest.gitbook.io/coorest-dapp-manual/", "_blank");
                menu.current.toggle(event);
            },
        },
        {
            label: TRANSLATIONS.ccsRegisrty,
            icon: <Icon path={mdiTreeOutline} style={{ width: "20px", marginRight: "10px" }} />,

            command: (event: any) => {
                window.open("https://coorest.gitbook.io/coorest-dapp-manual/", "_blank");
                menu.current.toggle(event);
            },
        },
        {
            label: TRANSLATIONS.charts,
            icon: <Icon path={mdiChartLine} style={{ width: "20px", marginRight: "10px" }} />,

            command: (event: any) => {
                window.open("https://ember-climate.org/data/data-tools/carbon-price-viewer/", "_blank");
                menu.current.toggle(event);
            },
        },
    ];

    const closeLoginDialog = () => {
        setDisplayLoginDialog(false);
    };

    /** Change the theme  */
    //Check the user's browser preference
    const changeTheme = () => setTheme(theme === "dark" ? "light" : "dark");
    onThemeChange && onThemeChange(theme === "dark" ? "dark" : "light");

    return (
        <>
            <div className={classes.menu}>
                <div className={classes.left_menu}>
                    {/* Notification */}
                    {!(currentUser.username === "Guest") && (
                        <div style={{ paddingTop: "10px" }} className="relative" onClick={() => alert("coming soon!")}>
                            <img
                                src={theme === "dark" ? bellSecondary : bellPrimary}
                                alt=""
                                width="30px"
                                height="30px"
                                className={`${classes.logo_transition} mr-2`}
                            />
                        </div>
                    )}
                    {/* Theme change */}

                    <div
                        onClick={changeTheme}
                        className={`${classes.theme_box} ${theme === "dark" ? classes.dark : classes.light}`}
                    >
                        <div className={classes.stars}>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                        <div className={classes.sun}>
                            <Icon path={mdiAppleIcloud} />
                        </div>
                        <div className={classes.moon}></div>
                    </div>
                </div>

                {/* profile */}

                <div className={`${classes.profile} flex align-items-center justify-content-start`}>
                    <Link to={currentUser.username === "Guest" ? "/" : "/profile"}>
                        <img
                            src={currentUser.profilePicture ? currentUser.profilePicture.toString() : coorestIcon}
                            alt={currentUser.fullName}
                            width="45"
                            height="45"
                            className="mr-2"
                            style={{ borderRadius: "50%" }}
                        />
                    </Link>

                    <div className={`${classes.right_menu} pr-6`}>
                        <span>
                            <b>
                                {/* if user is equal to guest show connect with wallet message */}
                                {currentUser.username === "Guest" ? (
                                    <div className="flex">
                                        <span className="cursor-pointer" onClick={() => setDisplayLoginDialog(true)}>
                                            <FormattedMessage id="connect_wallet" />
                                        </span>
                                        <img
                                            src={theme === "dark" ? walletSecondary : walletPrimary}
                                            alt=""
                                            width="30px"
                                            height="30px"
                                            className={`${classes.logo_transition} ml-0 mr-2`}
                                        />
                                        <div></div>
                                    </div>
                                ) : currentUser.fullName && currentUser.fullName.length > 10 ? (
                                    currentUser.fullName.substring(0, 10) + "..."
                                ) : (
                                    currentUser.fullName
                                )}
                            </b>
                        </span>
                        {!(currentUser.username === "Guest") && (
                            <div className="cursor-pointer flex align-items-center" onClick={(event) => menu.current.toggle(event)}>
                                <Icon path={mdiChevronDown} size={1.2}></Icon>
                            </div>
                        )}
                        <TieredMenu model={items} popup ref={menu} id="overlay_tmenu" />
                    </div>
                </div>
            </div>
            {displayLoginDialog && <UserLogin displayLoginDialog={displayLoginDialog} onHide={closeLoginDialog} />}


        </>
    );
};

export default ProfileBar;