import "primereact/resources/themes/saga-green/theme.css" //theme
import "primereact/resources/primereact.min.css"; //core css
import "primeicons/primeicons.css";
import React from "react";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import "./index.scss";
import { MoralisProvider } from "react-moralis";
import { appId, serverUrl } from "./dappConfig";
import { Provider } from "react-redux";
import { store } from "./store/store";
import { createRoot } from "react-dom/client";

const Moralis = require("moralis-v1");
Moralis.start({ serverUrl, appId });

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <React.StrictMode>
      <Provider store={store}>
        <MoralisProvider appId={appId} serverUrl={serverUrl}>
    <BrowserRouter>
          <App />
    </BrowserRouter>
        </MoralisProvider>
      </Provider>

  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
