export const polygonConfig = {
  NETWORK_NAME: process.env.REACT_APP_NETWORK_NAME ?? "",

  STAKED_TOKEN_ADDRESS:
    process.env.REACT_APP_STAKED_ADRESS?.toLowerCase() ?? "",

  CO2_TOKEN_ADDRESS:
    process.env.REACT_APP_CO2_TOKEN_ADDRESS?.toLowerCase() ?? "",

  CRST_TOKEN_ADDRESS: 
    process.env.REACT_APP_CRST_TOKEN_ADDRESS?.toLowerCase() ?? "",

  TREE_TOKEN_ADDRESS:
    process.env.REACT_APP_TREE_TOKEN_ADDRESS?.toLowerCase() ?? "",

  POCC_TOKEN_ADDRESS:
    process.env.REACT_APP_POCC_TOKEN_ADDRESS?.toLowerCase() ?? "",

  CMP_TOKEN_ADDRESS:
    process.env.REACT_APP_CMP_TOKEN_ADDRESS?.toLowerCase() ?? "",

  USDC_TOKEN_ADDRESS:
    process.env.REACT_APP_USDC_TOKEN_ADDRESS?.toLowerCase() ?? "",

  appId: process.env.REACT_APP_APP_ID ?? "",

  serverUrl: process.env.REACT_APP_SERVER_URL ?? "",

  chainId: process.env.REACT_APP_CHAIN_ID ?? "",

  chainName: process.env.REACT_APP_CHAIN_NAME ?? "",

  TOKEN_TRANSFER_COLLECTION:
    process.env.REACT_APP_TOKEN_TRANSFER_COLLECTION ?? "",

  NFT_TRANSFER_COLLECTION: process.env.REACT_APP_NFT_TRANSFER_COLLECTION ?? "",

  Txhash: process.env.REACT_APP_TXHASH ?? "",

  MERKLE_TREE_API: process.env.REACT_APP_MERKLETREE_API ?? "",
  TOTAL_CO2_API: process.env.REACT_APP_TOTAL_CO2_API ?? "",
  TRY_CARBON_API: process.env.REACT_APP_TRY_CARBON_API ?? "",
  TRY_CARBON_TOKEN: process.env.REACT_APP_TRY_CARBON_TOKEN ?? "",
};

export const appId = polygonConfig.appId;
export const serverUrl = polygonConfig.serverUrl;
