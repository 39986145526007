import { configureStore } from "@reduxjs/toolkit";
import usersReducer from "./custom-hooks/useUser/usersSlice";
// import treeReducer from "../features/nftrees/treeSlice";
import localeReducer from "../i18n/localeSlice";

export const store = configureStore({
  reducer: {
    users: usersReducer,
    // trees: treeReducer,
    locale: localeReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {users: UsersState}
export type AppDispatch = typeof store.dispatch;
