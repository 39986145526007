
import { useIntl } from "react-intl";

export const useTranslations  = () => {
  const intl = useIntl();
  
  const TRANSLATIONS = {
    error : intl.formatMessage({ id: "error" }),
    network_error: intl.formatMessage({ id: "network_error" }),
    pocc_error : intl.formatMessage({ id: "pocc_error" }),
    unable_to_burn : intl.formatMessage({ id: "unable_to_burn" }),
    pocc_success : intl.formatMessage({ id: "pocc_success" }),
    success : intl.formatMessage({ id: "success" }),
    add_to_compensation_field : intl.formatMessage({ id: "add_to_compensation_field" }),
    burn_cco2_to_generate : intl.formatMessage({ id: "burn_cco2_to_generate" }),
    co2_amount : intl.formatMessage({ id: "co2_amount" }),
    flight : intl.formatMessage({ id: "flight" }),
    carTravel : intl.formatMessage({ id: "carTravel" }),
    fuelToCo2 : intl.formatMessage({ id: "fuelToCo2" }),
    longFirstClassFlight : intl.formatMessage({ id: "longFirstClassFlight" }),
    longBusinessClassFlight : intl.formatMessage({ id: "longBusinessClassFlight" }),
    longEconomyClassFlight : intl.formatMessage({ id: "longEconomyClassFlight" }),
    shortBusinessClassFlight : intl.formatMessage({ id: "shortBusinessClassFlight" }),
    shortEconomyClassFlight : intl.formatMessage({ id: "shortEconomyClassFlight" }),
    smallDieselCar : intl.formatMessage({ id: "smallDieselCar" }),
    mediumDieselCar : intl.formatMessage({ id: "mediumDieselCar" }),
    largeDieselCar : intl.formatMessage({ id: "largeDieselCar" }),
    mediumHybridCar : intl.formatMessage({ id: "mediumHybridCar" }),
    largeHybridCar : intl.formatMessage({ id: "largeHybridCar" }),
    smallPetrolVan : intl.formatMessage({ id: "smallPetrolVan" }),
    largePetrolVan : intl.formatMessage({ id: "largePetrolVan" }),
    petrol : intl.formatMessage({ id: "petrol" }),
    diesel : intl.formatMessage({ id: "diesel" }),
    lpg : intl.formatMessage({ id: "lpg" }),
    selectOption : intl.formatMessage({ id: "selectOption" }),
    theDistanceInKM : intl.formatMessage({ id: "theDistanceInKM" }),
    selectFlightClass : intl.formatMessage({ id: "selectFlightClass" }),
    selectCarType : intl.formatMessage({ id: "selectCarType" }),
    selectFuelType : intl.formatMessage({ id: "selectFuelType" }),
    numberOfLiters : intl.formatMessage({ id: "numberOfLiters" }),
    fees_from_claimed_amount : intl.formatMessage({ id: "fees_from_claimed_amount" }),
    nftrees_refresh_button : intl.formatMessage({ id: "nftrees_refresh_button" }),
    select_nftrees : intl.formatMessage({ id: "select_nftrees" }),
    transfer_your_nftrees : intl.formatMessage({ id: "transfer_your_nftrees" }),
    sign_transaction_wallet_connect : intl.formatMessage({ id: "sign_transaction_wallet_connect" }),
    sign_the_transaction : intl.formatMessage({ id: "sign_the_transaction" }),
    info : intl.formatMessage({ id: "info" }),
    something_went_wrong : intl.formatMessage({ id: "something_went_wrong" }),
    transaction_wasnt_successful : intl.formatMessage({ id: "transaction_wasnt_successful" }),
    transfer_completed : intl.formatMessage({ id: "transfer_completed" }),
    fire : intl.formatMessage({ id: "fire" }),
    flood : intl.formatMessage({ id: "flood" }),
    pest : intl.formatMessage({ id: "pest" }),
    project1 : intl.formatMessage({ id: "project1" }),
    project2 : intl.formatMessage({ id: "project2" }),
    project3 : intl.formatMessage({ id: "project3" }),
    start_date : intl.formatMessage({ id: "start_date" }),
    end_date : intl.formatMessage({ id: "end_date" }),
    choose_project_id : intl.formatMessage({ id: "choose_project_id" }),
    select_risks : intl.formatMessage({ id: "select_risks" }),
    batch : intl.formatMessage({ id: "batch" }),
    fig : intl.formatMessage({ id: "fig" }),
    twenty_years_ago : intl.formatMessage({ id: "twenty_years_ago" }),
    spain : intl.formatMessage({ id: "spain" }),
    caspe_spain : intl.formatMessage({ id: "caspe_spain" }),
    mixed_forest : intl.formatMessage({ id: "mixed_forest" }),
    to_be_determined : intl.formatMessage({ id: "to_be_determined" }),
    
    name : intl.formatMessage({ id: "name" }),
    amount : intl.formatMessage({ id: "amount" }),
    date : intl.formatMessage({ id: "date" }),
    no_specific_reason : intl.formatMessage({ id: "no_specific_reason" }),
    reason_for_compensating : intl.formatMessage({ id: "reason_for_compensating" }),
    actions : intl.formatMessage({ id: "actions" }),
    email_has_been_sent : intl.formatMessage({ id: "email_has_been_sent" }),
    please_check_your_inbox : intl.formatMessage({ id: "please_check_your_inbox" }),
    settings : intl.formatMessage({ id: "settings" }),
    reports : intl.formatMessage({ id: "reports" }),
    help : intl.formatMessage({ id: "help" }),
    ccsRegisrty : intl.formatMessage({ id: "ccsRegisrty" }),
    my_profile : intl.formatMessage({ id: "my_profile" }),
    co2Calculator : intl.formatMessage({ id: "co2Calculator" }),
    coorest_forum : intl.formatMessage({ id: "coorest_forum" }),
    exchange : intl.formatMessage({ id: "exchange" }),
    insurance_history : intl.formatMessage({ id: "insurance_history" }),
    insurance : intl.formatMessage({ id: "insurance" }),
    buy_insurance : intl.formatMessage({ id: "buy_insurance" }),
    invest_in_risk_pool : intl.formatMessage({ id: "invest_in_risk_pool" }),
    pocc_certificates : intl.formatMessage({ id: "pocc_certificates" }),
    profile_updated : intl.formatMessage({ id: "profile_updated" }),
    check_your_file : intl.formatMessage({ id: "check_your_file" }),
    select_language : intl.formatMessage({ id: "select_language" }),
    bridge: intl.formatMessage({ id: "bridge" }),
    quickly_bridge_tokens: intl.formatMessage({ id: "quickly_bridge_tokens" }),
    bridge_eth_polygon: intl.formatMessage({ id: "bridge_eth_polygon" }),
    bridge_eth_q: intl.formatMessage({ id: "bridge_eth_q" }),
    bridge_eth_arbitrium: intl.formatMessage({ id: "bridge_eth_arbitrium" }),
    charts: intl.formatMessage({ id: "charts" }),
  };
  
  return TRANSLATIONS;
};