import React, { useEffect, useRef, useState } from "react";
import classes from "../../styles/Dashboard.module.scss";
import nftreeIcon from "../../assets/images/tokens/tree.png";
import warningIcon from "../../assets/images/warning-sign.jpg";
import Icon from "@mdi/react";
import { mdiLoading, mdiTreeOutline, mdiRefresh } from "@mdi/js";
import { User } from "../../store/custom-hooks/useUser/usersTypes";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { polygonConfig } from "../../dappConfig";
import UserLogin from "../users/UserLogin";
import { Toast } from "primereact/toast";
import { useMoralis, useMoralisWeb3Api } from "react-moralis";
import { Tooltip } from "primereact/tooltip";
import { useContracts } from "../../store/custom-hooks/useContract/useContract";
import { chunk } from "../../utils/utils-methods";
import { FormattedMessage } from "react-intl";
import { useFetchNftrees } from "../../store/custom-hooks/useNFTree/useFetchNftrees";
import { useTranslations  } from "../../i18n/useTranslations";
import { constants } from "../../utils/constants";

interface NFTreesProps {
  currentUser: User;
}
const NFTrees = ({currentUser} : NFTreesProps) => {
  const TRANSLATIONS = useTranslations ();
  const { user, setUserData, isAuthenticated, chainId } = useMoralis();
  const [batchTransferDialog, setBatchTransferDialog] = useState<boolean>(false);
  const [receiver, setReceiver] = useState<string | undefined>(undefined);
  const [selectedTrees, setSelectedTrees] = useState<number[] | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [displayLoginDialog, setDisplayLoginDialog] = useState(false);
  // const [nftrees, setNftrees] = useState(currentUser.NFTrees);
  const { refetch : refetchNftrees,loading: nftreeLoading,setLoading:setNftreeLoading } = useFetchNftrees();
  const currentMoralisUser = user;
  //const [nftreeLoading, setNftreeLoading] = useState<boolean>(false);
  const toast = useRef(null);
  const {  getNFTreeContract } = useContracts();


  const transferNFTree = () => {

    currentMoralisUser ? setBatchTransferDialog(true): setDisplayLoginDialog(true);
  };

  const closeLoginDialog = () => {
    setDisplayLoginDialog(false);
  };

  const onReceiverChange = (e: any) => {
    setReceiver(e.target.value);
  };

  const renderBatchTransferFooter = () => {
    return (
      <div className="flex justify-content-end">
        <button onClick={closeBatchTransferDialog} className={classes.secondary_btn}>
          <FormattedMessage id="cancel" />
        </button>
        <button onClick={batchTransfer} className={classes.dapp_btn}>
          <FormattedMessage id="transfer" />
        </button>
      </div>
    );
  };

  const closeBatchTransferDialog = () => {
    setBatchTransferDialog(false);
    setReceiver(undefined);
    setSelectedTrees(null);
  };

  useEffect(() => {
    if (loading && localStorage.getItem("walletType") === "walletConnect") {
      toast.current &&
        (toast.current as any).show({
          severity: "info",
          summary: TRANSLATIONS.sign_the_transaction,
          detail: TRANSLATIONS.sign_transaction_wallet_connect,
          life: 6000,
        });
    }
  }, [loading]);

  const batchTransfer = async () => {
    if (chainId != polygonConfig.chainId) {
      toast.current &&
        (toast.current as any).show({
          severity: "error",
          summary: TRANSLATIONS.network_error,
          detail: `Please switch to ${polygonConfig.NETWORK_NAME}`,
          life: 3000,
        });
      return;
    }
    if (selectedTrees) {
      const batches = chunk(selectedTrees!, constants.chunkSize); 
      const amounts: number[] = [];
      for (let i = 0; i < selectedTrees?.length; i++) {
        amounts.push(1);
      }

      const nftree = await getNFTreeContract();
      try {
        setNftreeLoading(true);
        setBatchTransferDialog(false);

        const transactions = [];
        for (const batch of batches) {
          const transaction = await nftree.safeBatchTransferFrom(
            currentUser.ethAddress,
            receiver,
            batch,
            new Array(batch.length).fill(1),
            0x65
          );
          transactions.push(transaction);
        }

        try {
          setNftreeLoading(true);
          // for (const transaction of transactions) {
          //   await transaction.wait();
          // }
          for (let i = 0; i < batches.length; i++) {
            i == batches.length - 1 ? await transactions[i].wait() : transactions[i].wait();
          }
          toast.current &&
            (toast.current as any).show({
              severity: "success",
              summary: TRANSLATIONS.success,
              detail: TRANSLATIONS.transfer_completed,
              life: 3000,
            });
            refetchNftrees();
          // await refetchNftrees();
        } catch (err: any) {
          console.log(err);
          toast.current &&
            (toast.current as any).show({
              severity: "error",
              summary: err,
              detail: TRANSLATIONS.transaction_wasnt_successful,
              life: 3000,
            });
        } finally {
          setNftreeLoading(false);
          closeBatchTransferDialog();
        }
      } catch (err) {
        console.log(err);
        toast.current &&
          (toast.current as any).show({
            severity: "error",
            summary: TRANSLATIONS.error,
            detail: TRANSLATIONS.something_went_wrong,
            life: 3000,
          });
      } finally {
        setNftreeLoading(false);
        closeBatchTransferDialog();
      }
    }
  };

  const nftreeTemplate = (option: any) => {
    let date = new Date(option.lastClaimDate).toLocaleDateString('en-GB');
    let isClaimed =  date !== '01/01/1970';
   // let date = format(option.lastClaimDate, "dd/MM/yyyy");
   // console.log("date : ",typeof date);
    return (
      // <div className=" container w-full">

      <div className=" flex flex-row md:justify-content-between w-full ">
        <div className="col-5 col-sm-3  ">#{option.token_id}</div>
        
        {isClaimed ? (
        <div className="col-7 col-sm-9 text-right"> last claimed: {date}</div>
      ) : (
        <div className="col-7 col-sm-9 text-right"> never claimed</div>
      )}
      </div>
      // </div>
    );
  };

  const panelFooterTemplate = () => {
    const length = selectedTrees ? selectedTrees.length : 0;

    return (
      <div className="py-2 px-3">
        <b>{length}</b> item{length > 1 ? "s" : ""} selected.
      </div>
    );
  };



  return (
    <React.Fragment>
      <div className={`${classes.base_card} ${classes.card} flex-column justify-content-between min-h-0`}>
        {/* title */}
        <div className={`${classes.nftree_title} flex align-items-center justify-content-between w-full `}>
          <div className="flex align-items-center">
            <img src={nftreeIcon} alt="NFTrees" className="mr-2" style={{ width: "40px" }} />
            <strong>NFTrees</strong>
          </div>
          <a href="https://venly.market/collection/Coorest" target="_blank" style={{ width: "auto" }} rel="noreferrer">
            <button className={`${classes.dapp_btn}`}>
              <FormattedMessage id="buy_nftrees" />
            </button>
          </a>
        </div>

        {/* Body */}
        <div className="flex w-full flex-column align-items-start pt-2">
          <div className="mt-4">
            <FormattedMessage id="total_nftrees" />:
            <strong className={classes.strong_blue}>
              {" "}
              {nftreeLoading ? (
                <Icon className={classes.spinner} path={mdiLoading} style={{ color: "#444" }} />
              ) : (
               currentUser.totalNFTrees
              )}
            </strong>
           
          </div>

          <div className="mt-2">
            <FormattedMessage id="absorbing" />{" "}
            <strong className={classes.strong_blue}>{currentUser.totalAbsorbtion}</strong>{" "}
            <FormattedMessage id="per_year" />
          </div>
        </div>
        <button
          className={`${classes.secondary_btn} ${classes.button_with_icon} flex justify-content-between align-items-center`}
          onClick={transferNFTree}
        >
          {loading ? (
            <Icon className={classes.transferSpinner} path={mdiLoading} />
          ) : (
            <FormattedMessage id="transfer" />
          )}
          <Icon path={mdiTreeOutline} className={classes.icon}></Icon>
        </button>
      </div>

      {/* Transfer Dialog */}
      <Dialog
        draggable={false}
        header={TRANSLATIONS.transfer_your_nftrees}
        visible={batchTransferDialog}
        footer={renderBatchTransferFooter()}
        onHide={closeBatchTransferDialog}
        className={classes.transfer_dialog}
      >
        <div className="my-3">
          <label htmlFor="receiver">
            <FormattedMessage id="receiver_address" />
          </label>
          <InputText id="receiver" className="w-full" value={receiver} onChange={onReceiverChange} />
        </div>
        <div className="my-3 w-full">
          <label className="w-full">
            <FormattedMessage id="choose_the_nftrees" /> ({currentUser.totalNFTrees})
          </label>
          <MultiSelect
            value={selectedTrees}
            className="w-full"
            options={currentUser.NFTrees}
            onChange={(e) => setSelectedTrees(e.value)}
            optionLabel="token_id"
            optionValue="token_id"
            placeholder={TRANSLATIONS.select_nftrees}
            display="chip"
            filter={true}
            panelFooterTemplate={panelFooterTemplate}
            itemTemplate={nftreeTemplate}
          />
        </div>
        {selectedTrees && selectedTrees.length > constants.chunkSize && (
          <div className="my-3 flex flex-row">
            {" "}
            <div className="pl-2 pr-2">
              <img src={warningIcon} height={"24px"} width={"auto"} />
            </div>
            <small className="pl-2 pr-2">
              Please be aware that in some cases the transaction may be split into multiple ones. In these cases, it is
              important that you confirm each of the transactions in order to complete the process.
            </small>
          </div>
        )}
      </Dialog>

      {/* User Login Dialog */}
      {displayLoginDialog && <UserLogin displayLoginDialog={displayLoginDialog} onHide={closeLoginDialog} />}

      {/* Toast */}
      <Toast ref={toast} />
    </React.Fragment>
  );
};

export default NFTrees;
