import { FormattedMessage, useIntl } from "react-intl";
import ForumIcon from "../assets/images/DAO/forum/icon.png";
import SnapmIcon from "../assets/images/DAO/snapshot/icon.png";
import TopNavbar from "../components/common/top-navbar/NavbarTop";
import LinkWithIcon from "../components/dao/LinkWithIcon";
import { useAppSelector } from "../store/hooks";
import { RootState } from "../store/store";
import classes from "../styles/DaoPage.module.scss";
import RightSidebar from "./RightSidebar";
import { useTranslations } from "../i18n/useTranslations"

export interface DAOProps {
  onThemeChange: (newTheme: string) => void;
}

const DaoPage = (props: DAOProps) => {
  const currentUser = useAppSelector(
    (state: RootState) => state.users.currentUser
  );

  const TRANSLATIONS = useTranslations();

  return (
    <div className={`${classes.dashboard} flex w-full justify-content-between`}>
      <div className={classes.main_part}>
        <TopNavbar currentAccount={currentUser} />
        {/* Main Section */}
        <div className={classes.dao_title}>
          <FormattedMessage id="participate_in_coorest_dao" />
        </div>
        <section className="flex w-full flex-wrap justify-content-between">
          <div className={`${classes.main_content} flex-column w-full`}>
            <LinkWithIcon
              target="_blank"
              rel="noreferrer"
              className={classes.forum_section}
              href="https://community.coorest.io"
              iconSrc={ForumIcon}
              altText="Coorest Forum"
              text={TRANSLATIONS.coorest_forum}
            />
            <LinkWithIcon
              target="_blank"
              rel="noreferrer"
              className={classes.snapshot_section}
              href="https://snapshot.org/#/coorest.eth"
              iconSrc={SnapmIcon}
              altText="Snapshot.org"
              text="Snapshot.org"
            />
          </div>
        </section>
      </div>

      {/* Right sidebar */}
      <RightSidebar onThemeChange={(theme) => props.onThemeChange(theme)} />
    </div>
  );
};

export default DaoPage;
