import React, { useState } from "react";
import classes from "../../styles/Dashboard.module.scss";
import co2Icon from "../../assets/images/tokens/co2.png";
import coorestIcon from "../../assets/images/tokens/token.png";
import Icon from "@mdi/react";
import { mdiContentCopy, mdiHandCoinOutline } from "@mdi/js";
import { User } from "../../store/custom-hooks/useUser/usersTypes";
import { polygonConfig } from "../../dappConfig";
import metamaskIcon from "../../assets/images/wallets/metamask.png";
import { Tooltip } from "primereact/tooltip";
import { FormattedMessage, useIntl } from "react-intl";


interface TokenBalanceProps {
  currentUser: User;
}

const TokenBalance = ({ currentUser }: TokenBalanceProps) => {
  const [copyToClipboard, setCopyToClipboard] = useState(false);
  const intl = useIntl();
  const copiedMessage = intl.formatMessage({id: 'copied'});
  const copyAdressMessage = intl.formatMessage({id: 'copy_address'});
  const addToMetamaskMessage = intl.formatMessage({id: 'add_to_metamask'});

  const addTokenToMetamask = async (tokenAddress:any, tokenSymbol:any, image:any) => {
    const tokenDecimals = 18;

    try {
      //@ts-ignore
      const wasAdded = await ethereum.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20",
          options: {
            address: tokenAddress,
            symbol: tokenSymbol,
            decimals: tokenDecimals,
            image: image,
          },
        },
      });

      if (wasAdded) {
        console.log("Thanks for your interest!");
      }
    } catch (err) {
      throw err;
    }
  };
  const copyTokenToWallet = (tokenAddress:any) => {
    const el = document.createElement("textarea");
    el.value = tokenAddress;
    el.setAttribute("readonly", "");
    el.style.position = "absolute";
    el.style.left = "-9999px";
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    setCopyToClipboard(true);
    setTimeout(() => {
      setCopyToClipboard(false);
    }, 2000);
  };

  return (
    <React.Fragment>
      <div
        className={`${classes.base_card} ${classes.card} ${classes.token_balance} flex-column`}
      >
        {/* title */}
        <div className="flex align-items-center w-full">
          <Icon
            path={mdiHandCoinOutline}
            className={classes.token_balance_title}
          />
          <strong><FormattedMessage
  id="token_balance"
/></strong>
        </div>

        {/* Body */}
        <div className="flex w-full justify-content-between h-full">
          <div
            className={`${classes.base_card} ${classes.token_balance} ${classes.token_balance_content} flex flex-column justify-content-center align-items-center`}
          >
            <img src={coorestIcon} alt="$CRST" className="mb-2 mt-2" />
            <div>
            <span>CRST</span>
              <span
                className="cursor-pointer ml-2"
                onClick={()=>{addTokenToMetamask(polygonConfig.CRST_TOKEN_ADDRESS, "CRST", "http://localhost:3000/token.png")}}
              >
                <Tooltip
                  target=".metamaskIcon"
                />
                <img
                  src={metamaskIcon}
                  className="metamaskIcon"
                  alt={addToMetamaskMessage}
                  style={{ width: "20px", position: "relative", top: "4px" }}
                  data-pr-tooltip="Add to metamask"
                  data-pr-position="right"
                />
              </span>
          <span className="ml-2" onClick={()=>copyTokenToWallet(polygonConfig.CRST_TOKEN_ADDRESS)}>
                <Tooltip
                  target=".copyCRSTAdress"
                  content={copyToClipboard ? copiedMessage : copyAdressMessage}
                />
              <Icon
                path={mdiContentCopy}
                style={{ width: "15px" }}
                className="copyCRSTAdress cursor-pointer mx-1"
                data-pr-position="right"
              ></Icon>
            </span>
            </div>
            <strong className={classes.strong_blue}>
              {currentUser.crstBalance.toLocaleString(undefined, {
                maximumFractionDigits: 2,
              })}
            </strong>
          </div>


          <div
            className={`${classes.base_card} ${classes.token_balance} ${classes.token_balance_content} flex flex-column justify-content-center align-items-center`}
          >
            <img src={co2Icon} alt="$CO2" className="mb-2 mt-2" />
            <div>
              <span>CCO2</span>
              <span
                className="cursor-pointer ml-2"
                onClick={()=>{addTokenToMetamask(polygonConfig.CO2_TOKEN_ADDRESS, "CCO2", "http://localhost:3000/co2.png")}}
              >
                <Tooltip
                  target=".metamaskIcon"

                />
                <img
                  src={metamaskIcon}
                  className="metamaskIcon"
                  alt={addToMetamaskMessage}
                  style={{ width: "20px", position: "relative", top: "4px" }}
                  data-pr-tooltip="Add to metamask"
                  data-pr-position="right"
                />
              </span>

              <span onClick={()=>copyTokenToWallet(polygonConfig.CO2_TOKEN_ADDRESS)}>
                <Tooltip
                  target=".copy"
                  
                  content={copyToClipboard ? copiedMessage : copyAdressMessage}
                />
                <Icon
                  path={mdiContentCopy}
                  style={{ width: "15px" }}
                  className="copy cursor-pointer mx-1"
                  data-pr-position="right"
                ></Icon>
              </span>
            </div>
            <strong className={classes.strong_blue}>
              {currentUser.co2Balance.toLocaleString(undefined, {
                maximumFractionDigits: 2,
              })}
            </strong>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TokenBalance;
