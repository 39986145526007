import { createSlice } from "@reduxjs/toolkit";
import { User } from "./usersTypes";

const currentUser: User = {
  username: "",
  fullName: "",
  accounts: [],
  ethAddress: "",
  email: "",
  company: "",
  profilePicture: null,
  co2Balance: 0,
  crstBalance: 0,
  claimableCo2: 0,
  NFTrees: [],
  totalAbsorbtion: 0,
  treesInfo: [],
  pocc: [],
  usdcBalance: 0,
  totalNFTrees: 0,
  totalCompensation: 0,
};

export const usersSlice = createSlice({
  name: "users",
  initialState: {
    currentUser: currentUser,
  },
  reducers: {
    login: (state, action) => {
      // console.log("dispatch login",action.payload);
      state.currentUser = action.payload;
    },
    userLogout: (state) => {
      state.currentUser = {
        username: "Guest",
        fullName: "Guest",
        accounts: [],
        ethAddress: "0x00000",
        email: "guest@coorest.io",
        company: "",
        profilePicture: null,
        co2Balance: 0,
        crstBalance: 0,
        claimableCo2: 0,
        NFTrees: [],
        totalAbsorbtion: 0,
        treesInfo: [],
        pocc: [],
        usdcBalance: 0,
        totalNFTrees: 0,
        totalCompensation: 0,
      };
    },
    updateUserProfile: (state, action) => {
      state.currentUser.fullName = action.payload.fullName;
      state.currentUser.email = action.payload.email;
      state.currentUser.company = action.payload.company;
    },
    updateAvatar: (state, action) => {
      state.currentUser.profilePicture = action.payload.profilePicture;
    },
    updateEthaddress: (state, action) => {
      state.currentUser.ethAddress = action.payload;
    },
    updateTotalCompensation: (state, action) => {
      state.currentUser.totalCompensation = action.payload;
    },
    updatePocc: (state, action) => {
      state.currentUser.pocc = action.payload;
    },
    updateNftrees: (state, action) => {
      state.currentUser.NFTrees = action.payload.nftrees;
      state.currentUser.totalAbsorbtion = action.payload.totalAbsorbtion;
    },
    updateTotalNftrees: (state, action) => {
      state.currentUser.totalNFTrees = action.payload;
    },
    updateClaimableCo2: (state, action) => {
      state.currentUser.claimableCo2 =
        state.currentUser.claimableCo2 - action.payload;
      state.currentUser.co2Balance =
        state.currentUser.co2Balance + +action.payload;
    },
    updateCompensationAmount: (state, action) => {
      // console.log("payload", action.payload);
      state.currentUser.totalCompensation =
        +state.currentUser.totalCompensation + +action.payload;
    },
    updateCo2Balance: (state, action) => {
      // console.log("payload", action.payload);
      state.currentUser.co2Balance =
        +state.currentUser.co2Balance - +action.payload;
    },
    claimedAll: (state) => {
      const coorestFee = (state.currentUser.claimableCo2 * 8) / 100;
      state.currentUser.co2Balance =
        state.currentUser.co2Balance +
        (state.currentUser.claimableCo2 - coorestFee);
      state.currentUser.claimableCo2 = 0;
    },
    removeNFTree: (state, action) => {
      state.currentUser.NFTrees = state.currentUser.NFTrees.filter(
        (nftree) => nftree.attributes?.token_id !== action.payload
      );
    },
    removeNFTrees: (state, action) => {
      state.currentUser.NFTrees = state.currentUser.NFTrees.filter(
        (nftree) => !action.payload.includes(nftree.attributes?.token_id)
      );
    },
    resetClaimableCo2: (state, action) => {
      state.currentUser.claimableCo2 = action.payload;
    },
  },
});

// // the outside "thunk creator" function
// const fetchUserById = userId => {
//     // the inside "thunk function"
//     return async (dispatch, getState) => {
//       try {
//         // make an async call in the thunk
//         const user = await userAPI.fetchById(userId)
//         // dispatch an action when we get the response back
//         dispatch(userLoaded(user))
//       } catch (err) {
//         // If something went wrong, handle it here
//       }
//     }
//   }

export const {
  login,
  userLogout,
  updateTotalCompensation,
  updateClaimableCo2,
  updateCo2Balance,
  claimedAll,
  removeNFTree,
  removeNFTrees,
  updateTotalNftrees,
  updateUserProfile,
  updateAvatar,
  updatePocc,
  updateNftrees,
  updateCompensationAmount,
  updateEthaddress,
  resetClaimableCo2,
} = usersSlice.actions;

export default usersSlice.reducer;
