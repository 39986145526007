import { mdiLoading } from "@mdi/js";
import Icon from "@mdi/react";
import { Toast } from "primereact/toast";
import React, { useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import classes from "../../styles/CO2Calculator.module.scss";
import CO2Burner from "../co2-burner/CO2Burner";
import UserLogin from "../users/UserLogin";
import { useMoralis } from "react-moralis";


interface TotalFootprintCardProps {
  totalFootprint: number;
}

const TotalFootprintCard: React.FC<TotalFootprintCardProps> = ({
  totalFootprint,
}) => {
  const toast = useRef(null);
  const { user:currentUser } = useMoralis();
  const [poccLoading, setPoccLoading] = useState<boolean>(false);
  const [displayBurningDialog, setDisplayBurningDialog] =
    useState<boolean>(false);
  const [displayLoginDialog, setDisplayLoginDialog] = useState(false);
  
  const closeLoginDialog = () => {
    setDisplayLoginDialog(false);
  };

  const showBurningDialog = () => {
    console.log("showBurningDialog called");
    // setDisplayBurningDialog(true);

    if (currentUser) {
      setDisplayBurningDialog(true);
    } else {
      setDisplayLoginDialog(true);
    }
  };

  return (
    <>
      <div className={`${classes.base_card} flex-column w-full m-auto p-3`}>
        <div className="flex flex-row mb-1">
          <strong className={`${classes.title}`}>
            <FormattedMessage id="yourTotalFootprint" />
          </strong>
        </div>
        <div className="flex flex-row align-items-center">
          <div className={`${classes.insurance_premium} w-full mb-1`}>
            {totalFootprint} Kg CO2
          </div>
        </div>
        <div className="mt-1">
          <button
            disabled={totalFootprint === 0}
            onClick={showBurningDialog}
            className={`${classes.secondary_btn} xl:mt-2 ml-1`}
          >
            {poccLoading ? (
              <span className="text-0">
                <Icon className={classes.transferSpinner} path={mdiLoading} />{" "}
              </span>
            ) : (
              <FormattedMessage id="burn" />
            )}
          </button>
        </div>
      </div>
      {displayBurningDialog && (
        <CO2Burner
          setPoccLoading={setPoccLoading}
          setDisplayBurningDialog={setDisplayBurningDialog}
          totalFootprint={totalFootprint}
          toast={toast}
        />
      )}
      {/* User Login Dialog */}
      {displayLoginDialog && (
        <UserLogin
          displayLoginDialog={displayLoginDialog}
          onHide={closeLoginDialog}
        />
      )}
      <Toast ref={toast} />
    </>
  );
};

export default TotalFootprintCard;
