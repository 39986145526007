import { LOCALES } from '../constants';

export default {
  [LOCALES.ITALIAN]: {
    "absorbing": "Assorbimento",
    "actions": "Azioni",
    "add_to_compensation_field": "Aggiungi al campo di compensazione",
    "add_to_metamask": "Aggiungi a Metamask",
    "address": "Indirizzo ",
    "agree_to_terms_and_conditions": "Accetto i termini e le condizioni",
    "agree_to_terms_condition_privacy_policy": "Accetto i Termini e le Condizioni e l'Informativa sulla Privacy",
    "amount": "Importo",
    "amount_of_compensation": "Importo della compensazione",
    "amount_shown_in_pocc": "Importo indicato nel PoCC",
    "annual_percentage_return": "Rendimento percentuale annuo",
    "apy": "APY",
    "back": "Indietro",
    "batch": "Batch",
    "bundle_lifetime": "Durata del pacchetto",
    "bundle_lifetime_until": "Durata del pacchetto fino a",
    "bundle_name": "Nome del pacchetto",
    "burn": "Brucia",
    "burn_cco2_to_generate": "Brucia CCO2 per generare un PoCC Certificate",
    "burned_percentage": "Percentuale bruciata",
    "burnt": "Bruciato",
    "business_name": "Nome dell'azienda(se applicabile)",
    "buy_insurance": "Acquista un'assicurazione",
    "buy_nftrees": "Acquista NFTrees",
    'bridge':'Ponte',
    'bridge_eth_polygon':'Ponte ETH-Polygon',
    'bridge_eth_q':'Ponte ETH-Q',
    'bridge_eth_arbitrium':'Ponte ETH-Arbitrium',
    "calculateYourEmission": "Calcola le tue emissioni",
    "cancel": "Annulla",
    "carTravel": "Viaggio in auto",
    "caspe_spain": "Caspe, Spagna",
    "cco2_available": "CCO2 disponibili da bruciare",
    "cco2_burned": "CCO2 bruciati (KG) :",
    "cco2_conversion_rate": "( 1 CCO2 token = 1 KG di CO2)",
    "cco2_tokens": "CCO2 Tokens",
    "ccsRegisrty": "CCS Registry",
    "certificate_id": "Certificate ID",
    "certificates": "Certificati",
    "certificates_created": "Certificati creati",
    "charts": "Grafici",
    "check_your_file": "Qualcosa è andato storto, controlla di nuovo il tuo file",
    "chooseCategory": "Scegli la categoria",
    "choose_insurance_period": "Scegli il periodo di assicurazione",
    "choose_project_id": "Scegli ID progetto",
    "choose_the_nftrees": "Scegli NFTrees:",
    "claim_all": "Ritira tutto",
    "claimed": "Non reclamato",
    "close": "Chiudi",
    "co2Calculator": "CO2 Calculator",
    "co2_amount": " Importo CO2",
    "coming_soon": "Coming soon",
    "compensate": "Compensazione",
    "connect_wallet": "Connetti wallet",
    "connect_your_wallet": "Collegate il vostro wallet e iniziate subito a usare la DApp",
    "coorest_dao": "Coorest DAO",
    "coorest_fee": "Commissione Coorest",
    "coorest_forum": "Coorest forum",
    "copied": "Copiato!",
    "copy_address": "Copia indirizzo",
    "dashboard": "Dashboard",
    "date": "Data",
    "days": "Giorni",
    "diesel": "Diesel",
    "distance": "Distanza",
    "earned": "Accumulato",
    "email": "Email",
    "email_has_been_sent": "Email inviata.",
    "end_date": "Fine",
    "enter_bundle_name": "Inserire il nome del pacchetto",
    "enter_the_amount_of_co2": "Inserisci la quantità di CO2 da riportare sul tuo certificato PoCC. Il calcolatore indica quanti token andrebbero bruciati per ottnere quella quantità",
    "error": "Errore",
    'exchange': '',
    "fees_from_claimed_amount": "Applichiamo una commissione dell'8% sull'importo richiesto",
    "fees_notice": "Si noti che per la creazione del PoCC viene trattenuta una commissione dello 0,2%.",
    "fig": "Fico",
    "fig_trees": "Alberi di fico",
    "fire": "Incendio",
    "flight": "Volo",
    "flood": "Alluvione",
    "fuelToCo2": "Carburante in co2",
    "fullname": "Nome completo ",
    "generated": "Generato",
    "gps_location": "Posizone GPS",
    "guest": "Ospite",
    "help": "Help",
    "hey_welcome": "Ciao! Benvenuto",
    "hi": "Ciao, ",
    "history": "Cronologia",
    "how_many_cco2_to_compensate": "Quanti token CCO2 vuoi compensare?",
    "info": "Info",
    "insurance": "Assicurazione",
    "insurance_history": "Cronologia assicurazione",
    "insurance_period": "Periodo assicurativo",
    "insurance_premium": "Premio assicurativo",
    "insurances": "Assicurazioni",
    "invest_in_risk_pool": "Investi in un risk pool",
    "invested_amount": "Importo investito",
    "kg_of_co2_absorbed": "KG di CO2 assorbita /anno",
    "largeDieselCar": "Autovettura grande Diesel",
    "largeHybridCar": "Autovettura Ibrida grande",
    "largePetrolVan": "Furgone grande Benziona",
    "lifetime_accumulated": "Accumulato ad oggi (CCO2) :",
    "liters": "Litri",
    "live_statistics": "Live statistics",
    "location": "Posizione",
    "login": "Login",
    "login_with_venly": "Login su Venly",
    "logout": "Logout",
    "longBusinessClassFlight": "Volo a lungo raggio in classe business",
    "longEconomyClassFlight": "Volo a lungo in classe economy",
    "longFirstClassFlight": "Volo a lungo raggio in prima classe",
    "lpg": "GPL",
    "max_coverage_duration": "Durata massima della copertura",
    "max_protected_amount": "Importo massimo protetto",
    "mediumDieselCar": "Autovettura media Diesel",
    "mediumHybridCar": "Autovettura Ibrida media",
    "min_coverage_duration": "Minimum coverage duration",
    "min_protected_amount": "Importo minimo protetto",
    "mixed_forest": "Bosco misto",
    "my_balance": "Il tuo saldo",
    "my_profile": "Il mio profilo",
    "name": "Nome",
    "name_on_the_certificate": "Nome sul certificato",
    "network_error": "Network Error",
    "nftrees_refresh_button": "Fare clic sul pulsante Refresh per aggiornare i numeri dopo aver trasferito l'albero.",
    "no_enough_nftrees": "Non hai nessun NFTree per relcamare CO2",
    "no_specific_reason": "Nessun motivo specifico",
    "nothing_to_burn": "Niente da bruciare",
    "nothing_to_claim": "Niente da reclamare",
    "numberOfLiters": "Numero di litri da calcolare da",
    "pagination_info": "Visualizza da {first} a {last} di {totalRecords}",
    "participate_in_coorest_dao": "Partecipa a Coorest DAO",
    "per_year": "KG di CO2 per anno",
    "pest": "Infestazione",
    "petrol": "Benzina",
    "plant_date": "Data di impianto",
    "please_check_your_inbox": "Controlla la tua posta in arrivo",
    "please_switch_to": "Passa a",
    "pocc_certificates": "PoCC Certificates",
    "pocc_error": "Si è verificato un problema nella generazione del certificato PoCC. Riprovare più tardi.",
    "pocc_success": "Il certificato PoCC è stato generato con successo. Potrebbero essere necessari alcuni minuti prima di poterlo vedere nell'elenco dei certificati.",
    "pomegranate_trees": "Alberi di melograno",
    "powered_by": "Powered by",
    "profile_updated": "I dati del profilo sono stati aggiornati con successo",
    "project1": "Progetto 1",
    "project2": "Progetto 2",
    "project3": "Progetto 3",
    "projectId": "ID Progetto",
    "project_id": "Project ID",
    "properties": "Proprietà",
    "quickly_bridge_tokens":"Collega rapidamente i tuoi token tra catene diverse (cross-chain)",
    "reason_for_compensating": "Motivo della compensazione",
    "received": "Ricevuto",
    "receiver_address": "Indirizzo del destinatario :",
    "reports": "Reports",
    "risk_category": "Categoria di rischio",
    "risks_chosen": "Scelta Rischio",
    "save": "Salva",
    "selectCarType": "Seleziona il tipo di auto",
    "selectFlightClass": "Seleziona una classe di volo",
    "selectFuelType": "Seleziona il tipo di carburante",
    "selectOption": "Seleziona un'opzione",
    "select_language": "Seleziona la lingua",
    "select_nftrees": "Seleziona NFTrees",
    "select_risks": "Seleziona i rischi",
    "sent": "Inviato",
    "settings": "Impostazioni",
    "shortBusinessClassFlight": "Volo a corto raggio in classe business",
    "shortEconomyClassFlight": "Volo a corto raggio in classe economy",
    "sign_the_transaction": "Firma la transazione",
    "sign_transaction_wallet_connect": "Sottoscrivi la transazione sul tuo walletConnect",
    "smallDieselCar": "Autovettura picoola Diesel",
    "smallPetrolVan": "Furgone piccolo Benzina",
    "something_went_wrong": "Qualcosa è andato storto. Si prega di riprovare più tardi.",
    "spain": "Spagna",
    "staked_crst": "Staked CRST",
    'stakeCRST' : 'Stake CRST',
    "start_date": "Inizio",
    "stats": "Statistiche",
    "status": "Stato",
    "success": "Successo",
    "successfully_claimed": "Token CCO2 reclamato con successo",
    "summary": "Sintesi",
    "term": "Termine",
    "theDistanceInKM": "Distanza in Km",
    "to_be_determined": "Da definire",
    "token_balance": "Saldo Token",
    "token_id": "Token ID",
    "top_co2_compensators": "Top CO2 Compensators",
    "top_nftrees_holders": "Top NFTree holders",
    "total": "Totale",
    "total_co2_absorbed": "Totale CO2 assorbita (KG)",
    "total_co2_compensated": "Total CO2 compensated (KG)",
    "total_nftrees": "Totale NFTrees",
    "transaction_wasnt_successful": "La transazione non è andata a buon fine. Si prega di riprovare",
    "transactions": "Transazioni",
    "transfer": "Trasferimento",
    "transfer_completed": "Trasferimento completato",
    "transfer_your_nftrees": "Trasferisci i tuoi NFTrees",
    "twenty_years_ago": "20 anni",
    "type": "Tipo",
    "type_your_name": "Digitare il nome e/o l'azienda da riportare sul certificato",
    "unable_to_burn": "Non è possibile bruciare CCo2. Riprovare più tardi.",
    "unable_to_claim": "Non è possibile reclamare co2.",
    "unclaimed": "Non reclamato (CCO2) :",
    "update_your_details": "Aggiorna i tuoi dati",
    "vault_tvl": "Vault TVL",
    "what_is_the_reason_for_compensation": "Qual è il motivo del risarcimento?",
    "yourTotalFootprint": "La tua impronta totale",
    "your_stake": "Il tuo stake",
    'total_staked': 'Total staked',
    'reward_rate': 'Reward Rate',

  },
};
