import React from "react";
import classes from "./DappLoading.module.scss";
import { mdiLoading } from "@mdi/js";
import Icon from "@mdi/react";

const DappBoxLoading = () => {
  return (
    <React.Fragment>
      <div className={classes.box_loading}>
        <Icon path={mdiLoading} className={classes.spinner} />
      </div>
    </React.Fragment>
  );
};

export default DappBoxLoading;
