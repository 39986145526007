import React, { useEffect, useState } from "react";
import classes from "../../styles/Dashboard.module.scss";
import { FormattedMessage, useIntl } from "react-intl";
import LogoIcon from "../../assets/images/logo/logo-icon.png";
import LogoIconWhite from "../../assets/images/logo/Logo-white.png";
import DappBoxLoading from "../../ui/DappBoxLoading";
import CO2ABI from "../../services/abi/co2Abi";
import { getTotalCO2Absorbed } from "../../utils/api";


const LiveStatistics = ({ totalCO2Compensated, setTotalCO2Compensated }: any) => {

  const Moralis = require("moralis-v1");
  const [totalCO2Loading, setTotalCO2Loading] = useState(false);
  const [totalCO2Absorbed, setTotalCO2Absorbed] = useState<string>("");
  const [percentBurnt, setPercentBurnt] = useState<string>("");

  const getTotalCO2 = async () => {
    var totalCO2Absorbed = sessionStorage.getItem("totalCO2Absorbed");
    if (totalCO2Absorbed) {
      setTotalCO2Absorbed(totalCO2Absorbed);
      return;
    }
    setTotalCO2Loading(true);
    let result = await getTotalCO2Absorbed();
    // console.log(response);
    setTotalCO2Absorbed(result);
    sessionStorage.setItem("totalCO2Absorbed", result);
    setTotalCO2Loading(false);
    const currentTimestamp = Date.now();
    sessionStorage.setItem("lastFetchLiveStatsTimestamp", currentTimestamp.toString());

  };


  const onStart = async () => {
    const currentTimestamp = Date.now();
    // get lastFetchTimestamp from sessionStorage
    const lastFetchLiveStatsTimestamp = sessionStorage.getItem("lastFetchLiveStatsTimestamp");

    if (lastFetchLiveStatsTimestamp !== null && currentTimestamp - Number(lastFetchLiveStatsTimestamp) < 60 * 60 * 1000) {
      const totalCO2Absorbed = sessionStorage.getItem("totalCO2Absorbed");
      const totalCO2Compensated = sessionStorage.getItem("totalCO2Compensated");

      if (totalCO2Absorbed) {
        setTotalCO2Absorbed(JSON.parse(totalCO2Absorbed));
      }
      if (totalCO2Compensated) {
        setTotalCO2Compensated(JSON.parse(totalCO2Compensated));
      }

    } else {
      await Promise.all([getTotalCO2()]);

    }
  }

  useEffect(() => {
    onStart();
  }, []);

  const getTotalSupply = async () => {
    try {
      const ABI = CO2ABI;
      const options = {
        chain: "polygon",
        address: "0x82B37070e43C1BA0EA9e2283285b674eF7f1D4E2",
        function_name: "totalSupply",
        abi: ABI,
        params: {},
      };
      const count = await Moralis.Web3API.native.runContractFunction(options);
      const totalSupply = parseFloat(count) / Math.pow(10, 18);
      return totalSupply;
    } catch (error) {
      console.error(error);
    }
  }
  const percentCalcul = async () => {
    const Co2Burnt = parseFloat(totalCO2Compensated);
    const totalSupply = await getTotalSupply();
    if (typeof totalSupply !== 'undefined') {
      const percentResult = (Co2Burnt / (totalSupply + Co2Burnt)) * 100;
      setPercentBurnt(percentResult.toFixed(2));
    }
  }

  useEffect(() => {
    if (totalCO2Compensated !== "") {
      console.log("totalCO2Compensated state changed", totalCO2Compensated);
      percentCalcul();
    }
  }, [totalCO2Compensated]);

  return (
    <>
      <div className={classes.live_statistics}>
        <div className="flex align-items-center pt-4">
          <img
            src={LogoIcon}
            alt=""
            width="20"
            height="20"
            className={`${classes.logo_transition} ${classes.lightIcon} ml-0 mr-2`}
          />
          <img
            src={LogoIconWhite}
            alt="logoIconWhite"
            width="20"
            height="20"
            className={`${classes.logo_transition} ${classes.darkIcon} ml-0 mr-2`}
          />
          <strong style={{ fontSize: "19px" }}><FormattedMessage
            id="live_statistics"
          /></strong>
        </div>
        <div className="w-full flex justify-content-between">
          <span>
            <FormattedMessage id="total_co2_absorbed" /> :
          </span>
          {totalCO2Loading ? (
            <DappBoxLoading />
          ) : (
            <strong>
              {Number(totalCO2Absorbed).toLocaleString(undefined, {
                maximumFractionDigits: 2,
              })}
            </strong>
          )}
        </div>
        <div className="w-full flex justify-content-between">
          <span>
            <FormattedMessage id="total_co2_compensated" /> :
          </span>
          {totalCO2Loading ? (
            <DappBoxLoading />
          ) : (
            <strong>
              {Number(totalCO2Compensated).toLocaleString(undefined, {
                maximumFractionDigits: 2,
              })}
            </strong>
          )}
        </div>
        <div className="w-full flex justify-content-between">
          <span><FormattedMessage
            id="burned_percentage"
          /> :</span>
          {totalCO2Loading ? (
            <DappBoxLoading />
          ) : (

            <strong>
              {percentBurnt}{"%"}
            </strong>
          )}
        </div>
      </div>

    </>
  );
};

export default LiveStatistics;