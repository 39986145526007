import { mdiArrowUpThinCircleOutline, mdiSwapHorizontal } from "@mdi/js";
import Icon from "@mdi/react";
import { ethers } from "ethers";
import { Dialog } from "primereact/dialog";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { Tooltip } from "primereact/tooltip";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import calculatorIcon from "../../assets/images/calculator.png";
import maxIcon from "../../assets/images/max.png";
import warningIcon from "../../assets/images/warning-sign.jpg";
import { polygonConfig } from "../../dappConfig";
import { useContracts } from "../../store/custom-hooks/useContract/useContract";
import { useFetchPocc } from "../../store/custom-hooks/usePocc/useFetchPocc";
import { updateCo2Balance, updateCompensationAmount } from "../../store/custom-hooks/useUser/usersSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { RootState } from "../../store/store";
import classes from "./CO2Burner.module.scss";
import { useTranslations  } from "../../i18n/useTranslations";
import { constants } from "../../utils/constants";

export interface CO2BurnerProps {
  setDisplayBurningDialog: (status: boolean) => void;
  totalFootprint?: number;
  toast?: any;
  setBurnerLoading: (status: boolean) => void;
}
const CO2Burner = (props: any) => {
  const currentUser = useAppSelector((state: RootState) => state.users.currentUser);
  
  const TRANSLATIONS = useTranslations ();
  const intl = useIntl();
  const Moralis = require("moralis-v1");
  const dispatch = useAppDispatch();
  const [displayCalculatorDialog, setDisplayCalculatorDialog] = useState<boolean>(false);
  const [enteredAmount, setEnteredAmount] = React.useState<number>(
    props.totalFootprint! + props.totalFootprint! * constants.compensationRate || 0
  );
  const [compensationAmount, setCompensationAmount] = useState<number>(
    props.totalFootprint! + props.totalFootprint! * constants.compensationRate || 0
  );
  const [certificateAmount, setCertificateAmount] = useState<number>(props.totalFootprint || 0);
  const [compensationReason, setCompensationReason] = useState<string>("No specific reason");
  const [fullName, setFullName] = useState<string>(currentUser.fullName);
  const [company, setCompany] = useState<string>(currentUser.company);
  const { getCoorestContract, getCO2Contract } = useContracts();
 
  const { refetch : refetchPocc } = useFetchPocc();
  const mintPOCC = async () => {
    let chainId = await Moralis.getChainId();
    if (chainId != polygonConfig.chainId) {
      props.toast.current &&
        (props.toast.current as any).show({
          severity: "error",
          summary: TRANSLATIONS.network_error,
          detail: `Please switch to ${polygonConfig.NETWORK_NAME}`,
          life: 4000,
        });
      return;
    }
    var co2 = await getCO2Contract();
    var coorest = await getCoorestContract();
    try {
      props.setBurnerLoading(true);
      props.setDisplayBurningDialog(false);
      let balanceOfCO2Before = await co2.balanceOf(currentUser.ethAddress);
      

      const amountOfCompensation = enteredAmount ? ethers.utils.parseUnits(enteredAmount.toString(), 18) : 0;

      let tx1 = await coorest.mintPOCC(
        amountOfCompensation,
        compensationReason,
        [fullName, company].filter(Boolean).join(" / ")
      );
      await tx1.wait();

      Promise.all([balanceOfCO2Before, tx1])
        .then(async()  => {
          props.setBurnerLoading(true);
          props.toast.current &&
            (props.toast.current as any).show({
              severity: "success",
              summary: TRANSLATIONS.success,
              detail: TRANSLATIONS.pocc_success,
              life: 4000,
            });
            await refetchPocc();
        })
        .catch((err) => {
          console.log(err);
          props.toast.current &&
            (props.toast.current as any).show({
              severity: "error",
              summary: TRANSLATIONS.error,
              detail: TRANSLATIONS.pocc_error,
              life: 4000,
            });
        })
        .finally(() => {
          dispatch(updateCompensationAmount(enteredAmount ?? 0));
          dispatch(updateCo2Balance(enteredAmount ?? 0));
          props.setBurnerLoading(false);
        });
    } catch (err) {
      props.setBurnerLoading(false);
      props.toast.current &&
        (props.toast.current as any).show({
          severity: "error",
          summary: TRANSLATIONS.error,
          detail: TRANSLATIONS.unable_to_burn,
          life: 3000,
        });
      console.log(err);
    } finally {
      setTimeout(() => {
        props.setDisplayBurningDialog(false);
      }, 4000);
    }
  };

  const renderFooter = () => {
    return (
      <div className="flex justify-content-between">
        <button className={classes.dapp_btn} disabled={validateInputs() ? false : true} onClick={mintPOCC}>
          <FormattedMessage id="compensate" />
        </button>
        <button className={classes.secondary_btn} onClick={onHide}>
          <FormattedMessage id="close" />
        </button>
      </div>
    );
  };
  const renderCalculatorFooter = () => {
    return <div className={classes.calculator_footer}></div>;
  };
  const fillTheMaxAmount = () => {
    setEnteredAmount(+currentUser.co2Balance.toFixed(3));
  };
/*   const validateCompnsateInput = (event: any) => {
    const currentValue: number = event.target.value;

    const inputValue = currentValue > currentUser.co2Balance ? +currentUser.co2Balance.toFixed(3) : currentValue;

    setEnteredAmount(inputValue);
    compensationChange(inputValue);
  }; */
  const validateCompnsateInput = (amount: number | null) => {
    if(amount) {
      setEnteredAmount(amount);
      compensationChange(amount);
    }
  }

  const compensationChange = (amount: number | null) => {
    if (amount) {
      setCompensationAmount(amount);
      setCertificateAmount(amount - amount * constants.compensationRate);
    }
  };

  const onFullNameChange = (e: any) => {
    setFullName(e.target.value);
  };
  const onCompanyChange = (e: any) => {
    setCompany(e.target.value);
  };

  const certificateChange = (amount: number | null) => {
    if (amount) {
      setCertificateAmount(amount);
      setCompensationAmount(((amount * 1000) / 998));
    }
  };

  function validateInputs() {
    if (!enteredAmount || enteredAmount === 0) return false;
    if ((!fullName || fullName.length == 0) && (!company || company.length == 0)) return false;
    return true;
  }
  const onHide = () => {
    console.log("onHide called");

    props.setDisplayBurningDialog(false);
    setEnteredAmount(0);
    setCertificateAmount(0);
    setCompensationAmount(0);
    setFullName(currentUser.fullName);
    setCompany(currentUser.company);
  };

  const onCalculatorHide = () => {
    setDisplayCalculatorDialog(false);
  };
  const headerStyle = {
    height: "10px",
    boxShadow: "none",
  };
  const contentStyle = { padding: "0", boxShadow: "none" };
  

  const fillInCalcValue = () => {
    console.log("fillInCalcValue called");

    compensationAmount && compensationAmount <= +currentUser.co2Balance.toFixed(3)
      ? setEnteredAmount(compensationAmount)
      : alert("the amount is more than your max tokens");
  };

  return (
    <>
      {/* Calculator Dialog */}
      <Dialog
        draggable={false}
        visible={displayCalculatorDialog}
        onHide={onCalculatorHide}
       className={classes.calculator_dialog}
        headerStyle={headerStyle}
        contentStyle={contentStyle}
        footer={renderCalculatorFooter}        
      >
        <div className="surface-ground">
          <div className="p-fluid grid formgrid flex justify-content-center align-items-center pb-4 pr-4 pl-4 m-0">
            <p className="pb-6">
              <FormattedMessage id="enter_the_amount_of_co2" />
            </p>
            <div className="flex no-wrap align-items-center">
              <div className="field col-5 m-0 pt-2 p-float-label">
                <InputNumber inputId="pocc" value={certificateAmount} onChange={(e) => certificateChange(e.value)} maxFractionDigits={3} />
                <label htmlFor="pocc">
                  <FormattedMessage id="amount_shown_in_pocc" />
                </label>
              </div>
              <Icon path={mdiSwapHorizontal} size={2} className="m-0 p-0" />
              <div className="field col-5 m-0 pt-2 p-float-label">
                <InputNumber
                  inputId="integeronly"
                  value={compensationAmount}
                  onChange={(e) => compensationChange(e.value)}
                  maxFractionDigits={3}
                />
                <label htmlFor="integeronly">
                  <FormattedMessage id="amount_of_compensation" />
                </label>
              </div>
              <Tooltip target=".customTooltip" showDelay={100} />
              <span
                className="cursor-pointer customTooltip"

                onClick={fillInCalcValue}
                data-pr-position="bottom"
                data-pr-tooltip={TRANSLATIONS.add_to_compensation_field}
              >
                <Icon path={mdiArrowUpThinCircleOutline} size={1} color="green" className="mx-2" />
              </span>
            </div>
          </div>
        </div>
      </Dialog>

      {/* Compensation Dialog */}
      <Dialog
        draggable={false}
        header={TRANSLATIONS.burn_cco2_to_generate}
        visible={true}
        footer={renderFooter}
        onHide={onHide}
        className={classes.burn_dialog}
      >
        <div className="flex flex-column h-90">
          <p className={`${classes.burn_dialog_subtitles}`}>
            <FormattedMessage id="how_many_cco2_to_compensate" /> <br /> ( 1 CCO2 token = 1 KG of CO2)
          </p>
          <div className="p-inputgroup relative mb-6">
          <InputNumber
              className={`${classes.co2_input} w-full`}
              placeholder={TRANSLATIONS.co2_amount}
            
              value={enteredAmount ? enteredAmount : undefined}
             
              onChange={(event) => validateCompnsateInput(event.value)}
              maxFractionDigits={3}
              required
            />
            <div className={`${classes.input_helpers} p-2`}>
              <div onClick={fillTheMaxAmount}>
                <img src={maxIcon} alt="" />
              </div>

              <div>
                <img src={calculatorIcon} alt="" onClick={() => setDisplayCalculatorDialog((prev) => !prev)} />
              </div>
            </div>
          </div>
          <div className={`${classes.warning} flex pt-1`}>
            <div className="pl-2 pr-2">
              <img src={warningIcon} alt="" onClick={() => setDisplayCalculatorDialog((prev) => !prev)} />
            </div>
            <small>
              <FormattedMessage id="fees_notice" />
            </small>
          </div>
          <p className={`${classes.burn_dialog_subtitles} my-2`}>
            <FormattedMessage id="type_your_name" />
          </p>
          <div className="flex mb-2 justify-content-between">
            <div className="flex-column mr-4">
              <label htmlFor="fullname">
                <FormattedMessage id="name" />
              </label>
              <InputText
                id="fullname"
                type="text"
                name="fullname"
                className="w-full"
                onChange={onFullNameChange}
                placeholder={currentUser.fullName ? "" : "N/A"}
                defaultValue={currentUser.fullName}
              />{" "}
            </div>
            <div className="flex-column ml-4 ">
              <label htmlFor="company">
                <FormattedMessage id="business_name" />
              </label>
              <InputText
                id="company"
                type="text"
                name="company"
                className="w-full"
                onChange={onCompanyChange}
                placeholder={currentUser.company ? "" : "N/A"}
                defaultValue={currentUser.company}
              />
            </div>
          </div>
          <div className="flex">
            <small className="pr-2">
              <FormattedMessage id="name_on_the_certificate" />:{" "}
            </small>
            <label> {[fullName, company].filter(Boolean).join(" / ")}</label>
          </div>
          <div className="flex-column">
            <p className={`${classes.burn_dialog_subtitles} my-3 pt-2`}>
              <FormattedMessage id="what_is_the_reason_for_compensation" />
            </p>
            <InputText
              className="w-full"
              value={compensationReason}
              onChange={(e) => setCompensationReason(e.target.value)}
            />
          </div>
        </div>
      </Dialog>
    </>
  );
};
export default CO2Burner;
