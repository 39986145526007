import React, { useState, useRef, useEffect } from "react";
import { useMoralis, useMoralisCloudFunction, useMoralisWeb3Api } from "react-moralis";
import { polygonConfig } from "../../dappConfig";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useContracts } from "../../../src/store/custom-hooks/useContract/useContract";
import { useAppSelector } from "../../store/hooks";
import { RootState } from "../../store/store";
//import { useIntl } from "react-intl";
import { useFile } from "../../store/custom-hooks/useFile/useFile";
import jsPDF from "jspdf";
import { Document, Page, pdfjs } from "react-pdf";
import logo from "../../assets/images/logo/Coorest_Logo.svg";
import { QRCode } from "react-qrcode-logo";
import styled from "styled-components";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { format } from "date-fns";
import { Button } from "primereact/button";
import DappLoading from "../../ui/DappLoading";
import Icon from "@mdi/react";
import {
  mdiDownload,
  mdiEmail,
  mdiEye,
  mdiPound,
  mdiQrcode
} from "@mdi/js";
import poccImage from "../../assets/certificate.png";
import classes from "../../styles/POCC.module.scss";
import { useTranslations } from "../../i18n/useTranslations"
import { useFetchPocc } from "../../store/custom-hooks/usePocc/useFetchPocc";

const workerUrl = `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
pdfjs.GlobalWorkerOptions.workerSrc = workerUrl;

const PoccDataTable = () => {
  const Moralis = require("moralis-v1");
  const Web3Api = useMoralisWeb3Api();

  const TRANSLATIONS = useTranslations();

  const currentUser = useAppSelector(
    (state: RootState) => state.users.currentUser
  );
  const toast = useRef(null);
  const { user } = useMoralis();
  const poccTokenAddress = polygonConfig.POCC_TOKEN_ADDRESS;

  const [poccDetails, setPoccDetails]: any[] = useState({});
  //const [data, setData] = useState<any[]>([]);
   const { data: data, fetch: fetchPocc, isFetched } = useFetchPocc();
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 10,
    page: 1,
  });
  const [generatingCertificate, setGeneratingCertificate] = useState(false);
  const [pdfUrl, setPdfUrl] = useState<any | null>(null);
  const [showPoCCModal, setShowPoCCModal] = useState(false);
  const [detailsFetched, setDetailsFetched] = useState(false);
  const [emailLoading, setEmailLoading] = useState(false);
  const [fileUrl, SetFileUrl] = useState();
  const [generatingQR, setGeneratingQR] = useState(false);
  const [qrData, setQrData] = useState();
  const [showModal, setShowModal] = useState(false);
  const [key, setKey] = useState(0);
  const { loadXHR, getBase64 } = useFile();

  const {
    fetch: callEmailCloudFunction,
    error,
    isLoading,
  } = useMoralisCloudFunction(
    "sendEmailToUser",
    {
      email: currentUser.email,
      name: currentUser.fullName ?? currentUser.username,
      fileUrl: fileUrl,
    },
    {
      autoFetch: false,
    }
  );

  const { getPoCCContract } = useContracts();
  const generateName = (rowData: any): string => {
    try {
      return poccDetails[rowData.token_id].owner;

    } catch {
      return "";
    }
  };
  const generateCo2Amount = (rowData: any) => {
    try {
      const amountCo2 = poccDetails[rowData.token_id]
        ? poccDetails[rowData.token_id].amount
        : 0;
      return amountCo2 + " KGs";
    } catch {
      return 0;
    }
  };
  const generateDate = (rowData: any): string => {
    const date = rowData.block_timestamp
      ? format(new Date(rowData.block_timestamp), "dd MMMM Y")
      : "";
    return date;
  };
  const generateReason = (rowData: any) => {
    try {
      return poccDetails[rowData.token_id].reason;
    } catch {
      return TRANSLATIONS.no_specific_reason;
    }
  };

  const generatePDF = async (rowData: any): Promise<jsPDF> => {
    const options = {
      format: "a4",
    };
    const doc = new jsPDF(options);

    try {
      const blob = await loadXHR(poccImage);
      const base64 = await getBase64(blob);

      const amountCo2 = poccDetails[rowData.token_id]
        ? poccDetails[rowData.token_id].amount
        : 0;
      const reason = poccDetails[rowData.token_id]
        ? poccDetails[rowData.token_id].reason
        : TRANSLATIONS.no_specific_reason;
      const owner = poccDetails[rowData.token_id] ?
          poccDetails[rowData.token_id].owner
        : "";

      const fontSize = 11;
      const font = "courier";
      const fontColor = "#066E27";
      const fontStyle = "bold";

      doc.setFont(font, fontStyle);
      doc.setFontSize(fontSize);
      doc.setTextColor(fontColor);


      doc.addImage(base64 as any, "JPEG", -1, 0, 212, 300);
      //NAME
      doc.text(owner, 60, 143);
      //AMOUNT
      doc.text(
        Number.isInteger(amountCo2)
          ? amountCo2.toString() + " KGs"
          : amountCo2.toFixed(2) + " KGs",
        61,
        160
      );
      //DATE
      doc.text(
        rowData && format(new Date(rowData.block_timestamp), "dd MMMM Y"),
        119,
        160
      );
      //REASON
      doc.text(reason, 61, 176);
      // HASH nb
      doc.text(rowData && rowData.transaction_hash, 61, 194, {
        maxWidth: 120,
        align: "justify",
      });;
      // DOC ID
      doc.text(rowData && "" + rowData.token_id, 152, 42);
      doc.setTextColor('#000');
      doc.text("Document nr.", 152, 36);

      return doc;
    } catch (err) {
      console.log(err);
      return doc;
    }
  };

  const downloadCertificate = async (rowData: any) => {
    const doc = await generatePDF(rowData);
    doc.save("Certificate.pdf");
  };
  const generateCertificate = async (rowData: any) => {
    setGeneratingCertificate(true);
    try {
      const doc = await generatePDF(rowData);
      var blob2 = doc.output("bloburl");
      setPdfUrl(`${blob2}`);
      setGeneratingCertificate(false);
      setShowPoCCModal(true);

    } catch (error) {
      console.log(error);
    }
  };
  //const DialogWrapper
  const PDFDocumentWrapper = styled.div`
  canvas {
    width: 80vw !important;
    height: 90vh !important;
  };

  @media screen and (min-width: 1024px) {
    canvas {
      width: 30vw !important;
      height: 80vh !important;
    }
  } ;
`;
  const emailCertificate = async (rowData: any) => {
    setEmailLoading(true);
    const doc = await generatePDF(rowData);
    //  Upload on IPFS
    const blob = doc.output("blob");
    const file = new Moralis.File(''+rowData.token_id, blob);
    await file.saveIPFS();
    SetFileUrl(file.ipfs());
    setEmailLoading(false);
    toast.current &&
      (toast.current as any).show({
        severity: "success",
        summary: TRANSLATIONS.email_has_been_sent,
        detail: TRANSLATIONS.please_check_your_inbox,
        life: 5000,

      });
  };

  const generateQRCOde = async (rowData: any) => {
    setGeneratingQR(true);
    try {
      const doc = await generatePDF(rowData);
      //  Upload onIPFS
      var blob = doc.output("blob");
      var formData = new FormData();
      formData.append("pdf", blob);
      formData.forEach(async (filedata) => {
        const data = filedata;       
        const file = new Moralis.File(''+rowData.token_id, data);
        await file.saveIPFS();  
        /* for dev
        // setQrData(file.url());
        */
        setQrData(file._ipfs);
        setGeneratingQR(false);
        setShowModal(true);

      });
    } catch (error) {
      console.log(error);
    }
  };

  const downloadCode = () => {
    const canvas: any = document.getElementById("QR");
    if (canvas) {
      const pngUrl = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");
      let downloadLink = document.createElement("a");
      downloadLink.href = pngUrl;
      downloadLink.download = `PoCCQRCode.png`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  };

  const generateActionButtons = (rowData: any) => {
    try {
      return (
        <>
          <Button
            icon={<Icon path={mdiDownload} />}
            className="p-button-rounded p-button-text"
            onClick={() => downloadCertificate(rowData)}
          />
          <div className="certificateButton">
            <Button
              icon={<Icon path={mdiEye} />}
              className="p-button-rounded p-button-text desktop-button"
              onClick={() => generateCertificate(rowData)}
            />

            <Dialog
              id="DialogCertificate"
              visible={showPoCCModal}
              onHide={() => setShowPoCCModal(false)}
              modal={true}
              showHeader={false}
              dismissableMask={true}
              style={{ borderRadius: "25px" }}
              contentStyle={{
                padding: "0",
                borderRadius: "25px",
                overflowX: "hidden",
              }}
              draggable={false}
            >

              {pdfUrl && (
                <div className="PDFContainer ">

                  <Document
                    file={pdfUrl}
                    className="PDFDocument"
                    loading="Loading Certificate..."
                  >
                    <PDFDocumentWrapper>
                      <Page
                        className="PDFPage"
                        pageNumber={1}
                        scale={2}
                        renderAnnotationLayer={false}
                        renderTextLayer={false}
                      />
                    </PDFDocumentWrapper>
                  </Document>

                </div>
              )}

            </Dialog>


          </div>

          <Button
            icon={<Icon path={mdiEmail} />}
            className="p-button-rounded p-button-text"
            onClick={() => emailCertificate(rowData)}
          />
          <a
            href={polygonConfig.Txhash + rowData.transaction_hash}
            target="_blank"
            rel="noreferrer"
          >
            <Button
              icon={<Icon path={mdiPound} />}
              className="p-button-rounded p-button-text"
            />
          </a>
          <Button
            icon={<Icon path={mdiQrcode} />}
            className="p-button-rounded p-button-text"
            onClick={() => generateQRCOde(rowData)}
          />

          <Dialog
            draggable={false}
            visible={showModal}
            header="QR Code"
            onHide={() => setShowModal(false)}
            modal={true}
            footer={
              <div>
                <Button
                  label="Download QR Code"
                  icon="pi pi-download"
                  onClick={() => downloadCode()}
                />
                <Button
                  label="Close"
                  icon="pi pi-times"
                  onClick={() => setShowModal(false)}
                  className="p-button-secondary"
                />
              </div>
            }
          >
            <QRCode
              value={qrData}
              size={350}
              qrStyle="dots"
              eyeRadius={10}
              id={"QR"}
              logoImage={logo}
              logoHeight={80}
              logoWidth={80}
              logoOpacity={1}
              enableCORS={true}
              removeQrCodeBehindLogo={true}
            />
          </Dialog>
        </>
      );
    } catch {
      return "";
    }
  };

 const updatePoCCDetails = async (data: any) => {
    console.log("updating pocc details");
    console.log("lazyParams", lazyParams);
    const pocc = await getPoCCContract();
    const tokenIds = data.map((certificate: any) => certificate.token_id);

    // Get the cached poccDetails data for the current page from localStorage
    const cachedPageKey = `poccDetails_page_${lazyParams.page}`;
    const cachedPageData = localStorage.getItem(cachedPageKey);
    const cachedDetails = cachedPageData ? JSON.parse(cachedPageData) : {};

    let updatedDetails: any = {}; // Object to accumulate the updates
    let needToCache = false; // Flag to track if there are any updates that need to be cached

    for (let i = lazyParams.first; i < lazyParams.first + 10; i++) {
      const tokenId = tokenIds[i];

      if (tokenId) {
        // Check if tokenId is not falsy (not undefined, null, etc.)
        if (
          cachedDetails[tokenId] &&
          cachedDetails[tokenId].isValid &&
          Object.keys(cachedDetails[tokenId]).length > 1
        ) {
          console.log("Getting poccs for tokenId (from cache):", tokenId);
          // Use the cached details from the cached data
          updatedDetails[tokenId] = cachedDetails[tokenId];
        } else {
          console.log("Getting poccs for tokenId:", tokenId);
          const tx = await pocc.poccTokens(tokenId);

          updatedDetails[tokenId] = {
            reason: tx.reason,
            amount: tx.amountCO2 / Math.pow(10, 18),
            owner: tx.owner,
          };

          // Cache the fetched details for this tokenId and mark it as valid
          cachedDetails[tokenId] = {
            ...updatedDetails[tokenId],
            isValid: true,
          };
          needToCache = true;
        }
      }
    }

    // If there are updates that need to be cached, update the cache for the current page
    if (needToCache) {
      localStorage.setItem(cachedPageKey, JSON.stringify(cachedDetails));
    }

    setPoccDetails((prevState: any) => ({
      ...prevState,
      ...updatedDetails,
    }));
  };


  const onPage = (event: any) => {
    setLazyParams(event);
  };

  useEffect(() => {
    updatePoCCDetails(data);
  }, [lazyParams]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    isFetched && data ?  updatePoCCDetails(data) : fetchPocc();
  }, [data]);

  useEffect(() => {
    fileUrl && callEmailCloudFunction();
  }, [fileUrl]);

  return (
    <React.Fragment>
      {emailLoading && <DappLoading />}
      {generatingQR && <DappLoading />}

      <div
        className={`${classes.base_card_pocc} ${classes.fixed_height_body} flex-column w-full`}
      >
          <DataTable
            value={data}
            sortField="createdAt"
            sortOrder={1}
            responsiveLayout="scroll"
            style={{ width: "100%", zIndex: "0" }}
            scrollable
            scrollHeight="flex"
            size="small"
            className={classes.datatable}
            paginator
            first={lazyParams.first}
            rows={10}
            totalRecords={data.length}
            onPage={onPage}
            //  paginator
            //  paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            //  currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            //  rows={10}
            //    rowsPerPageOptions={[10, 20, 50]}
            pageLinkSize={3}
            // onPage={onPageChange}
          >
            <Column
              className={classes.hide_on_small_screen}
              body={generateName}
              header={TRANSLATIONS.name}
            ></Column>
            <Column
              body={generateCo2Amount}
              header={TRANSLATIONS.amount}
            ></Column>
            <Column
              sortable={true}
              body={generateDate}
              field="createdAt"
              header={TRANSLATIONS.date}
            ></Column>
            <Column
              body={generateReason}
              className={classes.hide_on_small_screen}
              header={TRANSLATIONS.reason_for_compensating}
            ></Column>
            <Column
              header={TRANSLATIONS.actions}
              body={generateActionButtons}
            ></Column>
          </DataTable>
      </div>
      <Toast ref={toast} />
    </React.Fragment>
  );
};

export default PoccDataTable;