import { mdiAccountCircleOutline, mdiAccountMultiple, mdiBellRingOutline, mdiBridge, mdiBullhornVariantOutline, mdiCalculatorVariant, mdiCalculatorVariantOutline, mdiChartLine, mdiChevronDown, mdiCogOutline, mdiFileSign, mdiHelpCircleOutline, mdiHomeMinusOutline, mdiLogin, mdiLogout, mdiShieldOutline, mdiSwapHorizontal, mdiTreeOutline } from "@mdi/js";
import { Icon } from "@mdi/react";
import { Dialog } from "primereact/dialog";
import { TieredMenu } from "primereact/tieredmenu";
import { useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useMoralis } from "react-moralis";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import es from "../assets/images/flags/es.svg";
import en from "../assets/images/flags/gb.svg";
import it from "../assets/images/flags/it.svg";
import logo from "../assets/images/logo/Logo-sidebar.png";
import logoWhite from "../assets/images/logo/LogoBaW.png";
import coorestIcon from "../assets/images/tokens/token.png";
import UserLogin from "../components/users/UserLogin";
import { setLocale } from "../i18n/localeSlice";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { RootState } from "../store/store";
import classes from "./../styles/Sidebar.module.scss";
import { useTranslations } from "../i18n/useTranslations"
import { useUser } from "../store/custom-hooks/useUser/useUser";

interface SidebarProps {
  showSidebar: boolean;
  closeSidebar: () => void;
  onAppLocaleChange: (newAppLocale: string) => void;
}
const Sidebar = ({ showSidebar, closeSidebar, onAppLocaleChange }: SidebarProps) => {
  const StyledDialog = styled.div`
    .p-dialog {
      background: var(--background-color) !important;
      border-bottom-right-radius: 30px !important;
      border-bottom-left-radius: 30px !important;
    }
  `;
  const { user } = useMoralis();
  const { logout } = useUser();
  const currentMoralisUser = user;
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector((state: RootState) => state.users.currentUser);
  const [displayLoginDialog, setDisplayLoginDialog] = useState(false);
  const [displayLocaleDialog, setDisplayLocaleDialog] = useState(false);
  const TRANSLATIONS = useTranslations();

  const closeLoginDialog = () => {
    setDisplayLoginDialog(false);
  };

  function CurrentLanguageFlag() {
    const storedLanguage = localStorage.getItem("locale") || "en";
    let image;

    switch (storedLanguage) {
      case "en":
        image = en;
        break;
      case "es":
        image = es;
        break;
        case "it":
        image = it;
        break;
      default:
        image = en;
        break;
    }
    return (
      <div>
        <img className={classes.flag} src={image} alt={storedLanguage} />
      </div>
    );
  }

  const onLanguageChange = (code: string) => {
    setDisplayLocaleDialog(false);
    onAppLocaleChange(code);
    dispatch(setLocale(code));
  };

  /** Profile menu items */
  const menu: any = useRef(null);
  const items = [
    {
      label: TRANSLATIONS.my_profile,
      icon: <Icon path={mdiAccountCircleOutline} style={{ width: "20px", marginRight: "10px" }} />,
      command: (event: any) => {
        navigate("/profile");
        menu.current.toggle(event);
      },
    },
    {
      label: TRANSLATIONS.settings,
      icon: <Icon path={mdiCogOutline} style={{ width: "20px", marginRight: "10px" }} />,

      disabled: true,
    },
    {
      label: TRANSLATIONS.reports,
      icon: <Icon path={mdiBullhornVariantOutline} style={{ width: "20px", marginRight: "10px" }} />,

      disabled: true,
    },
    {
      label: TRANSLATIONS.help,
      icon: <Icon path={mdiHelpCircleOutline} style={{ width: "20px", marginRight: "10px" }} />,

      command: (event: any) => {
        window.open("https://coorest.gitbook.io/coorest-dapp-manual/", "_blank");
        menu.current.toggle(event);
      },
    },
    {
      label: TRANSLATIONS.ccsRegisrty,
      icon: <Icon path={mdiTreeOutline} style={{ width: "20px", marginRight: "10px" }} />,

      command: (event: any) => {
        window.open("https://coorest.gitbook.io/ccs-registry/", "_blank");
        menu.current.toggle(event);
      },
    },
  ];

  /** Dynamic class for active menu */
  const generateClass = (path: string) => {
    return location.pathname === path ? classes.active : classes.deactive;
  };

  return (
    <div className={`${classes.sidebar} ${showSidebar ? classes.show : classes.hide}`}>
      <div className={`${classes.logo}`}>
        <Link to="/">
          <img className={`w-full ${classes.light}`} src={logo} alt="" />
          <img className={`w-full ${classes.dark}`} src={logoWhite} alt="whiteLogo" />
        </Link>
      </div>
      {/* profile in mobile view */}
      <div className={classes.profile}>
        <Link to="/profile">
          <img
            className={classes.avatar}
            src={currentUser.profilePicture ? currentUser.profilePicture.toString() : coorestIcon}
            alt={currentUser.fullName}
          />
        </Link>
        <span>
          <b>
            {/* if user is equal to guest show connect with wallet message */}
            {currentUser.username === "Guest" ? (
              <span className="cursor-pointer" onClick={() => setDisplayLoginDialog(true)}>
                <FormattedMessage id="connect_wallet" />
              </span>
            ) : currentUser.fullName && currentUser.fullName.length > 10 ? (
              currentUser.fullName.substring(0, 10) + "..."
            ) : (
              currentUser.fullName
            )}
          </b>
        </span>
        <span
          onClick={(event) => menu.current.toggle(event)}
          aria-haspopup
          aria-controls="overlay_tmenu"
          className="cursor-pointer"
        >
          <Icon path={mdiChevronDown} size={1.2}></Icon>
        </span>

        <TieredMenu model={items} popup ref={menu} id="overlay_tmenu" />

        <Icon path={mdiBellRingOutline} size={1.1}></Icon>
      </div>

      <div className={`${classes.menu_panel}`}>
        <ul>
          <Link className="ms-1" to="/" onClick={closeSidebar}>
            {" "}
            <li className={`${generateClass("/")}`}>
              <Icon className={classes.menu_icon} path={mdiHomeMinusOutline}></Icon>
              <span className={classes.menu_title}>
                <FormattedMessage id="dashboard" />
              </span>
            </li>{" "}
          </Link>
          {/* <Link to="/exchange" onClick={closeSidebar}>
            <li className={`${generateClass("/exchange")} flex align-items-center`}>
              <Icon className={classes.menu_icon} path={mdiSwapHorizontal}></Icon>
              <span className={classes.menu_title}>
              <FormattedMessage id="exchange" />
              </span>
            </li>
          </Link> */}

          <Link to="/batches" onClick={closeSidebar}>
            <li className={`${generateClass("/batches")} flex align-items-center`}>
              <Icon className={classes.menu_icon} path={mdiTreeOutline}></Icon>
              <span className={classes.menu_title}>NFTrees</span>
            </li>
          </Link>
          <Link to="/certificates" onClick={closeSidebar}>
            <li className={`${generateClass("/certificates")} flex align-items-center`}>
              <Icon className={classes.menu_icon} path={mdiFileSign}></Icon>
              <span className={classes.menu_title}>
                <FormattedMessage id="pocc_certificates" />
              </span>
            </li>
          </Link>
          {/* <Link to="/insurances" onClick={closeSidebar}>
            <li className={`${generateClass("/insurances")} flex align-items-center`}>
              <Icon className={classes.menu_icon} path={mdiShieldOutline}></Icon>
              <span className={classes.menu_title}>
                <FormattedMessage id="insurances" />
              </span>
            </li>
          </Link> */}
          {/* <Link to="/Charts" onClick={closeSidebar}>
            <li
              className={`${generateClass("/Charts")} flex align-items-center`}
            >
              <Icon className={classes.menu_icon} path={mdiChartLine}></Icon>
              <span className={classes.menu_title}>
                <FormattedMessage id="charts" />
              </span>
            </li>
          </Link> */}
          {/* <a href="https://ember-climate.org/data/data-tools/carbon-price-viewer/" target="_blank" rel="noreferrer">
            {" "}
            <li className="flex align-items-center">
              <Icon className={classes.menu_icon} path={mdiChartLine}></Icon>
              <span className={classes.menu_title} style={{ fontWeight: "normal" }}>
                <FormattedMessage id="charts" />
              </span>
            </li>
          </a> */}

          <Link to="/Bridge" onClick={closeSidebar}>
            <li className={`${generateClass("/Bridge")} flex align-items-center`}>
              <Icon className={classes.menu_icon} path={mdiBridge}></Icon>
              <span className={classes.menu_title}>
                <FormattedMessage id="bridge" />
              </span>
            </li>
          </Link>
          <Link to="/Dao" onClick={closeSidebar}>
            <li className={`${generateClass("/Dao")} flex align-items-center`}>
              <Icon className={classes.menu_icon} path={mdiAccountMultiple}></Icon>
              <span className={classes.menu_title}>
                <FormattedMessage id="coorest_dao" />
              </span>
            </li>
          </Link>
          <Link to="/CO2Calculator" onClick={closeSidebar}>
            <li className={`${generateClass("/CO2Calculator")} flex align-items-center`}>
              <Icon className={classes.menu_icon} path={mdiCalculatorVariantOutline}></Icon>
              <span className={classes.menu_title}>
                <FormattedMessage id="co2Calculator" />
              </span>
            </li>
          </Link>
        </ul>
      </div>

      <div className={`${classes.bottom_section} gap-1`}>
        <div className={`${classes.translate} gap-2`}>
          <CurrentLanguageFlag></CurrentLanguageFlag>
          <span onClick={() => setDisplayLocaleDialog(true)}>
            <FormattedMessage id="select_language" />
          </span>
        </div>
        <div className={`${classes.logout}`}>
          <Icon className={classes.menu_icon} path={currentMoralisUser ? mdiLogout : mdiLogin}></Icon>
          {currentMoralisUser ? (
            <span onClick={logout}>
              <FormattedMessage id="logout" />
            </span>
          ) : (
            <span onClick={() => setDisplayLoginDialog(true)}>
              <FormattedMessage id="login" />
            </span>
          )}
        </div>
      </div>

      {/* Selec App Locale Dialog*/}
      {displayLocaleDialog && (
        <StyledDialog>
          <Dialog
            draggable={false}
            header={TRANSLATIONS.select_language}
            visible={displayLocaleDialog}
            style={{ width: "300px" }}
            onHide={() => setDisplayLocaleDialog(false)}
          >
            <div className="flex flex-column gap-2">
              <div className="flex flex-row gap-2 cursor-pointer">
                <div>
                  <img height={"16px"} width={"auto"} src={en} alt="SVG image" />
                </div>
                <span onClick={() => onLanguageChange("en")} key={"en-US"}>
                  English
                </span>
              </div>
              <div className="flex flex-row gap-2 cursor-pointer">
                <div>
                  <img height={"16px"} width={"auto"} src={es} alt="SVG image" />
                </div>
                <span onClick={() => onLanguageChange("es")} key={"es-ES"}>
                  Español
                </span>
              </div>
              <div className="flex flex-row gap-2 cursor-pointer">
                <div>
                  <img height={"16px"} width={"auto"} src={it} alt="SVG image" />
                </div>
                <span onClick={() => onLanguageChange("it")} key={"it"}>
                  Italiano
                </span>
              </div>
            </div>
          </Dialog>
        </StyledDialog>
      )}

      {/* User Login Dialog */}
      {displayLoginDialog && <UserLogin displayLoginDialog={displayLoginDialog} onHide={closeLoginDialog} />}
    </div>
  );
};

export default Sidebar;
