import React from "react";
import classes from "./../../../styles/Dashboard.module.scss";
interface ButtonProps {
  content: React.ReactNode;
  onClick?: () => void;
  style?: React.CSSProperties;
}

const Button: React.FC<ButtonProps> = ({ content, onClick, style }) => {
  const buttonStyle = {
    ...style, // Merge the style prop with default styles
  };

  return (
    <button onClick={onClick} style={buttonStyle} className={classes.dapp_btn}>
      {content}
    </button>
  );
};

export default Button;
