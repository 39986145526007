import React, { useEffect, useState } from "react";
import { useMoralis } from "react-moralis";
import CCO2Available from "../components/pocc/CCO2Available";
import NavbarLong from "../components/common/top-navbar/NavbarLong";
import { useAppSelector } from "../store/hooks";
import { RootState } from "../store/store";
import classes from "../styles/POCC.module.scss";
import { FormattedMessage } from "react-intl";
import "../styles/POCC.module.scss";
import Button from "../components/common/buttons/PrimaryButton";
import { mdiArrowLeft } from "@mdi/js";
import Icon from "@mdi/react";
import PoccDataTable from "../components/pocc/PoccDataTable";
import { Link } from "react-router-dom";
import { useTranslations } from "../i18n/useTranslations"

const POCC = () => {
  const currentUser = useAppSelector(
    (state: RootState) => state.users.currentUser
  );
  const { user } = useMoralis();
  const [data, setData] = useState<any[]>([]);
  const TRANSLATIONS = useTranslations();

  useEffect(() => {
    setData(currentUser.pocc);
  }, [user, currentUser.pocc]);

  return (
    <React.Fragment>
      <div className={`${classes.dashboard} flex w-full flex-column text-left`}>
        <NavbarLong currentAccount={currentUser} title={TRANSLATIONS.pocc_certificates} />
        <div>
          {/* Main Section */}
          <section>
            <div className={`${classes.co2_info} mb-1 w-60`}>

              <Link to="/">
                <Button
                  content={
                    <>
                      <Icon
                        className="text-white mr-2"
                        path={mdiArrowLeft}
                         size={0.7}
                      />
                      <FormattedMessage id="back" />
                    </>
                  }
                  style={{
                    width: "150px",
                  }}
                />
              </Link>

              <CCO2Available />
            </div>
            <PoccDataTable />
          </section>
        </div>
      </div>
    </React.Fragment>
  );
};

export default POCC;
