import React from 'react';
import classes from "../../styles/DaoPage.module.scss";
import ArrowIcon from "../../assets/images/DAO/Arrow.png";

interface LinkWithIconProps {
  target: string;
  rel: string;
  className: string;
  href: string;
  iconSrc?: string;
  altText: string;
  text?: string;
}

const LinkWithIcon: React.FC<LinkWithIconProps> = ({
  target,
  rel,
  className,
  href,
  iconSrc,
  altText,
  text,
}) => {
  return (
    <a target={target} rel={rel} className={className} href={href}>
      <div className={classes.dao_desc}>
        {iconSrc && <img src={iconSrc} alt={altText} />}
        {text && <span>{text}</span>}
        <img src={ArrowIcon} />
      </div>
    </a>
  );
};

export default LinkWithIcon;