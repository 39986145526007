import { LOCALES } from '../constants';

export default {
  [LOCALES.SPANISH]: {
    "absorbing": "Absorbido",
    "actions": "Acciones",
    "add_to_compensation_field": "Añadir al campo de compensación",
    "add_to_metamask": "Añadir a su billetera",
    "address": "Dirección",
    "agree_to_terms_and_conditions": "Acepto los términos y condiciones",
    "agree_to_terms_condition_privacy_policy": "Acepto los Términos y condiciones y la Política de privacidad",
    "amount": "Cantidad",
    "amount_of_compensation": "Cantidad de compensación",
    "amount_shown_in_pocc": "Cantidad mostrada en su certificado PoCC",
    "annual_percentage_return": "Rendimiento porcentual anual",
    "apy": "APY",
    "back": "Volver",
    "batch": "Lote",
    "bundle_lifetime": "Duración del paquete",
    "bundle_lifetime_until": "Paquete de por vida hasta",
    "bundle_name": "Nombre del paquete",
    "burn": "Quema",
    "burn_cco2_to_generate": "Quema CCO2 para generar certificado de PoCC",
    "burned_percentage": "Porcentaje de quemados",
    "burnt": "Quemada",
    "business_name": "Nombre de la compañía (Si aplica)",
    "buy_insurance": "Comprar el seguro",
    "buy_nftrees": "Comprar NFTrees",
    'bridge':'Puente',
    'bridge_eth_polygon':'Puente ETH-Polygon',
    'bridge_eth_q':'Puente ETH-Q',
    'bridge_eth_arbitrium':'Puente ETH-Arbitrium',
    "calculateYourEmission": "Calcula tus emisiones",
    "cancel": "Cancelar",
    "carTravel": "viaje en coche",
    "caspe_spain": "Caspe, España",
    "cco2_available": "CCO2 Disponible para quemar",
    "cco2_burned": "CCO2 quemado (KG) :",
    "cco2_conversion_rate": "(1 CCO2 moneda = 1 KG de CO2)",
    "cco2_tokens": "CCO2 Monedas",
    "ccsRegisrty": "Registro CCS",
    "certificate_id": "Identificación del certificado",
    "certificates": "Certificados",
    "certificates_created": "Certificado creado",
    "charts": "Grafico",
    "check_your_file": "Algo salió mal por favor revisa tu archivo de nuevo",
    "chooseCategory": "Elige la categoría",
    "choose_insurance_period": "Elija el período de seguro",
    "choose_project_id": "Elija la identificación del proyecto",
    "choose_the_nftrees": "Elige los Nftrees :",
    "claim_all": "Reclama todo",
    "claimed": "Reclamada",
    "close": "Cerca",
    "co2Calculator": "Calculadora de CO2",
    "co2_amount": " Cantidad de CO2",
    "coming_soon": "Próximamente",
    "compensate": "Compensar",
    "connect_wallet": "Conectar billetera",
    "connect_your_wallet": "Conecta tu billetera y empieza a usar la Applicación ahora mismo",
    "coorest_dao": "Coorest DAO",
    "coorest_fee": "Tarifa de Coorest",
    "coorest_forum": "Foro de Coorest",
    "copied": "Copiado!",
    "copy_address": "Copiar dirección cco2",
    "dashboard": "Tablero",
    "date": "Fecha",
    "days": "Días",
    "diesel": "Diésel",
    "distance": "Distancia",
    "earned": "Ganar",
    "email": "Dirección de correo",
    "email_has_been_sent": "E-mail ha sido enviado.",
    "end_date": "Fecha final",
    "enter_bundle_name": "Ingrese el nombre de su paquete",
    "enter_the_amount_of_co2": "Entre la cantidad de CO2 que quieres mostrar en tu certificado de PoCC. La calculadora te mostrara cuantas monedas necesitas quemar para tener esa cantidad.",
    "error": "Error",
    'exchange': '',
    "fees_from_claimed_amount": "Se aplica un costo del 8% de la cantidad reclamada",
    "fees_notice": "Por favor note que el costo de 0,2% es por concepto de la creación del certificado PoCC",
    "fig": "Higo",
    "fig_trees": "Arboles de higos",
    "fire": "Fuego",
    "flight": "Vuelo",
    "flood": "Inundación",
    "fuelToCo2": "Combustible a co2",
    "fullname": "Nombre completo",
    "generated": "Generada",
    "gps_location": "Ubicación GPS",
    "guest": "Invitado",
    "help": "Ayuda",
    "hey_welcome": "Hola! Bienvenido",
    "hi": "Hola, ",
    "history": "Historia",
    "how_many_cco2_to_compensate": "Cuantas monedas de CCO2 quieres compensar?",
    "info": "Información",
    "insurance": "Póliza de seguro",
    "insurance_history": "Historial del seguro",
    "insurance_period": "Período de seguro",
    "insurance_premium": "Prima de seguro",
    "insurances": "Póliza de seguros",
    "invest_in_risk_pool": "Invertir en grupo de riesgo",
    "invested_amount": "Cantidad invertida",
    "kg_of_co2_absorbed": "KG de co2 absorbido/Año",
    "largeDieselCar": "Automóvil Grande Diésel",
    "largeHybridCar": "Automóvil Grande Híbrido",
    "largePetrolVan": "Furgoneta Grande de Gasolina",
    "lifetime_accumulated": "Acumulación total (CCO2) :",
    "liters": "Litros",
    "live_statistics": "Estadisticas actuales",
    "location": "Locación",
    "login": "Iniciar sesión",
    "login_with_venly": "Inicie con su billetera de Venly",
    "logout": "Cerrar sesión",
    "longBusinessClassFlight": "Largo Vuelo de Clase Ejecutiva",
    "longEconomyClassFlight": "Largo Vuelo de Clase Económica",
    "longFirstClassFlight": "Largo Vuelo de Primera Clase",
    "lpg": "LPG",
    "max_coverage_duration": "Duración máxima de la cobertura",
    "max_protected_amount": "Importe máximo protegido",
    "mediumDieselCar": "Automóvil Mediano Diésel",
    "mediumHybridCar": "Automóvil Mediano Híbrido",
    "min_coverage_duration": "Duración mínima de la cobertura",
    "min_protected_amount": "Importe mínimo protegido",
    "mixed_forest": "Bosque mixto",
    "my_balance": "Mi balance",
    "my_profile": "Mi Perfil",
    "name": "Nombre",
    "name_on_the_certificate": "Nombre en el certificado",
    "network_error": "Error de red",
    "nftrees_refresh_button": "Haga clic en el botón de actualización para actualizar los números después de transferir su árbol",
    "no_enough_nftrees": "No tienes ningún NFTree para reclamar CO2",
    "no_specific_reason": "Sin razón específica",
    "nothing_to_burn": "Nada para quemar",
    "nothing_to_claim": "Nada por reclamar",
    "numberOfLiters": "El número de litros a calcular",
    "pagination_info": "Mostrar {first} a {last} de {totalRecords}",
    "participate_in_coorest_dao": "Participa en el DAO de Coorest",
    "per_year": "KGs de CO2 por año",
    "pest": "Parásito",
    "petrol": "Gasolina",
    "plant_date": "Fecha de siembra",
    "plant_type": "Tipo de árbol",
    "please_check_your_inbox": "Por favor revise su bandeja de entrada",
    "please_switch_to": "Cambia a",
    "pocc_certificates": "Certificados PoCC",
    "pocc_error": "Ha habido un problema al generar el certificado PoCC. Por favor, inténtelo de nuevo más tarde",
    "pocc_success": "El certificado POCC se generó con éxito. Pueden pasar unos minutos antes de que pueda verlo en su lista de certificados",
    "pomegranate_trees": "Arboles de granados",
    "powered_by": "Energizado por",
    "profile_updated": "Actualizó con éxito los detalles de su perfil",
    "project1": "Proyecto 1",
    "project2": "Proyecto 2",
    "project3": "Proyecto 3",
    "projectId": "Projecto ID",
    "project_id": " ID del proyecto",
    "properties": "Características",
    "quickly_bridge_tokens":"Rápidamente conecta tus tokens de forma intercadena",
    "reason_for_compensating": " Razón para compensar",
    "received": "Recibió",
    "receiver_address": "Dirección del receptor :",
    "reports": "Reportes",
    "risk_category": "Categoría de riesgo",
    "risks_chosen": "Riesgo elegido",
    "save": "Guardar",
    "selectCarType": "Seleccione un tipo de coche",
    "selectFlightClass": "Seleccione una clase de vuelo",
    "selectFuelType": "Seleccionar tipo de combustible",
    "selectOption": "Seleccione una opción",
    "select_language": "Seleccione el idioma",
    "select_nftrees": "Seleccione NFTrees",
    "select_risks": "Seleccionar riesgos",
    "sent": "Enviada",
    "settings": "Configuración",
    "shortBusinessClassFlight": "Corto Vuelo de Clase Ejecutiva",
    "shortEconomyClassFlight": "Corto Vuelo de Clase Económica",
    "sign_the_transaction": "Firma la transacción",
    "sign_transaction_wallet_connect": "Firme la transacción en su WalletConnect",
    "smallDieselCar": "Automóvil Pequeño Diésel",
    "smallPetrolVan": "Furgoneta Pequeña de Gasolina",
    "something_went_wrong": "Algo salió mal. Por favor, inténtelo de nuevo más tarde",
    "spain": "España",
    "staked_crst": "Staked/Invertido CRST",
    'stakeCRST' : 'Stake CRST',
    "start_date": "Fecha de inicio",
    "stats": "Estadísticas",
    "status": "Estado",
    "success": "Éxito",
    "successfully_claimed": "Successfully claimed all the cco2 tokens",
    "summary": "Resumen",
    "term": "Término",
    "theDistanceInKM": "La distancia en KM",
    "to_be_determined": "Ser determinada",
    "token_balance": "Balance de Monedas",
    "token_id": "Identificador de token",
    "top_co2_compensators": "Compensador Número 1 de CO2",
    "top_nftrees_holders": "Titular Número 1 de NFTree",
    "total": "Total",
    "total_co2_absorbed": "Total de CO2 absorbido (KG)",
    "total_co2_compensated": "Total de CO2 compensado (KG)",
    "total_nftrees": "Total NFTrees",
    "transaction_wasnt_successful": "La transacción no fue exitosa. Inténtalo de nuevo",
    "transactions": "Transacciones",
    "transfer": "Transferir",
    "transfer_completed": "Transferencia completada",
    "transfer_your_nftrees": "Transfiera sus NFTrees",
    "twenty_years_ago": "20 años",
    "type": "Selecciona",
    "type_your_name": "Escriba el nombre y/o nombre de la compañía a mostrar en el certificado",
    "unable_to_burn": "No pudo quemar CCo2. Por favor, inténtelo de nuevo más tarde.",
    "unable_to_claim": "Incapaz de reclamar CO2",
    "unclaimed": "Sin reclamar (CCO2) :",
    "update_your_details": "Actualiza tus detalles",
    "vault_tvl": "Bóveda Total valor guardado",
    "what_is_the_reason_for_compensation": "Cual es el motivo de compensación?",
    "yourTotalFootprint": "Tu Huella Total",
    "your_stake": "Tu Inversion",
    'total_staked': 'Total staked',
    'reward_rate': 'Reward Rate',

  },
};
