import { useEffect, useState } from "react";
import { useMoralis, useMoralisWeb3Api } from "react-moralis";
import { useAppSelector } from "../../../store/hooks";
import { RootState } from "../../../store/store";
import { polygonConfig } from "../../../dappConfig";
import {
  CachedData,
  TransactionsResponse,
  TransferMeta,
  useTransferResponse,
} from "./types";
import useLocalStorage from "use-local-storage";
import differenceInSeconds from "date-fns/differenceInSeconds";

export function usePoccTransfer(): useTransferResponse {
  const Web3Api = useMoralisWeb3Api();
  const { user,isAuthenticated } = useMoralis();
  const currentUser = useAppSelector(
    (state: RootState) => state.users.currentUser
  );

  const [data, setData] = useState<TransactionsResponse[]>([]);
  const [meta, setMeta] = useState<TransferMeta>({} as TransferMeta);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [poccTransfers, setPoccTransfers] = useLocalStorage(
    "poccTransfers",
    {} as CachedData
  );

  /** Retrieving the PoCC transfers */
  const fetch = async (): Promise<void> => {
    console.log("!--Started Fetching pocc_transfers from the server--!");
    if (
      //If transfer data exists in locale storage and it's been saved not later than 2 minutes ago
      poccTransfers &&
      differenceInSeconds(Date.now(), poccTransfers.date) < 120
    ) {
      setData(poccTransfers.data);
      setMeta(poccTransfers.meta);
    } else {
      const options = {
        chain: polygonConfig.chainName,
        address: currentUser.ethAddress,
        
      };
      try {
        setLoading(true);
        let poccTransfer: TransactionsResponse[] = [];

        const transactionResult = await Web3Api.account.getNFTTransfers(
          options as any
        );
        //#region : If you want Moralis Query instead of web3 api*/
        // const pocc = Moralis.Object.extend("PolygonNFTTransfers");
        // const sentQuery = new Moralis.Query(pocc);
        // sentQuery.equalTo("from_address", currentUser.ethAddress);
        // const receivedQuery = new Moralis.Query(pocc);
        // receivedQuery.equalTo("to_address", currentUser.ethAddress);
        // const query = Moralis.Query.or(sentQuery, receivedQuery);
        // query.equalTo("token_address", polygonConfig.POCC_TOKEN_ADDRESS);
        // const transactionResult = await query.find();
        //#endregion */
        transactionResult &&
          transactionResult.result !== undefined &&
          (poccTransfer = transactionResult.result
            .filter(
              (tx) => tx.token_address === polygonConfig.POCC_TOKEN_ADDRESS
            )
            .map((transaction: any) => {
              return {
                from: transaction.from_address,
                to: transaction.to_address,
                tokenId: transaction.token_id,
                transactionHash: transaction.transaction_hash,
                date: transaction.block_timestamp,
                tokenAddress: transaction.token_address,
              };
            }));

        const pageDetails: TransferMeta = {
          page: transactionResult.page ?? 0,
          pageSize: transactionResult.page_size,
          total:
            transactionResult.total ?? transactionResult.result?.length ?? 0,
        };
        //#region:  This part should be used for pagination */
        // while ((transactionResult as any).next) {
        //   transactionResult = await (transactionResult as any).next();

        //   transactionResult.result &&
        //     (Co2Transfers = [
        //       ...Co2Transfers,
        //       ...transactionResult.result
        //         .filter((tx) => tx.address === polygonConfig.CO2_TOKEN_ADDRESS)
        //         .map((transaction: any) => {
        //           return {
        //             from: transaction.from_address,
        //             to: transaction.to_address,
        //             transactionHash: transaction.transaction_hash,
        //             date: transaction.block_timestamp,
        //             tokenAddress: transaction.address,
        //             value: transaction.value,
        //           };
        //         }),
        //     ]);
        // }
        //#endregion */

        setData(poccTransfer);
        setPoccTransfers({
          data: poccTransfer,
          meta: pageDetails,
          date: Date.now(),
        });
      } catch (err) {
        console.log(err);
        setError(true);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (!user || !isAuthenticated) return;
    fetch();
  }, [user,isAuthenticated]);

  return { fetch, data, meta, error, loading };
}
