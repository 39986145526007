import {
  mdiBellRingOutline,
  mdiCheckboxBlankCircle,
  mdiChevronDown,
  mdiContentCopy
} from "@mdi/js";
import Icon from "@mdi/react";
import React, { useState } from "react";
import { useMoralis } from "react-moralis";
import { Link } from "react-router-dom";
import coorestIcon from "../../../assets/images/tokens/token.png";
import { User } from "../../../store/custom-hooks/useUser/usersTypes";
import UserLogin from "../../users/UserLogin";
import classes from "./NavbarTop.module.scss";

interface NavbarLongProps {
  currentAccount: User;
  title: string;
}

const NavbarLong = ({ currentAccount, title }: NavbarLongProps) => {
  const Moralis = require("moralis-v1");
  const {user} = useMoralis();
  const currentUser = user;

  const [value, setValue] = useState("");
  const [copyToClipboard, setCopyToClipboard] = useState(false);
  const [displayLoginDialog, setDisplayLoginDialog] = useState(false);

  const closeLoginDialog = () => {
    setDisplayLoginDialog(false);
  };

  const copyEth = () => {
    if (currentUser) {
      const el = document.createElement("textarea");
      el.value = currentAccount.ethAddress;
      el.setAttribute("readonly", "");
      el.style.position = "absolute";
      el.style.left = "-9999px";
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      setCopyToClipboard(true);
      setTimeout(() => {
        setCopyToClipboard(false);
      }, 1000);
    } else {
      setDisplayLoginDialog(true);
    }
  };

  return (
    <React.Fragment>
      <div className={`${classes.navbar} flex justify-content-end mb-4`}>
        <div className="welcome flex flex-column flex-grow-1 w-8">
          <h1>{title}</h1>
          <span>
            Address:{" "}
            {currentAccount.ethAddress &&
              `${currentAccount.ethAddress.slice(
                0,
                8
              )} ... ${currentAccount.ethAddress.slice(34)}`}
            <span onClick={copyEth}>
              <Icon
                path={mdiContentCopy}
                className="cursor-pointer mx-1"
                style={{ width: "20px" }}
              ></Icon>
            </span>{" "}
            <span className={`${!copyToClipboard && "hidden"} text-green-500`}>
              copied!
            </span>
          </span>
        </div>

        <div className={`${classes.profile_section}`}>
          {/* Notification */}
          <div
            className="relative flex pt-2"
            onClick={() => alert("coming soon!")}
          >
            <Icon path={mdiBellRingOutline} size={1.1}></Icon>
            <Icon
              path={mdiCheckboxBlankCircle}
              className={classes.notifications}
            ></Icon>
          </div>

          {/* profile */}
          <Link to="/profile" className="flex justify-content-end">
            <div className={`${classes.profile} flex align-items-center`}>
              <img
                src={
                  currentAccount.profilePicture
                    ? currentAccount.profilePicture.toString()
                    : coorestIcon
                }
                alt="Clau"
                width="45"
                height="45"
                className="mr-2"
                style={{ borderRadius: "50%" }}
              />

              <span>
                <b>
                  {currentAccount.fullName &&
                  currentAccount.fullName.length > 17
                    ? currentAccount.fullName.substring(0, 17) + "..."
                    : currentAccount.fullName}
                </b>
              </span>
              <Icon path={mdiChevronDown} size={1.2}></Icon>
            </div>
          </Link>
        </div>
      </div>
      {displayLoginDialog && (
        <UserLogin
          displayLoginDialog={displayLoginDialog}
          onHide={closeLoginDialog}
        />
      )}
    </React.Fragment>
  );
};

export default NavbarLong;
