import React from "react";
import { useAppSelector } from "../../store/hooks";
import { RootState } from "../../store/store";
import NavbarLong from "../common/top-navbar/NavbarLong";
import classes from "../../styles/nftrees.module.scss";
import tree1 from "../../assets/images/nftree/batch1.gif";
import tree2 from "../../assets/images/nftree/batch2.gif";
import tree3 from "../../assets/images/nftree/batch3.gif";
import { Link, useParams } from "react-router-dom";
import Icon from "@mdi/react";
import Button from "../common/buttons/PrimaryButton";
import { mdiArrowLeft } from "@mdi/js";
import { FormattedMessage, useIntl } from "react-intl";
import { useTranslations } from "../../i18n/useTranslations"

const BatchDetails = () => {
  const currentUser = useAppSelector(
    (state: RootState) => state.users.currentUser
  );

  const TRANSLATIONS = useTranslations();
  const { batchId } = useParams();
  const batches = [
    {
      id: 1,
      title: TRANSLATIONS.batch + 1,
      location: TRANSLATIONS.caspe_spain,
      plantDate: "1-7-2022",
      type: TRANSLATIONS.fig,
      term: TRANSLATIONS.twenty_years_ago,
      absorbtion: "81 KG",
      GPSLocation: "41.2530982, -0.0787654",
      picture: tree1,
    },
    {
      id: 2,
      title: TRANSLATIONS.batch + 2,
      location: TRANSLATIONS.spain,
      plantDate: "Q2 2023",
      type: TRANSLATIONS.mixed_forest,
      term: TRANSLATIONS.twenty_years_ago,
      absorbtion: "250 KG",
      GPSLocation: "41.259254, 0.126324",
      picture: tree2,
    },
    {
      id: 3,
      title: TRANSLATIONS.batch + 3,
      location: TRANSLATIONS.caspe_spain,
      plantDate: "Q3/Q4 2022",
      type: TRANSLATIONS.mixed_forest,
      term: TRANSLATIONS.twenty_years_ago,
      absorbtion: "25 KG",
      GPSLocation: TRANSLATIONS.to_be_determined,
      picture: tree3,
    },
  ];

  return (
    <React.Fragment>
      <div className={`${classes.dashboard} flex w-full flex-column h-full`}>
        <NavbarLong currentAccount={currentUser} title="NFTrees" />
        <div className="w-full">
          <Link to="/batches">
            <Button
              content={
                <>
                  <Icon
                    className="text-white mr-2"
                    path={mdiArrowLeft}
                    size={0.7}
                  />
                  <FormattedMessage id="back" />
                </>
              }
              style={{ width: "150px" }}
            ></Button>
          </Link>

          <section className={`${classes.batch_details}`}>
            <img src={batches[(batchId as any) - 1].picture} alt="" />
            <h1>{batches[(batchId as any) - 1].title}</h1>
            <div className={classes.propertiesBox}>
              <span className={classes.subtitle}>
                <FormattedMessage id="properties" />
              </span>
              <div className={classes.properties}>
                <span className={classes.label}>
                  <FormattedMessage id="location" />
                </span>
                <span className={classes.value}>
                  {batches[(batchId as any) - 1].location}
                </span>
              </div>
              <div className={classes.properties}>
                <span className={classes.label}>
                  <FormattedMessage id="plant_date" />
                </span>
                <span className={classes.value}>
                  {batches[(batchId as any) - 1].plantDate}
                </span>
              </div>
              <div className={classes.properties}>
                <span className={classes.label}>
                  <FormattedMessage id="plant_type" />
                </span>
                <span className={classes.value}>
                  {batches[(batchId as any) - 1].type}
                </span>
              </div>
              <div className={classes.properties}>
                <span className={classes.label}>
                  <FormattedMessage id="term" />
                </span>
                <span className={classes.value}>
                  {batches[(batchId as any) - 1].term}
                </span>
              </div>
              <div className={classes.properties}>
                <span className={classes.label}>
                  <FormattedMessage id="total_nftrees" />
                </span>
                <span className={classes.value}>5000</span>
              </div>
              <div className={classes.properties}>
                <span className={classes.label}>
                  <FormattedMessage id="gps_location" />
                </span>
                <span className={classes.value}>
                  {batches[(batchId as any) - 1].GPSLocation}
                </span>
              </div>
            </div>
            <div className={classes.stats}>
              <span className={classes.subtitle}>
                <FormattedMessage id="stats" />
              </span>
              <div className={classes.properties}>
                <span className={classes.label}>
                  <FormattedMessage id="kg_of_co2_absorbed" />
                </span>
                <span className={classes.value}>
                  {batches[(batchId as any) - 1].absorbtion}
                </span>
              </div>
            </div>
          </section>
        </div>
      </div>
    </React.Fragment>
  );
};

export default BatchDetails;
