// @ts-nocheck

import { ethers } from "ethers";
import POCCABI from "../../../services/abi/poccAbi";
import COORESTABI from "../../../services/abi/coorestAbi";
import NFTREEABI from "../../../services/abi/nftreeAbi";
import CO2ABI from "../../../services/abi/co2Abi";
import { polygonConfig } from "../../../dappConfig";
import { useMoralis } from "react-moralis";
import { Venly } from "@venly/web3-provider";
import { VenlyConnect } from "@venly/connect";
import Web3 from "web3";
const MoralisDep = require("moralis-v1");


class VenlyConnector /*extends MoralisDep?.AbstractWeb3Connector*/ {
  // A name for the connector to reference it easy later on
  type = "Venly";
  account: string | null = null;
  chainId: string | null = null;
  provider: unknown = null;
  venlyConnect = new VenlyConnect("Coorest");

  /**
   * A function to connect to the provider
   * This function should return an EIP1193 provider (which is the case with most wallets)
   * It should also return the account and chainId, if possible
   */
  async activate() {
    console.log(this.venlyConnect);

    const options = {
      clientId: "Coorest",
      environment: polygonConfig.chainName=="polygon"?"production":"staging", //optional, production by default
      signMethod: "POPUP", //optional, REDIRECT by default
      // bearerTokenProvider: () => 'obtained_bearer_token', //optional, default undefined
      //optional: you can set an identity provider to be used when authenticating

      secretType: 'MATIC' //optional, ETHEREUM by default
    };

    const provider = await Venly.createProviderEngine(options as any);
    // await Moralis.enableWeb3();
    const web3 = new Web3(provider);

    console.log("venly provider", web3);
    const accounts = await web3.eth.getAccounts();
    // console.log("venly accounts", accounts);
    const chainId = await web3.eth.getChainId();
    // console.log("venly chainId", chainId);
    this.account = accounts[0]; // accounts[0]
    this.chainId = "0x" + chainId.toString(16); // chainId // Should be in hex format
    this.provider = provider;

    // console.log("account:" + this.account);
    // Call the subscribeToEvents from AbstractWeb3Connector to handle events like accountsChange and chainChange
    // this.subscribeToEvents(this.provider);

    return {
      provider: this.provider,
      chainId: this.chainId,
      account: this.account,
    };
  }
  // Cleanup any references to venly
  async deactivate() {
    // Call the unsubscribeToEvents from AbstractWeb3Connector to handle events like accountsChange and chainChange
    // this.unsubscribeToEvents(this.provider);
    await Venly.connect().logout();
    this.account = null;
    this.chainId = null;
    this.provider = null;
  }
};

export function useContracts() {

  const { Moralis } = useMoralis();
  let config:{};
  let walletType = localStorage.getItem('walletType');

  if (walletType === 'metamask') {
    config =
    {
      provider: 'metamask',
      chainId: parseInt(polygonConfig.chainId),
    }
  }
  else if (walletType === 'venly') {
    config =
    {
      connector: VenlyConnector,
      chainId: parseInt(polygonConfig.chainId),
    }
  } else if (walletType === 'walletConnect') {
    config = 
    {
      provider: "walletconnect",
      chainId: parseInt(polygonConfig.chainId),
    }
  }
  
  const MoralisEthers = Moralis.web3Library;
  // const signer = web3Provider.getSigner();
  const getPoCCContract = async () => {
    let web3Provider ;    
    if (!Moralis.web3){
      web3Provider = await MoralisDep.enableWeb3(config);
   }else{
     web3Provider= Moralis.web3;
   }

    const signer = web3Provider.getSigner();
    let contractAddress = polygonConfig.POCC_TOKEN_ADDRESS;
    let contract = new MoralisEthers.Contract(contractAddress, POCCABI, signer);
    return contract;
  };

  const getCoorestContract = async () => {
    let web3Provider ;  
    if (!Moralis.web3){
       web3Provider = await MoralisDep.enableWeb3(config);
    }else{
      web3Provider= Moralis.web3;
    }

    const signer = web3Provider.getSigner();

    let contractAddress = polygonConfig.CMP_TOKEN_ADDRESS;
    let contract = new MoralisEthers.Contract(contractAddress, COORESTABI, signer);
    return contract;
  };

  const getNFTreeContract = async () => {
    let web3Provider ;    
    if (!Moralis.web3){
      web3Provider = await MoralisDep.enableWeb3(config);
   }else{
     web3Provider= Moralis.web3;
   }

    const signer = web3Provider.getSigner();
    let contractAddress = polygonConfig.TREE_TOKEN_ADDRESS;
    let contract = new MoralisEthers.Contract(contractAddress, NFTREEABI, signer);
    return contract;
  };

  const getCO2Contract = async () => {
    let web3Provider ;    
    if (!Moralis.web3){
      web3Provider = await MoralisDep.enableWeb3(config);
   }else{
     web3Provider= Moralis.web3;
   }

    const signer = web3Provider.getSigner();
    let contractAddress = polygonConfig.CO2_TOKEN_ADDRESS;
    let contract = new MoralisEthers.Contract(contractAddress, CO2ABI, signer);
    return contract;
  }

  return { getPoCCContract, getCoorestContract, getNFTreeContract, getCO2Contract };
}
