import { useState } from "react";
import EmissionCalculator from "../components/co2-calculator/EmissionsCalculator";
import TotalFootprintCard from "../components/co2-calculator/TotalFootprintCard";
import ProfilePanel from "../components/common/profile-panel/ProfilePanel";
import NavbarLong from "../components/common/top-navbar/NavbarLong";
import { useAppSelector } from "../store/hooks";
import { RootState } from "../store/store";
import classes from "../styles/CO2Calculator.module.scss";
import { useTranslations } from "../i18n/useTranslations"

export interface CO2CalculatorProps {
  onThemeChange: (newTheme: string) => void;
}
const CO2Calculator = (props: CO2CalculatorProps) => {
  const currentUser = useAppSelector(
    (state: RootState) => state.users.currentUser
  );

  const TRANSLATIONS = useTranslations();

  const [totalFootprint, setTotalFootprint] = useState(0);

  return (
    <>
      <div className={`${classes.section} flex flex-column h-full text-left`}>
        <div className={classes.section}>
          <NavbarLong
            currentAccount={currentUser}
            title={TRANSLATIONS.co2Calculator}
          />
          <ProfilePanel
            onThemeChange={(theme) => props.onThemeChange(theme)}
          ></ProfilePanel>
        </div>

        {/* <div className={`flex flex-column w-full p-3 `}> */}
        <EmissionCalculator setTotalFootprint={setTotalFootprint} />
        <TotalFootprintCard
          totalFootprint={totalFootprint}
        />
      </div>
    </>
  );
};
export default CO2Calculator;
