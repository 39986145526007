import React, { useEffect, useState } from "react";
import classes from "../../styles/Dashboard.module.scss";
import { useAppSelector } from "../../store/hooks";
import { mdiCloudOutline, mdiCrownOutline, mdiMedalOutline, mdiPartyPopper, mdiTreeOutline } from "@mdi/js";
import Icon from "@mdi/react";
import { FormattedMessage, useIntl } from "react-intl";
import { RootState } from "../../store/store";
import { ScrollPanel } from "primereact/scrollpanel";
import coorestIcon from "../../assets/images/tokens/token.png";
import { useMoralisWeb3Api } from "react-moralis";

const TopCO2Compensators = ({ setTotalCO2Compensated }: any) => {

  const Moralis = require("moralis-v1");
  const [topCompensators, setTopCompensators] = useState<any[]>([]);

  const getTopCompensators = async () => {
    try {
      const topCompensatorsResult = await Moralis.Cloud.run("topCompensators");
      let topCompensators = topCompensatorsResult[0].topCompensators;
      let totalCO2burned = parseFloat(topCompensatorsResult[0].totalCompensation) / Math.pow(10, 18);
      setTopCompensators(topCompensators.slice(0, 10));
      sessionStorage.setItem(
        "topCompensators",
        JSON.stringify(topCompensators.slice(0, 10))
      );
      setTotalCO2Compensated(totalCO2burned.toFixed(2));
      sessionStorage.setItem(
        "totalCO2Compensated",
        JSON.stringify(totalCO2burned.toFixed(2))
      );
      const currentTimestamp = Date.now();
      sessionStorage.setItem("lastFetchTopCompensatorsTimestamp", currentTimestamp.toString());
    }
    catch (err) {
      console.log(err);
    }

  };
  const generateName = (user: any) => {
    if (user) {
      return user.fullName
        ? user.fullName.length > 13
          ? user.fullName.substring(0, 10) + "..."
          : user.fullName
        : "****"
    } else {
      return "****";
    }
  };

  const onStart = async () => {
    const currentTimestamp = Date.now();
    // get lastFetchTimestamp from sessionStorage
    const lastFetchTopCompensatorsTimestamp = sessionStorage.getItem("lastFetchTopCompensatorsTimestamp");

    if (lastFetchTopCompensatorsTimestamp !== null && currentTimestamp - Number(lastFetchTopCompensatorsTimestamp) < 60 * 60 * 1000) {

      const topCompensatorsString = sessionStorage.getItem("topCompensators");
      if (topCompensatorsString && JSON.parse(topCompensatorsString).length > 0) {
        setTopCompensators(JSON.parse(topCompensatorsString));
      }
    } else {
      await Promise.all([getTopCompensators()]);

    }
  }

  useEffect(() => {
    onStart();
  }, []);

  return (
    <>

      <div className={classes.leaderboard}>
        <div className="title mt-4 flex">
          <strong><FormattedMessage
            id="top_co2_compensators"
          /></strong>
        </div>
        <ScrollPanel className={classes.PanelBoard}>
          {topCompensators.map((user: any, index) => {
            // const userDetails = user.tokens[0].user[0];
            let totalAbsorbedByUser = (parseFloat(user.compensation) / Math.pow(10, 18));

            return (
              <div
                className={`${classes.record} record flex align-items-center`}
                key={index}
              >
                <span>{index + 1}</span>
                <div className={classes.details}>
                  <img
                    src={
                      user && user.profilepicture
                        ? user.profilepicture
                        : coorestIcon
                    }
                    alt="avatar"
                    className="mr-1"
                  />
                  <Icon path={mdiCrownOutline} size={1}></Icon>
                  <div className="name ml-2">{generateName(user)}</div>
                  <div className="score ml-auto mr-1">
                    {Math.ceil(totalAbsorbedByUser)} KGs
                  </div>
                  <Icon path={mdiCloudOutline}></Icon>
                </div>
              </div>
            );
          })}
        </ScrollPanel>
      </div>
    </>
  );
};

export default TopCO2Compensators;