// @ts-nocheck
import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Tooltip } from "primereact/tooltip";
import metamaskIcon from "../../assets/images/wallets/metamask.png";
import venlyIcon from "../../assets/images/wallets/venlylogo.png";
import WalletConnectIcon from "../../assets/images/wallets/walletconnect.svg";
import classes from "./UserLogin.module.scss";
import { useMoralis } from "react-moralis";
import { Venly } from "@venly/web3-provider";
import { ethers } from "ethers";
import Web3 from "web3";
import { VenlyConnect } from "@venly/connect";
import logo from "../../assets/images/logo/Logo-sidebar.png";
import { useAppDispatch } from "../../store/hooks";
import { polygonConfig } from "../../dappConfig";
import useLocalStorage from "use-local-storage";
import { FormattedMessage } from "react-intl";

interface UserLoginProps {
  displayLoginDialog: boolean;
  onHide: () => void;
}

const UserLogin = ({ displayLoginDialog, onHide }: UserLoginProps) => {
  const Moralis = require("moralis-v1");
  const ethereum = window.ethereum;
  const [web3Enable, setWeb3Enable] = useState(true);
  const { authenticate, enableWeb3  } = useMoralis();
  const [message, setMessage] = useState<string | null>(null);
  const [chainId, setChainId] = useState<number | null>(null);
  const [authError, setAuthError] = useState<null | Error>(null);
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const chainIds: Record<number, string> = {
    1: "Ethereum Mainnet",
    4: "Ethereum Rinkeby",
    97: "Binance Smart Chain Testnet",
    137: "Polygon Mainnet",
    80001: "Polygon Mumbai Testnet",
  };
  const dispatch = useAppDispatch();
  const [walletType, setWalletType] = useLocalStorage("walletType", "");

  class VenlyConnector extends Moralis?.AbstractWeb3Connector {
    // A name for the connector to reference it easy later on
    type = "Venly";
    account: string | null = null;
    chainId: string | null = null;
    provider: unknown = null;

    /**
     * A function to connect to the provider
     * This function should return an EIP1193 provider (which is the case with most wallets)
     * It should also return the account and chainId, if possible
     */
    async activate() {
      const options = {
        clientId: "Coorest",
        environment: polygonConfig.chainName == "polygon" ? "production" : "staging", //optional, production by default
        signMethod: "POPUP", //optional, REDIRECT by default
        // bearerTokenProvider: () => 'obtained_bearer_token', //optional, default undefined
        //optional: you can set an identity provider to be used when authenticating
        // authenticationOptions: {
        //     // idpHint: 'google'
        // },
        secretType: "MATIC", //optional, ETHEREUM by default
      };

      const provider = await Venly.createProviderEngine(options as any);
      // await Moralis.enableWeb3();

      const web3 = new Web3(provider);
      const accounts = await web3.eth.getAccounts();
      const chainId = await web3.eth.getChainId();
      this.account = accounts[0]; // accounts[0]
      this.chainId = "0x" + chainId.toString(16); // chainId // Should be in hex format
      this.provider = provider;

      // Call the subscribeToEvents from AbstractWeb3Connector to handle events like accountsChange and chainChange
      // this.subscribeToEvents(this.provider);

      return {
        provider: this.provider,
        chainId: this.chainId,
        account: this.account,
      };
    }
    // Cleanup any references to venly
    async deactivate() {
      // Call the unsubscribeToEvents from AbstractWeb3Connector to handle events like accountsChange and chainChange
      // this.unsubscribeToEvents(this.provider);
      await Venly.connect().logout();
      this.account = null;
      this.chainId = null;
      this.provider = null;
    }
  }
  const authenticateMM = async () => {
    let prov = await authenticate({
      provider: "metamask",
      chainId: parseInt(polygonConfig.chainId),
      signingMessage: "Auth required to access the coorest Dapp",
    });
    if (prov != null) {
      localStorage.setItem("walletType", "metamask");
    }
    onHide();
  };
  const loginWithMetamask = async () => {
    let chainId = await getNetwork();
    let login = await setLoginMessage(chainId);
    if (login) {
      if (typeof window.ethereum !== "undefined") {
        const accounts = await window.ethereum.request({ method: "eth_requestAccounts" });
        console.log(accounts);
      }
      authenticateMM();
    }
  };
  const switchNetwork = async () => {
    if (window.ethereum) {
      try {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: polygonConfig.chainId }],
        });
        loginWithMetamask();
      } catch (error) {
        console.error(error);
      }
    }
  };
  const loginWithWalletconnect = async () => {
    onHide();
    try {
      let prov = await authenticate({
        provider: "walletConnect",
        chainId: parseInt(polygonConfig.chainId),
        signingMessage: "Auth required to access the coorest Dapp",
      });
      if (prov != null) {
        localStorage.setItem("walletType", "walletConnect");
      }

      console.log("login prov", prov);
    } catch (e) {
      console.error(e);
    }
  };

  const loginWithVenly = async () => {
    onHide();
    try {
      let prov = await authenticate({
        connector: VenlyConnector,
        chainId: parseInt(polygonConfig.chainId),
        signingMessage: "Log in to coorest Dapp using Venly",
      });
      if (prov != null) {
        localStorage.setItem("walletType", "venly");
      }
    } catch (err) {
      console.log("FAILED");
      console.log(err);
    }
  };

  const getNetwork = async () => {
    const web3 = new Web3(window.ethereum);
    const chainId = await web3.eth.net.getId();
    setChainId(chainId);
    return chainId;
  };

  const setLoginMessage = async (chainID: any) => {
    if (polygonConfig.chainName === "mumbai") {
      if (chainID && chainID != 80001) {
        const message =
          chainIds[chainID] !== undefined
            ? `You are using "${chainIds[chainID]}" on Metamask. Please make sure you are connected to "Mumbai testnet"`
            : `You need to change your network to "Mumbai Testnet".`;
        setMessage(message);
        return false;
      } else {
        setMessage(null);
        return true;
      }
    } else if (polygonConfig.chainName === "polygon") {
      if (chainID && chainID != 137) {
        const message =
          chainIds[chainID] !== undefined
            ? `You are using "${chainIds[chainID]}" on Metamask. Please make sure you are connected to "Polygon Mainnet"`
            : `You need to change your network to "Polygon Mainnet".`;
        setMessage(message);
        return false;
      } else {
        setMessage(null);
        return true;
      }
    }
  };

  useEffect(() => {
    if (localStorage.getItem("walletType") === "metamask") {
      if (!ethereum || !ethereum.on) {
        setWeb3Enable(false);
      } else {
        setWeb3Enable(true);
        getNetwork();
      }
    }
  }, []);

  useEffect(() => {
    async function listenMMNetwork() {
      window.ethereum.on("chainChanged", function (networkId: any) {
        setChainId(networkId);
        setLoginMessage(networkId);
      });
    }
    listenMMNetwork();
  }, []);

  // useEffect(() => {
  //   if (polygonConfig.chainName === "mumbai") {
  //     if (chainId && chainId != 80001) {
  //       const message =
  //         chainIds[chainId] !== undefined
  //           ? `You are using "${chainIds[chainId]}". Please make sure you are connected to "Mumbai testnet"`
  //           : `You need to change your network to "Mumbai Testnet".`;
  //       setMessage(message);

  //     } else {
  //       setMessage(null);
  //     }
  //   } else if (polygonConfig.chainName === "polygon"){
  //     if (chainId && chainId != 137) {
  //       const message =
  //         chainIds[chainId] !== undefined
  //           ? `You are using "${chainIds[chainId]}". Please make sure you are connected to "Polygon Mainnet"`
  //           : `You need to change your network to "Polygon Mainnet".`;
  //       setMessage(message);
  //     } else {
  //       setMessage(null);
  //     }
  //   }
  // }, [chainId]);

  return (
    <React.Fragment>
      <Dialog draggable={false} visible={displayLoginDialog} className={classes.login_dialog} onHide={onHide}>
        <div className={classes.dialog_logo}>
          <img src={logo} alt="logo" />
          <h3 className="font-weight-bold">
            <FormattedMessage id="hey_welcome"></FormattedMessage>
          </h3>
          <p>
            <FormattedMessage id="connect_your_wallet"></FormattedMessage>
          </p>
        </div>

        <div className={classes.body}>
          {message && (
            <>
              <div className="w-full bg-red-100 p-2 rounded">{message}</div>
              <div className="flex flex-column md:flex-row justify-content-end m-1">
                <button
                  // icon={<Icon path={mdiShuffle} />}
                  onClick={switchNetwork}
                  className={`${classes.secondary_btn}`}
                >
                  Switch to {polygonConfig.chainName}
                </button>
              </div>
            </>
          )}
          {web3Enable ? (
            <div className={`${classes.wallet_section}`} onClick={loginWithMetamask}>
              <span className={`${classes.wallet_title}`}>MetaMask</span>
              <img className={`${classes.logo}`} src={metamaskIcon} alt="Login with metamask" />
            </div>
          ) : (
            <a href="https://metamask.io/" target="_blank" rel="noreferrer">
              <div className={`${classes.wallet_section}`}>
                <Tooltip target=".metamask" />
                <span className={`${classes.wallet_title}`}>Download Metamask</span>
                <img className={`${classes.logo}`} src={metamaskIcon} alt="Download Metamask" />
              </div>
            </a>
          )}
          <div className={`${classes.wallet_section}`} onClick={loginWithVenly}>
            <span className={`${classes.wallet_title}`}>Venly wallet</span>
            <img className={`${classes.logo}`} src={venlyIcon} alt="Login with venly wallet" />
          </div>
          <div className={`${classes.wallet_section}`} onClick={loginWithWalletconnect}>
            <span className={`${classes.wallet_title}`}>Walletconnect</span>
            <img className={`${classes.logo}`} src={WalletConnectIcon} alt="Login with Walletconnect" />
          </div>
        </div>
      </Dialog>
    </React.Fragment>
  );
};

export default UserLogin;