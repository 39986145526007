import { Message } from "primereact/message";
import React, { useEffect, useState } from "react";
import { useMoralis } from "react-moralis";
import { Outlet } from "react-router";
import { useLocation, useNavigate } from "react-router-dom";
import Web3 from "web3";
import { polygonConfig } from "../dappConfig";
import Sidebar from "../pages/Sidebar";
import { useTotalCompensation } from "../store/custom-hooks/useCco2/useTotalCompensation";
import { useFetchNftrees } from "../store/custom-hooks/useNFTree/useFetchNftrees";
import { useFetchPocc } from "../store/custom-hooks/usePocc/useFetchPocc";
import { useTokensBalance } from "../store/custom-hooks/useTokensBalance/useTokensBalance";
import { login, userLogout } from "../store/custom-hooks/useUser/usersSlice";
import { useAppDispatch } from "../store/hooks";
import classes from "./Dashboard.module.scss";
import HamburgerMenu from "./HamburgerMenu";
import { useUser } from "../store/custom-hooks/useUser/useUser";
export interface DashboardLayoutProps {
  onAppLocaleChange: (newAppLocale: string) => void;
}
const DashboardLayout = (props: DashboardLayoutProps) => {
  const { user, isAuthenticated } = useMoralis();
  const { logout } = useUser();
  const { data : poccData, isFetched : poccFetched} = useFetchPocc();
  const { tokens, isFetched: tBalanceFetched } = useTokensBalance();
  const { nftrees: nftreesData, isFetched: nftreesFetched, claimableCo2 } = useFetchNftrees();
  const { data: totalCompensation, fetched: compensationFetched } = useTotalCompensation();
  const ethereum = window.ethereum;
  const [showSidebar, setShowSidebar] = useState(false);
  const location = useLocation();
  const [message, setMessage] = useState<string | null>(null);
  const [chainId, setChainId] = useState<number | null>(null);
  const chainIds: Record<number, string> = {
    1: "Ethereum Mainnet",
    4: "Ethereum Rinkeby",
    97: "Binance Smart Chain Testnet",
    137: "Polygon Mainnet",
    80001: "Polygon Mumbai Testnet",
  };
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const setUserDetails = async () => {
    //const claimable = await fetchClaimableCo2(nftreesData?.result);
    const cu = {
      username: user?.attributes.username,
      fullName: user?.attributes.fullName,
      accounts: user?.attributes.accounts,
      ethAddress: user?.attributes.ethAddress,
      email: user?.attributes.email,
      company: user?.attributes.company,
      profilePicture: user?.attributes.profilePicture ? user?.attributes.profilePicture._url : null,
      co2Balance: tokens.co2,
      crstBalance: tokens.crst,
      usdcBalance: tokens.usdc,
      claimableCo2: claimableCo2?? 0,
      NFTrees: nftreesData?.result? nftreesData.result : [],
      totalAbsorbtion: nftreesData?.totalAbsorbtion ? nftreesData.totalAbsorbtion : 0,
      pocc: poccData?? [],
      totalNFTrees: nftreesData?.total? nftreesData.total : 0,
      totalCompensation: totalCompensation ? totalCompensation.toString().substring(0, 10) : 0,
    };
    console.log("CU from Dashboard Layout : ",cu);
    
    dispatch(login(cu));
  };

  const $userLogout = () => {
    setMessage(null);
    logout();
  };

  useEffect(() => {
    console.log("poccFetched" + poccFetched +" tBalanceFetched "+ tBalanceFetched + "nftreesFetched" + nftreesFetched + "compensationFetched"+ compensationFetched);
    if (user  && tBalanceFetched && nftreesFetched && compensationFetched) {
      setUserDetails();
      console.log("setting user details");
    } else {
     // dispatch(userLogout());
     userLogout();
    }
  }, [user, tBalanceFetched, nftreesFetched, compensationFetched,poccFetched]);

  const getNetwork = async () => {
    const web3 = new Web3(window.ethereum);
    const chainId = await web3.eth.net.getId();
    setChainId(chainId);
  };

  useEffect(() => {
    if (ethereum && ethereum.on) {
      getNetwork();
    }
  }, []);

  useEffect(() => {
    async function listenMMNetwork() {
      window.ethereum.on("chainChanged", function (networkId: any) {
        setChainId(networkId);
      });
    }
    listenMMNetwork();
  }, []);

  useEffect(() => {
    if (localStorage.getItem("walletType") === "metamask") {
      // console.log('message in useeffect');

      if (polygonConfig.chainName === "mumbai") {
        if (chainId && chainId != 80001) {
          const message =
            chainIds[chainId] !== undefined
              ? `You are using "${chainIds[chainId]}" on Metamask. Please make sure you are connected to "Mumbai testnet"`
              : `You need to change your network to "Mumbai Testnet".`;
          setMessage(message);
        } else {
          setMessage(null);
        }
      } else if (polygonConfig.chainName === "polygon") {
        if (chainId && chainId != 137) {
          const message =
            chainIds[chainId] !== undefined
              ? `You are using "${chainIds[chainId]}" on Metamask. Please make sure you are connected to "Polygon Mainnet"`
              : `You need to change your network to "Polygon Mainnet".`;
          setMessage(message);
        } else {
          setMessage(null);
        }
      }
    }
  }, [chainId]);

  useEffect(() => {
    // console.log(polygonConfig.chainName);
    async function listenMMAccount() {
      window.ethereum.on("accountsChanged", async (account: string) => {
        // dispatch(updateEthaddress(account));
        $userLogout();
      });
    }
    listenMMAccount();
  }, []);

  useEffect(() => {
    setShowSidebar(false);
  }, [location]);
  return (
    <React.Fragment>
      <div className={`${classes.wrapper_dark} text-center`}>
        {isAuthenticated && message && <Message className="mb-3" severity="error" text={message}></Message>}
        {/* Hamburger Menu */}
        {/*         <div className={classes.hamburger_menu}>
          <span onClick={() => setShowSidebar(true)}>
            <Icon path={mdiMenu} size={1.5}></Icon>
          </span>
          <Link to="/">
            <img src={logo} alt="Coorest Dapp" />
          </Link>
        </div> */}
        <div className={classes.layout_container}>
          <HamburgerMenu
           onAppLocaleChange={props.onAppLocaleChange}
          />
          {/* Navigation sidebar */}
          <Sidebar
            closeSidebar={() => setShowSidebar(false)}
            showSidebar={showSidebar}
            onAppLocaleChange={props.onAppLocaleChange}
          />
          {/* Main Part */}
          <main className={classes.main}>
            <Outlet />
          </main>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DashboardLayout;
