import { LOCALES } from '../constants';

export default {
  [LOCALES.ENGLISH]: {
    'absorbing': 'Absorbing',
    'actions': 'Actions',
    'add_to_compensation_field': 'Add to compensation field',
    'add_to_metamask': 'Add to Metamask',
    'address': 'Address ',
    'agree_to_terms_and_conditions': 'I agree to the Terms & Conditions',
    'agree_to_terms_condition_privacy_policy': 'I agree to the Terms & Conditions and Privacy Policy',
    'amount': 'Amount',
    'amount_of_compensation': 'Amount of compensation',
    'amount_shown_in_pocc': 'Amount shown in PoCC',
    'annual_percentage_return': 'Annual percentage return',
    'apy': 'APY',
    'back': 'Back',
    'batch': 'Batch',
    'bundle_lifetime': 'Bundle lifetime',
    'bundle_lifetime_until': 'Bundle lifetime until',
    'bundle_name': 'Bundle name',
    'burn': 'Burn',
    'burn_cco2_to_generate': 'Burn CCO2 to generate PoCC Certificate',
    'burned_percentage': 'Burned percentage',
    'burnt': 'Burnt',
    'business_name': 'Business Name (if applicable)',
    'buy_insurance': 'Buy Insurance',
    'buy_nftrees': 'Buy NFTrees',
    'bridge':'Bridge',
    'bridge_eth_polygon':'ETH-Polygon bridge',
    'bridge_eth_q':'ETH-Q bridge',
    'bridge_eth_arbitrium':'ETH-Arbitrium bridge',
    'calculateYourEmission': 'Calculate your emissions',
    'cancel': 'Cancel',
    'carTravel': 'Car Travel',
    'caspe_spain': 'Caspe, Spain',
    'cco2_available': 'CCO2 Available for burning',
    'cco2_burned': 'CCO2 burned (KG) :',
    'cco2_conversion_rate': '( 1 CCO2 token = 1 KG of CO2)',
    'cco2_tokens': 'CCO2 Tokens',
    'ccsRegisrty': 'CCS Registry',
    'certificate_id': 'Certificate ID',
    'certificates': 'Certificates',
    'certificates_created': 'Certificates created',
    'charts': 'Charts',
    'check_your_file': 'Something went wrong please check your file again',
    'chooseCategory': 'Choose Category',
    'choose_insurance_period': 'Choose insurance period',
    'choose_project_id': 'Choose project ID',
    'choose_the_nftrees': 'Choose the NFTrees :',
    'claim_all': 'Claim all',
    'claimed': 'Claimed',
    'close': 'Close',
    'co2Calculator': 'CO2 Calculator',
    'co2_amount': ' CO2 Amount',
    'coming_soon': 'Coming soon',
    'compensate': 'Compensate',
    'connect_wallet': 'Connect wallet',
    'connect_your_wallet': 'Connect your wallet and start using the DApp right away',
    'coorest_dao': 'Coorest DAO',
    'coorest_fee': 'Coorest fee',
    'coorest_forum': 'Coorest forum',
    'copied': 'Copied!',
    'copy_address': 'Copy address',
    'dashboard': 'Dashboard',
    'date': 'Date',
    'days': 'Days',
    'diesel': 'Diesel',
    'distance': 'Distance',
    'earned': 'Earned',
    'email': 'Email',
    'email_has_been_sent': 'Email has been sent.',
    'end_date': 'End date',
    'enter_bundle_name': 'Enter bundle name',
    'enter_the_amount_of_co2': 'Enter the amount of CO2 you want to be shown in your PoCC certificate. The calculator will show you how many tokens you should burn to have that amount.',
    'error': 'Error',
    'exchange': 'Exchange',
    'fees_from_claimed_amount': 'We are applying an 8% fee from the claimed amount',
    'fees_notice': 'Please notice that a fee of 0,2% percent is taken for the creation of the PoCC',
    'fig': 'Fig',
    'fig_trees': 'Fig trees',
    'fire': 'Fire',
    'flight': 'Flight',
    'flood': 'Flood',
    'fuelToCo2': 'Fuel to co2',
    'fullname': 'Full Name ',
    'generated': 'Generated',
    'gps_location': 'GPS Location',
    'guest': 'Guest',
    'help': 'Help',
    'hey_welcome': 'Hey! Welcome',
    'hi': 'Hi, ',
    'history': 'History',
    'how_many_cco2_to_compensate': 'How many CCO2 tokens do you want to compensate?',
    'info': 'Informaci\u00f3n',
    'insurance': 'Insurance',
    'insurance_history': 'Insurance History',
    'insurance_period': 'Insurance period',
    'insurance_premium': 'Insurance Premium',
    'insurances': 'Insurances',
    'invest_in_risk_pool': 'Invest in risk pool',
    'invested_amount': 'Invested amount',
    'kg_of_co2_absorbed': 'KG of CO2 absorbed /year',
    'largeDieselCar': 'Large Diesel Car',
    'largeHybridCar': 'Large Hybrid Car',
    'largePetrolVan': 'Large Petrol Van',
    'lifetime_accumulated': 'Lifetime accumulated (CCO2) :',
    'liters': 'Liters',
    'live_statistics': 'Live statistics',
    'location': 'Location',
    'login': 'Login',
    'login_with_venly': 'Login with Venly',
    'logout': 'Logout',
    'longBusinessClassFlight': 'Long Business Class Flight',
    'longEconomyClassFlight': 'Long Economy Class Flight',
    'longFirstClassFlight': 'Long First Class Flight',
    'lpg': 'LPG',
    'max_coverage_duration': 'Max coverage duration',
    'max_protected_amount': 'Maximum protected amount',
    'mediumDieselCar': 'Medium Diesel Car',
    'mediumHybridCar': 'Medium Hybrid Car',
    'min_coverage_duration': 'Minimum coverage duration',
    'min_protected_amount': 'Minimum protected amount',
    'mixed_forest': 'Mixed forest',
    'my_balance': 'Your stake',
    'my_profile': 'My profile',
    'name': 'Name',
    'name_on_the_certificate': 'Name on the certificate',
    'network_error': 'Network Error',
    'nftrees_refresh_button': 'Click the refresh button to update the numbers after you transferred your tree.',
    'no_enough_nftrees': 'You dont have any NFTree to claim CO2',
    'no_specific_reason': 'No specific reason',
    'nothing_to_burn': 'Nothing to burn',
    'nothing_to_claim': 'Nothing to claim',
    'numberOfLiters': 'The number of liters to calculate from',
    'pagination_info': 'Showing {first} to {last} of {totalRecords}',
    'participate_in_coorest_dao': 'Participate in the Coorest DAO',
    'per_year': 'KGs of CO2 per year',
    'pest': 'Pest',
    'petrol': 'Petrol',
    'plant_date': 'Plant date',
    'please_check_your_inbox': 'Please check your inbox',
    'please_switch_to': 'Please switch to',
    'pocc_certificates': 'PoCC Certificates',
    'pocc_error': 'There has been a problem in generating PoCC certificate. please try again later',
    'pocc_success': 'PoCC Certificate is successfully generated. It might take a few minutes before you can see it in your certificates list',
    'pomegranate_trees': 'Pomegranate trees',
    'powered_by': 'Powered by',
    'profile_updated': 'Successfully updated your profile details',
    'project1': 'Project 1',
    'project2': 'Project 2',
    'project3': 'Project 3',
    'projectId': 'Project ID',
    'project_id': 'Project ID',
    'properties': 'Properties',
    'quickly_bridge_tokens':'Quickly bridge your tokens cross-chain',
    'reason_for_compensating': ' Reason for compensating',
    'received': 'Received',
    'receiver_address': 'Receiver address :',
    'reports': 'Reports',
    'risk_category': 'Risk category',
    'risks_chosen': 'Risks chosen',
    'save': 'Save',
    'selectCarType': 'Select a car type',
    'selectFlightClass': 'Select A Flight Class',
    'selectFuelType': 'Select fuel type',
    'selectOption': 'Select an option',
    'select_language': 'Select language',
    'select_nftrees': 'Select NFTrees',
    'select_risks': 'Select risks',
    'sent': 'Sent',
    'settings': 'Settings',
    'shortBusinessClassFlight': 'Short Business Class Flight',
    'shortEconomyClassFlight': 'Short Economy Class Flight',
    'sign_the_transaction': 'Sign the transaction',
    'sign_transaction_wallet_connect': 'Please sign the transaction on you walletConnect',
    'smallDieselCar': 'Small Diesel Car',
    'smallPetrolVan': 'Small Petrol Van',
    'something_went_wrong': 'Something went wrong. Please try again later.',
    'spain': 'Spain',
    'staked_crst': 'Staked CRST',
    'stakeCRST' : 'Stake CRST',
    'start_date': 'Start date',
    'stats': 'Stats',
    'status': 'Status',
    'success': 'Success',
    'successfully_claimed': 'Successfully claimed all the CCO2 tokens',
    'summary': 'Summary',
    'term': 'Term',
    'theDistanceInKM': 'The distance in KM',
    'to_be_determined': 'To be determined',
    'token_balance': 'Token Balance',
    'token_id': 'Token ID',
    'top_co2_compensators': 'Top CO2 Compensators',
    'top_nftrees_holders': 'Top NFTree holders',
    'total': 'Total',
    'total_co2_absorbed': 'Total CO2 absorbed (KG)',
    'total_co2_compensated': 'Total CO2 compensated (KG)',
    'total_nftrees': 'Total NFTrees',
    'transaction_wasnt_successful': 'The transaction was not successful. please try again',
    'transactions': 'Transactions',
    'transfer': 'Transfer',
    'transfer_completed': 'Transfer completed',
    'transfer_your_nftrees': 'Transfer your NFTrees',
    'twenty_years_ago': '20 Years',
    'type': 'Type',
    'type_your_name': 'Type in your name and/or company to be shown on the certificate',
    'unable_to_burn': 'You were not able to burn CCo2. please try again later',
    'unable_to_claim': 'Unable to claim co2.',
    'unclaimed': 'Unclaimed (CCO2) :',
    'update_your_details': 'Update your details',
    'vault_tvl': 'Vault TVL',
    'what_is_the_reason_for_compensation': 'What is the reason for compensation?',
    'yourTotalFootprint': 'Your total footprint',
    'your_stake': 'Your stake',
    'total_staked': 'Total staked',
    'reward_rate': 'Reward Rate',
  },
};
