import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useAppSelector } from "../../../src/store/hooks";
import { RootState } from "../../../src/store/store";
import { useNavigate } from "react-router-dom";
import batch1 from "../../assets/images/nftree/batch1.gif";
import batch2 from "../../assets/images/nftree/batch2.gif";
import batch3 from "../../assets/images/nftree/batch3.gif";
import classes from "../../styles/nftrees.module.scss";

const BatchComponent = () => {
    const currentUser = useAppSelector(
        (state: RootState) => state.users.currentUser
      );
    const navigate = useNavigate();
    
    const seperateByBatch = () => {
        let sortedNftrees: Record<string, number> = {};
        const allMetadata = currentUser.NFTrees.map((tree) => {
          return JSON.parse(tree.metadata);
        });
        allMetadata.forEach((metadata) => {
          if (metadata) {
            sortedNftrees[metadata.name]
              ? (sortedNftrees[metadata.name] = sortedNftrees[metadata.name] + 1)
              : (sortedNftrees[metadata.name] = 1);
          }
        });
      };
    
      useEffect(() => {
        seperateByBatch();
      });

    return (
        <React.Fragment>
        <div className="w-full">
          {/* 
          Should be shown if I own nftrees
          <Dropdown
            value={selectedStatus}
            className="mt-2"
            style={{ borderRadius: "30px" }}
            options={options}
            onChange={onStatusChange}
            placeholder="Select an option"
          /> */}
          <section className={`${classes.batch_container} mt-4 w-full`}>
            {/* Single Batch */}
            <div
              className={`${classes.batch}`}
              onClick={() => {
                navigate("/batches/1");
              }}
            >
              {/* Image */}
              <img src={batch1} alt="Batch #1" />
              {/* Details */}
              <div className="text-center my-3 flex-column flex">
                <h3 className="font-bold"><FormattedMessage
          id="batch"
        /> 1</h3>
                <span><FormattedMessage
          id="fig_trees"
        /></span>
                <span><FormattedMessage
          id="location"
        /> : <FormattedMessage
        id="caspe_spain"
      /></span>
              </div>
              {/* Owned badge */}
              {/* <div className={`${classes.badge} absolute left-0 top-0`}>
                NFTrees owned: 0
              </div> */}
            </div>
            <div
              className={`${classes.batch}`}
              onClick={() => {
                navigate("/batches/2");
              }}
            >
              {/* Image */}
              <img src={batch2} alt="Batch #2" />
              {/* Details */}
              <div className="text-center my-3 flex-column flex">
                <h3 className="font-bold"><FormattedMessage
          id="batch"
        /> 2</h3>
                <span><FormattedMessage
          id="mixed_forest"
        /></span>
                <span><FormattedMessage
          id="location"
        /> : <FormattedMessage
        id="spain"
      /></span>
              </div>
              {/* Owned badge :: shown if I owned any trees in this batch */}
              {/* <div className={`${classes.badge} absolute left-0 top-0`}>
                NFTrees owned: 0
              </div> */}
            </div>
            <div
              className={`${classes.batch}`}
              onClick={() => {
                navigate("/batches/3");
              }}
            >
              {/* Image */}
              <img src={batch3} alt="Batch #3" />
              {/* Details */}
              <div className="text-center my-3 flex-column flex">
                <h3 className="font-bold"><FormattedMessage
          id="batch"
        /> 3</h3>
                <span><FormattedMessage
          id="pomegranate_trees"
        /></span>
                <span><FormattedMessage
          id="location"
        /> : <FormattedMessage
        id="caspe_spain"
      /></span>
              </div>
              {/* Owned badge */}
              {/* <div className={`${classes.badge}`}>NFTrees owned: 0</div> */}
            </div>
          </section>
        </div>
        </React.Fragment>
    )
}

export default BatchComponent;