import React, { useEffect, useState } from "react";
import classes from "../../styles/Dashboard.module.scss";
import { polygonConfig } from "../../dappConfig";
import { useAppSelector } from "../../store/hooks";
import { mdiCrownOutline, mdiMedalOutline, mdiPartyPopper, mdiTreeOutline } from "@mdi/js";
import Icon from "@mdi/react";
import { FormattedMessage, useIntl } from "react-intl";
import { RootState } from "../../store/store";
import DappBoxLoading from "../../ui/DappBoxLoading";
import { ScrollPanel } from "primereact/scrollpanel";
import coorestIcon from "../../assets/images/tokens/token.png";
import { useMoralisWeb3Api } from "react-moralis";

const TopNftreeHolders = () => {
    const Moralis = require("moralis-v1");
    const Web3Api = useMoralisWeb3Api();
    const currentUser = useAppSelector(
        (state: RootState) => state.users.currentUser
    );
    const [topHolders, setTopHolders] = useState<any[]>([]);
    const [nftHoldersLoading, setNftHoldersLoading] = useState(false);

    const getNFTOwners = async () => {
        setNftHoldersLoading(true);
        const topNFTreeHolders: any[] = [];

        try {
        let options: {
            address: string;
            chain: string;
            cursor?: undefined | string;
            limit: number;
        } = {
            address: polygonConfig.TREE_TOKEN_ADDRESS,
            chain: polygonConfig.chainName,
            limit: 100,
            // cursor:'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjdXN0b21QYXJhbXMiOnsidG9rZW5BZGRyZXNzIjoiMHhhNWNlMzkwYmUxZDE5Y2M0MWVjYjI2YzMxNzRlOTllYWQzYTNlNDVhIn0sImtleXMiOlsiMTY0MjI2NTMwNi4wNTIiLCIxNjQ2NDQzNDY4LjM4NyIsIjE2NTQwMTg3NzIuMzgiLCIxNjYzNTI2MDA5Ljk2MyIsIjE2NjM1NzkzMzAuMzY2IiwiMTY3Mzg3MzA3NC42MTYiLCIxNjc1Njc3MTkxLjM0MyJdLCJ3aGVyZSI6eyJ0b2tlbl9hZGRyZXNzIjoiMHhhNWNlMzkwYmUxZDE5Y2M0MWVjYjI2YzMxNzRlOTllYWQzYTNlNDVhIn0sImxpbWl0IjoxMDAsIm9mZnNldCI6MCwib3JkZXIiOltdLCJkaXNhYmxlX3RvdGFsIjpmYWxzZSwidG90YWwiOjczMywicGFnZSI6NywidGFpbE9mZnNldCI6MSwiaWF0IjoxNjc1NjgwMzk4fQ.Y3By-1LoITB8mSg3PPMBkkwGGJaDDcOQShMxBensrW0'
        };
            let nftOwners : any= [];
            let result;
            let owners: any = {};
            do {
                result = await Web3Api.token.getNFTOwners(options as any);
                options.cursor = result.cursor;
                let res = result.result as any[];
            if (res && res.length) {
                res.forEach((result: any) => {
                    owners[result.owner_of] = (owners[result.owner_of] || 0) + 1;
                });
            }
        } while (result.cursor != null);
            console.log('nftOwners',nftOwners);

            const sortedOwners = Object.entries(owners).sort((a, b) => (b[1] as any) - (a[1] as any));
            console.log('sortedOwners',sortedOwners);

            const cloudfn = await Moralis.Cloud.run("nftHolders", sortedOwners);
            if (cloudfn) {
                sortedOwners.forEach((owner) => {
                    cloudfn.forEach((user: any) => {
                        if (user.ethAddress === owner[0]) {
                            topNFTreeHolders.push({
                                name: user.fullName ?? user.username ?? user.ethAddress,
                                profile: user.profilePictureAddress ?? null,
                                nftrees: owner[1],
                            });
                        }
                    });
                });
            }

            if (topNFTreeHolders.length > 10) {
                setTopHolders(topNFTreeHolders.slice(0, 10));
            } else {
                setTopHolders(topNFTreeHolders);
            }
            const currentTimestamp = Date.now();
            sessionStorage.setItem("lastFetchTopNftreeHoldersTimestamp", currentTimestamp.toString());

        } catch (err) {
            console.log(err);
        } finally {
            sessionStorage.setItem("nftHolders", JSON.stringify(topNFTreeHolders.slice(0, 10)));
            setNftHoldersLoading(false);
        }
    };

    const onStart = async () => {
        const currentTimestamp = Date.now();
        // get lastFetchTimestamp from sessionStorage
        const lastFetchTopNftreeHoldersTimestamp = sessionStorage.getItem("lastFetchTopNftreeHoldersTimestamp");

        if (lastFetchTopNftreeHoldersTimestamp !== null && currentTimestamp - Number(lastFetchTopNftreeHoldersTimestamp) < 60 * 60 * 1000) {

            const nftHoldersString = sessionStorage.getItem("nftHolders");
            if (nftHoldersString && JSON.parse(nftHoldersString).length > 0) {
                setTopHolders(JSON.parse(nftHoldersString));
            }
        } else {
            await Promise.all([getNFTOwners()]);
        }
    }

    useEffect(() => {
        onStart();
    }, []);

    return (
        <>
            <div className={classes.leaderboard}>
                <div className="title mt-2 xl:mt-4 flex">
                    <strong>
                        <FormattedMessage id="top_nftrees_holders" />
                    </strong>
                </div>
                {nftHoldersLoading ? (
                    <DappBoxLoading />
                ) : (
                    <ScrollPanel className={classes.PanelBoard} >
                        {topHolders.map((user: any, index) => {
                            return (
                                <div key={index} className={`${classes.record} record flex align-items-center`}>
                                    <span>{index + 1}</span>
                                    <div className={classes.details}>
                                        <img src={user.profile ?? coorestIcon} alt="avatar" width="40" height="40" className="mr-1" />
                                        {index === 0 ? (
                                            <Icon path={mdiCrownOutline}></Icon>
                                        ) : index === 1 ? (
                                            <Icon path={mdiMedalOutline}></Icon>
                                        ) : index === 2 ? (
                                            <Icon path={mdiPartyPopper}></Icon>
                                        ) : (
                                            ""
                                        )}

                                        {user.name === currentUser.fullName ??
                                            currentUser.username ? (
                                            <strong className="fs-5 ml-2">
                                                {user.name === '' ? '****' :
                                                    user.name && user.name.length > 17
                                                        ? user.name.substring(0, 14) + "..."
                                                        : user.name}

                                            </strong>
                                        ) : (
                                            <span className="name ml-2">
                                                {user.name === '' ? '****' :
                                                    user.name && user.name.length > 17
                                                        ? user.name.substring(0, 14) + "..."
                                                        : user.name}

                                            </span>
                                        )}

                                        {/* {console.log("user.name: ", user.name)} */}

                                        <div className="score ml-auto">{user.nftrees}</div>
                                        <Icon path={mdiTreeOutline}></Icon>
                                    </div>
                                </div>
                            );
                        })}
                    </ScrollPanel>
                )}
            </div>

        </>
    );
};

export default TopNftreeHolders;