import { useEffect, useState } from "react";
import { useMoralis, useMoralisWeb3Api } from "react-moralis";
import { polygonConfig } from "../../../dappConfig";
import { differenceInDays, differenceInSeconds, format } from "date-fns";
import { NFTree, TreeInfo, TreeInfoResponse } from "./TreeTypes";
import axios from "axios";
import { useContracts } from "../useContract/useContract";
import { updateNftrees, resetClaimableCo2, updateTotalNftrees } from "../useUser/usersSlice";
import BigNumber from "bignumber.js";
import { useDispatch } from "react-redux";
import { RootState } from "../../../store/store";
import { useAppSelector } from "../../../store/hooks";

export function useFetchNftrees() {
  const dispatch = useDispatch();
  const nftreeContract = polygonConfig.TREE_TOKEN_ADDRESS;
  const { getCoorestContract } = useContracts();
  const Web3Api = useMoralisWeb3Api();
  const { user, setUserData, isAuthenticated } = useMoralis();

  interface NFTreeProps {
    result: NFTree[];
    total: number;
    totalAbsorbtion: number;
  }

  const [nftrees, setNftrees] = useState<NFTreeProps>({
    result: [],
    total: 0,
    totalAbsorbtion: 0,
  });
  const currentUser = useAppSelector((state: RootState) => state.users.currentUser);
  const [claimableCo2, setClaimableCo2] = useState(0);
  const [loading, setLoading] = useState(false);
  const [refetching, setRefetching] = useState(false);
  const [error, setError] = useState(false);
  // To keep track on the tokens object if it stills empty
  const [isFetched, setIsFetched] = useState(false);
  const fetch = async (): Promise<void> => {
    console.log("!--Started Fetching nftrees from the server--!");
    setLoading(true);
    try {
      const options: {
        address: any;
        chain: any;
        token_address: any;
        cursor?: any;
        limit: number;
      } = {
        address: user?.attributes.ethAddress,
        chain: polygonConfig.chainName,
        token_address : nftreeContract,
        limit: 100,
      };
      let totNFTrees: any[] = [];
      let result;
      do {
        result = (await Web3Api.account.getNFTsForContract(options as any)) as any;
        //console.log(result);
        options.cursor = result.cursor;
        let res = result.result as unknown[];
        totNFTrees = [...totNFTrees, ...res];
        //console.log(totNFTrees);
      } while (result.cursor != null);
      totNFTrees.sort(function (a: any, b: any) {
        return Number(a.token_id) - Number(b.token_id);
      });
      // totNFTrees.length !== currentUser?.totalNFTrees &&
      setUserData({
        totalNFTrees: totNFTrees.length,
      });
      let totalNftrees = totNFTrees ?? [];

      const allNFTrees: number[] = totalNftrees.map((nftree: any) => +nftree.token_id);
      //console.log("allNFTrees", allNFTrees);
      let totalAbsorbtion = 0;
      let merkleTreeResult: NFTree[] = [];
      if (allNFTrees.length) {
        let res = await axios.get(polygonConfig.MERKLE_TREE_API, {
          params: {
            token_id_in: allNFTrees.join(","),
          },
        });

        let treeInfo = res.data.sort(function (a: any, b: any) {
          return Number(a.token_id) - Number(b.token_id);
        });
        let tokenIds: number[] = [];
        //Get the token ids in merkle tree
        treeInfo.forEach((info: TreeInfoResponse) => {
          //console.log("TreeInfoResponse", info);

          totalAbsorbtion = totalAbsorbtion + info.co2_absorbed_per_year;
          if (!tokenIds.includes(info.token_id)) {
            tokenIds.push(info.token_id);
          }
        });

        //filter all NFTrees and get the ones that are in merkle root
        merkleTreeResult = totalNftrees ? totalNftrees.filter((nft) => tokenIds.includes(+nft.token_id)) : [];
      }
      // merkleTreeResult.sort(function (a: any, b: any) {
      //   return Number(a.token_id) - Number(b.token_id);
      // });
      //console.log("merkleTreeResult", merkleTreeResult);

      /*totalNftrees &&
       setNftrees((prevState) => ({
          ...prevState,
          total: merkleTreeResult.length,
        }));*/

      /** Set claimable co2 */
      if (totalNftrees) {
        const coorest = await getCoorestContract();
        let allNFTreeIds: number[] = totalNftrees.map((nftree: any) => +nftree.token_id);

        if (allNFTreeIds.length > 0) {
          try {
            let treeInfoResult = await axios.get(polygonConfig.MERKLE_TREE_API, {
              params: {
                token_id_in: allNFTreeIds.join(","),
              },
            });

            try {
              let treesInfo: TreeInfo = {};
              let totalClaimableCo2 = 0;
              let treeIds: number[] = [];
              treeInfoResult.data.forEach((info: any) => {
                treeIds.push(info.token_id);
                treesInfo = {
                  ...treesInfo,
                  [info.token_id]: {
                    co2AbsorbedPerDay: info.co2_absorbed_per_year / 365,
                    plantDate: info.plant_date,
                  },
                };
              });
              const lastClaimDates = await coorest.getNftreeIdToLastClaimDate(
                treeIds
                // .sort(function (a: any, b: any) {
                //   return a-b;
                // })
              );

              lastClaimDates.forEach((lastClaimDate: any, index: number) => {
                merkleTreeResult[index].lastClaimDate = new Date(+lastClaimDate.toString() * 1000);
                const LastClaimDateUntillNow =
                  format(new Date(+lastClaimDate.toString() * 1000), "yyyy") < "2020"
                    ? differenceInDays(new Date(), new Date((treesInfo[treeIds[index]].plantDate ?? 0) * 1000))
                    : differenceInDays(new Date(), new Date(+lastClaimDate.toString() * 1000));

                const claimableCo2 = LastClaimDateUntillNow * treesInfo[treeIds[index]].co2AbsorbedPerDay;
                totalClaimableCo2 = totalClaimableCo2 + claimableCo2;
                treesInfo = {
                  ...treesInfo,
                  [treeIds[index]]: {
                    ...treesInfo[treeIds[index]],
                    claimableCo2: claimableCo2,
                  },
                };
              });
              //console.log("merkleTreeResult 2", merkleTreeResult);

              totalNftrees &&
                setNftrees(() => ({
                  total: merkleTreeResult.length,
                  totalAbsorbtion: totalAbsorbtion,
                  result: merkleTreeResult,
                }));
              setClaimableCo2(totalClaimableCo2);
              
              localStorage.setItem("claimable", JSON.stringify({ data: totalClaimableCo2, date: Date.now() }));
              localStorage.setItem(
                "nftreesData",
                JSON.stringify({
                  data: {
                    total: merkleTreeResult.length,
                    totalAbsorbtion: totalAbsorbtion,
                    result: merkleTreeResult,
                  },
                  date: Date.now(),
                })
              );
              

              //dispatch(resetClaimableCo2(totalClaimableCo2));
            } catch (err) {
              console.log(err);
              console.log("failed to get the last claim date using getNftreeIdToLastClaimDate()");
            }
          } catch (err) {
            console.log(err);
            console.log("failed to get the trees information ::api/merkletreenode::");
          }
        }
      }
    } catch (err) {
      console.log(err);
      setError(true);
    } finally {
      setLoading(false);
      setIsFetched(true);
    }
  };

  const refetch = async (): Promise<void> => {
    if (!user || !isAuthenticated) return;
    setRefetching(true);
    await fetch();
  };

  useEffect(() => {
    if (refetching) {
        dispatch(updateTotalNftrees(nftrees.total));
        dispatch(updateNftrees({ nftrees: nftrees.result, totalAbsorbtion: nftrees.totalAbsorbtion }));
        dispatch(resetClaimableCo2(claimableCo2));
      }
      setRefetching(false);
  }, [nftrees.total]);

  useEffect(() => {
    if (!user || !isAuthenticated) return;
    const cachedNftrees = localStorage.getItem("nftreesData");
    const cachedClaimable = localStorage.getItem("claimable");
    if (cachedNftrees && cachedClaimable) {
      const { data: nftreesData, date } = JSON.parse(cachedNftrees);
      const { data: claimableData } = JSON.parse(cachedClaimable);
      const secondsElapsed = differenceInSeconds(Date.now(), date);

      if (secondsElapsed <= 240) {
        setNftrees(nftreesData);
        setClaimableCo2(claimableData);
        setIsFetched(true);
        return;
      }
    }
    fetch();
  }, [user, isAuthenticated]);

  return { fetch, refetch, nftrees, loading, error, isFetched, setLoading, claimableCo2 };
}
