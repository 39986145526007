import React, { useEffect, useRef, useState } from "react";
import classes from "../../styles/Dashboard.module.scss";
import co2Icon from "../../assets/images/tokens/co2.png";
import { User } from "../../store/custom-hooks/useUser/usersTypes";
import { polygonConfig } from "../../dappConfig";
import { useAppDispatch } from "../../store/hooks";
import { claimedAll } from "../../store/custom-hooks/useUser/usersSlice";
import { mdiLoading, mdiCheck } from "@mdi/js";
import Icon from "@mdi/react";
import { Tooltip } from "primereact/tooltip";
import UserLogin from "../users/UserLogin";
import { TreeInfoResponse } from "../../store/custom-hooks/useNFTree/TreeTypes";
import { Toast } from "primereact/toast";
import { useContracts } from "../../store/custom-hooks/useContract/useContract";
import { chunk } from "../../utils/utils-methods";
import { useMoralis } from "react-moralis";
import CO2Burner from "../co2-burner/CO2Burner";
import { FormattedMessage, useIntl } from "react-intl";
import { useFetchPocc } from "../../store/custom-hooks/usePocc/useFetchPocc";
import { useTokensBalance } from "../../store/custom-hooks/useTokensBalance/useTokensBalance";
import { useTranslations  } from "../../i18n/useTranslations";
import { getMerkleTreeApi } from "../../utils/api";
import { constants } from "../../utils/constants";

interface C02TokensProps {
  currentUser: User;
}

const Co2Tokens = ({ currentUser }: C02TokensProps) => {
  const intl = useIntl();
  const TRANSLATIONS = useTranslations ();

  const Moralis = require("moralis-v1");
  const { user } = useMoralis();
  const currentMoralisUser = user;
  const { isFetched: tBalanceFetched } = useTokensBalance();
  const dispatch = useAppDispatch();
  const [displayBurningDialog, setDisplayBurningDialog] = useState<boolean>(false);
  const [displayCalculatorDialog, setDisplayCalculatorDialog] = useState<boolean>(false);
  const [burnerLoading, setBurnerLoading] = useState<boolean>(false);

  const [variant] = useState("");
  // const provider = new ethers.providers.Web3Provider((window as any).ethereum);
  // const signer = provider.getSigner();
  
  const [enteredAmount, setEnteredAmount] = React.useState<number>(0);
  const [compensationAmount, setCompensationAmount] = useState<number>(0);
  const [certificateAmount, setCertificateAmount] = useState<number>(0);
  const [compensationReason, setCompensationReason] = useState<string>("No specific reason");
  const [fullName, setFullName] = useState<string>(currentUser.fullName);
  const [company, setCompany] = useState<string>(currentUser.company);
  const [displayLoginDialog, setDisplayLoginDialog] = useState(false);
  const [claimLoading, setClaimLoading] = useState(false);
  const { loadingPocc } = useFetchPocc();
  const toast = useRef(null);
  const { getCoorestContract, getCO2Contract } = useContracts();

  const closeLoginDialog = () => {
    setDisplayLoginDialog(false);
  };

  const showBurningDialog = () => {
    if (currentMoralisUser) {
      setFullName(currentUser.fullName);
      setCompany(currentUser.company);
      setDisplayBurningDialog(true);
    } else {
      setDisplayLoginDialog(true);
    }
  };

  const onHide = () => {
    setDisplayBurningDialog(false);
    setEnteredAmount(0);
    setCertificateAmount(0);
    setCompensationAmount(0);
    setFullName(currentUser.fullName);
    setCompany(currentUser.company);
  };

  const claimCo2 = async () => {
    let chainId = await Moralis.getChainId();
    if (chainId != polygonConfig.chainId) {
      toast.current &&
        (toast.current as any).show({
          severity: "error",
          summary: "Network Error",
          detail: `Please switch to ${polygonConfig.NETWORK_NAME}`,
          life: 3000,
        });
      return;
    }

    const coorest = await getCoorestContract();
    const allNFTrees: number[] = currentUser.NFTrees.map((nftree: any) => +nftree.token_id);

    if (allNFTrees.length) {
      try {
        let result = await getMerkleTreeApi(allNFTrees);

        let treeInfo = result;
        let tokenIds: number[] = [];
        let plantDays: Date[] = [];
        let floodlights: string[] = [];
        let absorbationRates: number[] = [];
        let proofs: string[][] = [];
        let batches = chunk(treeInfo!, constants.chunkSize);
        setClaimLoading(true);
        for (let i = 0; i < batches.length; i++) {
          let batch = batches[i];
          console.log(batch);
          console.log(tokenIds);

          batch.forEach((info: TreeInfoResponse) => {
            if (!tokenIds.includes(info.token_id)) {
              // if (info.token_id === 1236) {
              tokenIds.push(info.token_id);
              plantDays.push(info.plant_date);
              floodlights.push(info.floodlight_id);
              absorbationRates.push(info.co2_absorbed_per_year);
              proofs.push(info.proof);
              // }
            }
          });
       
          console.log("token ids", tokenIds);
         
          try {
            let tx = await coorest.claimCO2(tokenIds, floodlights, plantDays, absorbationRates, proofs);

            try {
              if (localStorage.getItem("walletType") === "walletConnect") {
                toast.current &&
                  (toast.current as any).show({
                    severity: "info",
                    summary: "Sign the transaction",
                    detail: "Please sign the transaction on you walletConnect",
                    life: 6000,
                  });
              }
              // await tx.wait()
              console.log(i, batches.length);

              if (i !== batches.length - 1) {
                tx.wait();
              } else {
                await tx.wait();

                toast.current &&
                  (toast.current as any).show({
                    severity: "success",
                    summary: "Success Message",
                    detail: "Successfully claimed all the cco2 tokens",
                    life: 3000,
                  });
              }
            } catch {
              toast.current &&
                (toast.current as any).show({
                  severity: "error",
                  summary: "Error Message",
                  detail: "Something went wrong. Please try again later",
                  life: 3000,
                });
            } finally {
              dispatch(claimedAll());
            }
          } catch (err: any) {
            console.log(err);
            toast.current &&
              (toast.current as any).show({
                severity: "error",
                summary: "Error Message",
                detail: "Unable to claim co2",
                life: 3000,
              });
          }
          //clearing arrays
          tokenIds.length = 0;
          plantDays.length = 0;
          floodlights.length = 0;
          absorbationRates.length = 0;
          proofs.length = 0;
        }
        setClaimLoading(false);
      } catch (err) {
        toast.current &&
          (toast.current as any).show({
            severity: "error",
            summary: "Error Message",
            detail: "Unable to get trees info ::api/merkletreenode::",
            life: 3000,
          });
        console.log(err);
      }
    } else {
      toast.current &&
        (toast.current as any).show({
          severity: "error",
          summary: "Error Message",
          detail: "You don't have any NFTree to claim CO2",
          life: 3000,
        });
    }
  };

  const headerStyle = {
    height: "10px",
    backgroundColor: "#eff3f8",
    boxShadow: "none",
  };
  const contentStyle = { padding: "0", boxShadow: "none" };

  useEffect(() => {
    if (burnerLoading && localStorage.getItem("walletType") === "walletConnect") {
      toast.current &&
        (toast.current as any).show({
          severity: "info",
          summary: "Sign the transaction",
          detail: "Please sign the transaction on your walletConnect",
          life: 6000,
        });
    }
  }, [burnerLoading]);

  useEffect(() => {
    console.log(
      "totalcomp :" +
        currentUser.totalCompensation +
        "claimable" +
        currentUser.claimableCo2 
        +"co2" +
        currentUser.co2Balance
    );
  }, [currentUser]);

  useEffect(() => {
    console.log("tBalanceFetched:", tBalanceFetched);
    console.log("currentUser:", user);
  }, [tBalanceFetched]);

  return (
    <React.Fragment>
      <div className={`${classes.base_card} ${classes.card} flex-column justify-content-between`}>
        {/* title */}
        <div className="flex flex justify-content-start align-items-center">
          <img src={co2Icon} alt="NFTrees" className="mr-2" style={{ width: "40px" }} />
          <strong>
            <FormattedMessage id="cco2_tokens" />
          </strong>
        </div>

        {/* Body */}
        <div className={` ${classes.card_body}`}>
          <div className={`${classes.bordered_div}`} style={{ paddingBottom: "6px" }}>
            <span>
              <FormattedMessage id="lifetime_accumulated" />
            </span>
    
              <span>
                {(+currentUser.totalCompensation + +currentUser.claimableCo2 + +currentUser.co2Balance).toLocaleString(
                  undefined,
                  {
                    maximumFractionDigits: 2,
                  }
                )}{" "}
              </span>
       
          </div>
          <div style={{ paddingTop: "6px" }}>
            <span>
              <FormattedMessage id="unclaimed" />{" "}
            </span>
            <span>
              {currentUser.claimableCo2 &&
                currentUser.claimableCo2.toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                })}{" "}
            </span>
          </div>
        </div>
        <div className="flex justify-content-between">
          <Tooltip target=".claimIcon" style={{ maxWidth: "220px" }} />
          <button
            onClick={currentUser.claimableCo2 > 0 ? claimCo2 : () => {}}
            className={`${classes.dapp_btn} xl:mt-2 mr-1 claimIcon`}
            data-pr-tooltip={TRANSLATIONS.fees_from_claimed_amount}
            data-pr-position="top"
          >
            {claimLoading ? (
              <span className="text-0">
                <Icon className={classes.spinner} path={mdiLoading} />{" "}
              </span>
            ) : variant == "success" ? (
              <Icon size={1} path={mdiCheck} color="white" />
            ) : currentUser.claimableCo2 > 0 ? (
              <FormattedMessage id="claim_all" />
            ) : (
              <FormattedMessage id="nothing_to_claim" />
            )}
          </button>

          <button
            onClick={currentUser.co2Balance > 0 ? showBurningDialog : () => {}}
            className={`${classes.secondary_btn} xl:mt-2 ml-1`}
          >
            {burnerLoading ? (
              <span className="text-0">
                <Icon className={classes.transferSpinner} path={mdiLoading} />{" "}
              </span>
            ) : currentUser.co2Balance > 0 ? (
              <FormattedMessage id="burn" />
            ) : (
              <FormattedMessage id="nothing_to_burn" />
            )}
          </button>
        </div>
      </div>

      {displayBurningDialog && (
        <CO2Burner
          setBurnerLoading={setBurnerLoading}
          setDisplayBurningDialog={setDisplayBurningDialog}
          toast={toast}
        />
      )}
      {/* User Login Dialog */}
      {displayLoginDialog && <UserLogin displayLoginDialog={displayLoginDialog} onHide={closeLoginDialog} />}

      {/* Toast */}
      <Toast ref={toast} />
    </React.Fragment>
  );
};

export default Co2Tokens;
