import React from "react";
import NavbarLong from "../components/common/top-navbar/NavbarLong";
import { useAppSelector } from "../store/hooks";
import { RootState } from "../store/store";
import BatchComponent from "../components/nftrees/BatchComponent";
import classes from "./../styles/nftrees.module.scss";

const Batches = () => {
  const currentUser = useAppSelector(
    (state: RootState) => state.users.currentUser
  );

  return (
    <React.Fragment>
      <div className={`${classes.dashboard} d-flex w-full flex-column`}>
        <NavbarLong currentAccount={currentUser} title="NFTrees" />
        <BatchComponent />
      </div>
    </React.Fragment>
  );
};

export default Batches;