import { useCallback, useEffect, useRef, useState } from "react";
import { useMoralis, useMoralisWeb3Api } from "react-moralis";
import { polygonConfig } from "../../../dappConfig";
import { differenceInSeconds } from "date-fns";
import { updatePocc } from "../useUser/usersSlice";
import { useAppDispatch } from "../../../store/hooks";

let isFetching = false; // Shared variable to track ongoing fetch

export function useFetchPocc() {
  const Web3Api = useMoralisWeb3Api();
  const { user, isAuthenticated } = useMoralis();
  const [data, setData] = useState<any[]>([]);
  const [loadingPocc, setLoadingPocc] = useState(false);
  const [errorPocc] = useState(false);
  const [isFetched, setIsFetched] = useState(false);
  const [refetched, setRefetched] = useState(false);
  const isFetchingRef = useRef(false);
  const dispatch = useAppDispatch();




  const getPoCCData = async () => {
    console.log("getPoCCData Executed.");
    try {
      const options: {
        address: string | undefined;
        chain: string;
        format?: "decimal" | "hex" | undefined;
        // tokenAddresses: string[];
        limit?: number | undefined;
        cursor?: string | undefined;
      } = {
        address: polygonConfig.POCC_TOKEN_ADDRESS,
        chain: polygonConfig.chainName,
        format: "decimal",
        // tokenAddresses: [polygonConfig.POCC_TOKEN_ADDRESS],
        limit: 100,
        cursor: undefined,
      };
      let data: any[] = [];
      let result: any;
      do {
        result = await Web3Api.token.getContractNFTTransfers(options as any);
        if (result && result.result) {
          data = [...data, ...result.result];
        }
        options.cursor = result?.cursor;
      } while (result?.cursor != null);
      const filteredTransactions = data.filter((transaction: any) => {
        return (
          transaction.from_address ===
            "0x0000000000000000000000000000000000000000" &&
          transaction.to_address === user?.attributes.ethAddress &&
          transaction.token_address === polygonConfig.POCC_TOKEN_ADDRESS
        );
      });
      console.log("getContractNFTTransfers", filteredTransactions);
      setData(filteredTransactions);
      setIsFetched(true);
      localStorage.setItem("poccData", JSON.stringify({ data: filteredTransactions, date: Date.now() }));
      return filteredTransactions;
    } catch (err) {
      console.log(err);
    }
  };

 /* const getPoCCData = async () => {
    if (!user || !isAuthenticated) return;
    console.log("!--Started reFetching poccs --!");
    setLoadingPocc(true);
    try {
      const options: {
        address: string | undefined;
        chain: string;
        token_address: string;
        limit: number;
        disable_total: string;
        cursor?: any;
      } = {
        address: user?.attributes.ethAddress,
        chain: polygonConfig.chainName,
        token_address: polygonConfig.POCC_TOKEN_ADDRESS,
        limit: 100,
        disable_total: "false",
        cursor: null,
      };
      let data: any[] = [];
      let result;
      do {
        result = await Web3Api?.account?.getNFTsForContract(options as any);
        if (result && result.result) {
          data = [...data, ...result.result];
        }
        options.cursor = result?.cursor;
      } while (result?.cursor != null);
      setData(data);
      localStorage.setItem("poccData", JSON.stringify({ data: data, date: Date.now() }));
      return data;
    } catch (err) {
      console.log(err);
      setErrorPocc(true);
    } finally {
      setLoadingPocc(false);
      console.log("Finally from fetch");
    }
  };*/

  const fetch = async (): Promise<void> => {
    if (!isFetchingRef.current) {
      isFetchingRef.current = true;
      await getPoCCData();
      setIsFetched(true);
      isFetchingRef.current = false;
    }
  };
  
  const refetch = async (): Promise<void> => {
    let data = await getPoCCData();
    setRefetched(true);
    if (data !== null) {
      dispatch(updatePocc(data));
    }
  };
  // const fetch2 = async (): Promise<void> => {
  //   console.log("!--Started Fetching poccs from the server--!");
  //   setLoadingPocc(true);
  //   try {
  //     const options: {
  //       address: string | undefined;
  //       chain: string;
  //       tokenAddresses: string[];
  //       limit: number;
  //       disable_total: string;
  //       cursor?: any;
  //     } = {
  //       address: user?.attributes.ethAddress,
  //       chain: polygonConfig.chainName,
  //       tokenAddresses: [polygonConfig.POCC_TOKEN_ADDRESS],
  //       limit: 100,
  //       disable_total: "false",
  //       cursor: null,
  //     };
  //     let data: any[] = [];
  //     let result;
  //     do {
  //       result = await Web3Api?.account?.getNFTsForContract(options as any);
  //       if (result && result.result) {
  //         data = [...data, ...result.result];
  //       }
  //       options.cursor = result?.cursor;
  //     } while (result?.cursor != null);
  //     setData(data);
  //     localStorage.setItem("poccData", JSON.stringify({ data: data, date: Date.now() }));
  //     // setIsFetched(true);
  //   } catch (err) {
  //     console.log(err);
  //     setErrorPocc(true);
  //   } finally {
  //     setLoadingPocc(false);
  //     console.log("Finally from fetch");
  //   }
  // };


  useEffect(() => {
    if (!user || !isAuthenticated) return;
    const cachedData = localStorage.getItem("poccData");
    if (cachedData) {
      const { data, date } = JSON.parse(cachedData);
      console.log("typeof data : ", data);
      const secondsElapsed = differenceInSeconds(Date.now(), date);
      if (secondsElapsed <= 240) {
        setData(data);
        setIsFetched(true);
        return;
      }
    }
    fetch();
    return () => {
      isFetchingRef.current = false; // Reset the isFetchingRef on unmount
    };
  }, [user, isAuthenticated]);

  return {
    isFetched,
    refetch,
    fetch,
    data,
    loadingPocc,
    errorPocc,
    setLoadingPocc,
  };
}
